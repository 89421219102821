.payments__thank_you
  text-align: center

  .large_image
    margin: 0 auto
    display: block
    max-width: 100%
    width: 30%
    border-radius: 50%

  .small_logo
    width: 200px
    margin-top: 25px

  .motto_text
    margin-top: 0
    font-weight: bold
    text-align: center !important
