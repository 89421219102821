li.input.switch
  margin: 1em auto !important
  position: relative

  label
    justify-content: space-between
    display: flex
    @media (max-width: $mobile-width)
      // An awful hack but need to work with the current system while we refactor
      display: block !important

  input
    display: none

    &:checked ~ .switch-wrapper
      .slider
        background-color: #2196F3
        &:before
          transform: translateX(26px)

  .switch-wrapper
    display: inline-block
    width: 60px
    height: 34px
    position: relative
    @media (max-width: $mobile-width)
      margin-top: 5px
      display: block

  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #ccc
    transition: .4s
    border-radius: 34px

    &:before
      position: absolute
      content: ''
      height: 26px
      width: 26px
      left: 4px
      bottom: 4px
      background-color: white
      -webkit-transition: .4s
      transition: .4s
      border-radius: 50%
