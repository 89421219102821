.edit_modal .loader
  position: absolute
  top: 40%
  left: 45%
  z-index: 99

.croppie-container
  width: 100%
  height: 100%

  .cr-image
    z-index: -1
    position: absolute
    top: 0
    left: 0
    transform-origin: 0 0
    max-height: none
    max-width: none

  .cr-boundary
    position: relative
    overflow: hidden
    margin: 0 auto
    z-index: 1
    width: 100%
    height: 100%

  .cr-viewport
    position: absolute
    border: 2px solid #fff
    margin: auto
    top: 0
    bottom: 0
    right: 0
    left: 0
    box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5)
    z-index: 0

  .cr-original-image
    display: none

  .cr-vp-circle
    border-radius: 50%

  .cr-overlay
    z-index: 1
    position: absolute
    cursor: move

  .cr-slider-wrap
    width: 75%
    margin: 15px auto
    text-align: center

.croppie-result
  position: relative
  overflow: hidden

  img
    position: absolute

.croppie-container
  .cr-image, .cr-overlay, .cr-viewport
    transform: translateZ(0)

  .cr-slider-wrap p
    margin: 0
    font-size: 13px

.cr-slider
  -webkit-appearance: none
  width: 300px
  max-width: 100%
  padding-top: 8px
  padding-bottom: 8px
  background-color: transparent

  &::-webkit-slider-runnable-track
    width: 100%
    height: 3px
    background: rgba(0, 0, 0, 0.5)
    border: 0
    border-radius: 3px

  &::-webkit-slider-thumb
    -webkit-appearance: none
    border: none
    height: 16px
    width: 16px
    border-radius: 50%
    background: #ddd
    margin-top: -6px

  &:focus
    outline: none

  &::-moz-range-track
    width: 100%
    height: 3px
    background: rgba(0, 0, 0, 0.5)
    border: 0
    border-radius: 3px

  &::-moz-range-thumb
    border: none
    height: 16px
    width: 16px
    border-radius: 50%
    background: #ddd
    margin-top: -6px

  &:-moz-focusring
    outline: 1px solid white
    outline-offset: -1px

  &::-ms-track
    width: 100%
    height: 5px
    background: transparent
    border-color: transparent
    border-width: 6px 0
    color: transparent

  &::-ms-fill-lower, &::-ms-fill-upper
    background: rgba(0, 0, 0, 0.5)
    border-radius: 10px

  &::-ms-thumb
    border: none
    height: 16px
    width: 16px
    border-radius: 50%
    background: #ddd
    margin-top: 1px

  &:focus
    &::-ms-fill-lower, &::-ms-fill-upper
      background: rgba(0, 0, 0, 0.5)

.cr-rotate-controls
  position: absolute
  bottom: 5px
  left: 5px
  z-index: 1

  button
    border: 0
    background: none

  i:before
    display: inline-block
    font-style: normal
    font-weight: 900
    font-size: 22px

.cr-rotate-l i:before
  content: '↺'

.cr-rotate-r i:before
  content: '↻'

.edit_result
  position: absolute
  visibility: hidden
