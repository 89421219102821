body
  // Global use no records found container
  .no-records-found
    padding: 20px
    text-align: center
    width: 100%
    h3
      display: inline
      font-size: 1em
      margin-right: 5px

  .listings-container, .records-container
    position: relative
    border: 0

    // No records found within listings container
    .no-records-found
      border-radius: 0
      border: 1px solid $bordered-container-border
      h3
        display: block
        margin: 30px 0

    // Remove borders from edges in records listing tables
    table.listings, table.records
      th, td
        border-bottom: 0
        border-left: 0
        &:last-child
          border-right: 0

    // Table Buttons
    table, .mobile_table_tr
      .actions
        width: 1%
        white-space: nowrap
        @media (max-width: $tablet-width)
          white-space: normal

    // Summarised search parameters
    h2
      border: 1px solid $bordered-container-border
      border-top: 0
      padding: 20px
      margin: 0
      & + .no-records-found
        border-top: 0
    .filters-sidebar
      @media (max-width: $mobile-width)
        display: block
      .logs
        li
          width: 100%
          label
            margin-bottom: 5px
            display: block
    //Mobile Tables
    .mobile_table
      display: none
      @media (max-width: $mobile-width)
        display: block
      .mobile_table_tr
        border-bottom: 1px solid #eee
        padding: 10px 0
        h3
          margin: 0 0 10px 0
        h4
          margin: 0
          font-size: 14px
        p, ul
          margin: 0 0 10px 0
        .flex
          justify-content: space-between
          -webkit-justify-content: space-between
          -moz-justify-content: space-between
          -ms-justify-content: space-between
          font-size: 14px
          align-items: center
          -webkit-align-items: center
          -moz-align-items: center
          -ms-align-items: center

          img
            width: 47%
            border-radius: 100%
          .listing-details
            width: 47%
            h4
              margin: 0
            p
              margin-top: 0
      .mobile_table_tr:last-of-type
        border-bottom: 0
    .records-update
      margin-bottom: 15px
      float: right
