.cards-listings-profile-preview
  word-break: break-word

  .row
    width: 100%
    display: flex

    .right
      padding-left: 1em
      flex-grow: 1

  &--name
    margin-bottom: 0
    @media (max-width: $mobile-width)
      margin: 0
  &--breed-name, &--location, &--group-name
    margin: 0.5em 0
    font-size: 0.9em
    display: block

  &--img
    position: relative

    img
      border-radius: 50%
      width: 140px
      @media (max-width: $tablet-width)
        width: 95px
