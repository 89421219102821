.alerts-new__form
  max-width: 400px
  margin: 0 auto

.alerts-manage
  &__subtitle
    margin-bottom: 0

.alerts-manage__new-alert-button.button
  display: block
  margin: 25px auto 50px
  max-width: 400px

.alerts-manage__alerts-list
  display: flex
  flex-wrap: wrap
  -webkit-flex-wrap: wrap
  -moz-flex-wrap: wrap
  -ms-flex-wrap: wrap
  margin: 30px 0 0 0
  @media (max-width: $mobile-width)
    margin: 10px 0 0

.alerts-manage__alerts-list__alert
  background-color: $white
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.06)
  border-radius: 20px
  margin-bottom: 20px
  padding: 20px 30px
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  @media (max-width: $mobile-width)
    flex-direction: column

  &-header
    margin: 0 0 10px
    color: #707070
    strong
      color: $very-dark-grey

  .properties
    font-size: calcRem(18px)
    > .c-tag
      margin-bottom: 5px

  p.last_sent
    font-size: 10px
    margin-bottom: 0
    line-height: 20px
  .actions
    @media (max-width: $mobile-width)
      margin-top: 20px
    .button--grey
      margin: 0 auto
      display: block
    .button--stripped
      font-size: 10px
      font-weight: bold

.alerts-recent
  .wrapper
    position: relative
  &__manage_button
    position: absolute
    top: 0
    right: 20px
    @media (max-width: $tablet-width)
      position: relative
      right: 0
      margin: 0 auto 15px
      display: block
      width: fit-content

