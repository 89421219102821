@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-milestone-banner
  border-bottom: 2px solid #ddd
  @media(max-width: $mobile-width)
    padding: 0 15px 15px
  &-text
    display: inline-block
    font-family: $font-serif
    font-weight: bold
    vertical-align: middle
    padding-right: 104px
    position: relative
    font-size: calcRem(23px)
    margin: 16px 0
    @media(max-width: $mobile-width)
      padding-right: 0
    &:after
      display: block
      content: " "
      width: 96px
      height: 30px
      position: absolute
      right: 0
      top: 0
      background-size: contain
      background-image: url('~images/global/dotted-line-adoption-banner.svg')
      background-repeat: no-repeat
      @media(max-width: $mobile-width)
        width: 0

  &-highlight
    background-color: $petrescue-green
    color: white
    padding: 0 3px
  .article--button--strong
    vertical-align: middle
    font-size: calcRem(16px)
    margin: 0 0 0 5px
    @media(max-width: $mobile-width)
      margin: 0
