.warning
  margin: 0 auto 10px auto
  padding: 1px 0
  max-width: 1080px
  width: 100%
  background-color: $petrescue-orange
  color: $white
  font-weight: bold
  text-align: center
  position: relative

  a
    color: $white
