@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-two-factor-input
  &__input-wrapper
    display: grid
    grid-template-columns: repeat(6, 1fr)
    grid-gap: 5px
    @media (min-width: $tablet-small-width)
      grid-gap: 10px
  &__input
    width: 100%
    height: 50px
    font-size: 25px !important
    text-align: center
    border-color: $light-grey
    padding: 5px
    @media (min-width: $tablet-small-width)
      height: 60px
      padding: 0.48em 0.6em

  // TODO: can probably get rid of this
  &--submit
    display: block
    margin: 10px auto
