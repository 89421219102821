@import "sass/lib/variables"
  
.c-promo-cta--prtv
  margin: auto
  max-width: 1470px
  @media (min-width: $tablet-small-width)
    margin-bottom: -70px
  &__inner
    margin: auto
    @media (min-width: $tablet-small-width)
      margin-right: 0

  &__icon
    transition: all ease-in-out 0.2s

  &__text
    display: block
    transform: rotate(-35deg) translate(-60px, 0px)
    transition: all ease-in-out 0.2s
  &:hover
    .c-promo-cta--prtv__icon
      transform: rotate(5deg)
    .c-promo-cta--prtv__text
      transform: rotate(-33deg) translate(-63px, -3px)
