@import "sass/lib/variables"

.c-chat-sidebar__participants
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 50px
  position: relative
  .icon--envelope
    position: absolute
    font-size: 30px
    transform: rotate(345deg)
  .confetti-static
    z-index: -1
    &__confetti
      width: 10px
      height: 10px
      display: block
      position: absolute
      -webkit-mask-size: contain
      mask-size: contain
      -webkit-mask-size: contain !important
      -webkit-mask-repeat: no-repeat !important
      background-color: $petrescue-green
    .confetti-1
      -webkit-mask: url('~images/global/confetti-heart.svg')
      mask: url('~images/global/confetti-heart.svg')
      left: 0
      top: 80%
    .confetti-2
      -webkit-mask: url('~images/global/single-confetti.svg')
      mask: url('~images/global/single-confetti.svg')
      left: 10%
      top: 10%
      transform: rotate(240deg)
    .confetti-3
      -webkit-mask: url('~images/global/confetti-heart.svg')
      mask: url('~images/global/confetti-heart.svg')
      left: 14%
      top: 105%
      transform: rotate(40deg)
    .confetti-4
      -webkit-mask: url('~images/global/single-confetti.svg')
      mask: url('~images/global/single-confetti.svg')
      left: -2%
      top: 50%
    .confetti-5
      -webkit-mask: url('~images/global/single-confetti.svg')
      mask: url('~images/global/single-confetti.svg')
      left: 10%
      top: 40%

    .confetti-6
      -webkit-mask: url('~images/global/single-confetti.svg')
      mask: url('~images/global/single-confetti.svg')
      right: 10%
      top: 35%
      transform: rotate(200deg)
    .confetti-7
      -webkit-mask: url('~images/global/confetti-heart.svg')
      mask: url('~images/global/confetti-heart.svg')
      right: -0%
      top: 100%
      transform: rotate(20deg)
    .confetti-8
      -webkit-mask: url('~images/global/single-confetti.svg')
      mask: url('~images/global/single-confetti.svg')
      right: 0%
      top: 100%
    .confetti-9
      -webkit-mask: url('~images/global/single-confetti.svg')
      mask: url('~images/global/single-confetti.svg')
      right: 9%
      top: 3%
      transform: rotate(40deg)
    .confetti-10
      -webkit-mask: url('~images/global/confetti-heart.svg')
      mask: url('~images/global/confetti-heart.svg')
      right: 10%
      top: 70%
