@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"
  
.c-dev-environment-banner
  text-align: center
  padding: 10px
  color: #fff
  font-weight: bold

  &--info
    background-color: $petrescue-blue

  &--warning
    background-color: $petrescue-orange
    background-image: url('~images/global/staging-banner.jpg')