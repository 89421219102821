#browser-upgrade
  background: #003226
  color: #fff
  font-weight: normal
  line-height: 1.5
  text-align: center
  text-shadow: 0 1px 6px rgba(0,0,0,0.5)
  a
    color: $petrescue-green
    &:hover
      color: lighten($petrescue-green, 10%)
  .inner
    width: 960px
    padding: 30px 0
    margin: 0 auto
