@font-face
  font-family: 'SourceSerifPro-Light'
  src: url('~fonts/SourceSerifPro-Light.otf')

.blog-posts
  .wrapper
    max-width: 940px
  .share_buttons
    padding: 20px 0
    clear: both
    position: relative
    flex-direction: row
    max-width: 210px
    margin: 0 auto
    @media (max-width: $mobile-width)
      padding: 15px 0

  .blog_post-header
    margin-top: 40px
    position: relative
    .flick
      position: absolute
      top: -28px
      width: 125px
    &-icon
      float: left
      p
        background-color: #383838
        color: white
        font-weight: bold
        padding: 5px 10px
        font-size: 24px
        margin: 3px
      p:first-child
        padding-left: 20px
      p:last-child
        display: inline-block
        margin-left: 15px
    &-dates
      float: right
      margin: 10px 0
      @media (max-width: $mobile-width)
        float: left
        margin: 20px 0
      p
        margin: 0
        font-size: 14px
        line-height: 25px
        @media (max-width: $mobile-width)
          font-size: 16px
    &-heading
      clear: both
      max-width: 65%
      @media (max-width: $mobile-width)
        max-width: 100%
      h1
        margin: 40px 0 15px
        float: left
        text-align: left
        @media (max-width: $mobile-width)
          margin: 0
    &-categories
      margin-top: 45px
      display: flex
      flex-wrap: wrap
      justify-content: flex-end
      @media (max-width: $mobile-width)
        float: left
        margin: 10px 0
      .c-tag
        margin: 3px
        @media (max-width: $mobile-width)
          margin: 10px 15px 10px 0
  .blog_post-image
    width: 100%
  .article_content
    overflow: hidden
    clear: both
    max-width: 850px
    margin: auto
    .published_date, .updated_date
      margin: 0
      font-style: italic

  ul,ol
    padding: 0 20px
    list-style: disc
    li
      font-size: 18px
      line-height: 30px

  h2
    background-image: url('~images/blogposts/h2-background.svg')
    display: inline-block
    padding-right: 4px
    background-position-y: -5px
  h5
    color: #717171
    font-size: 20px
    font-weight: 500
  blockquote
    font-family: $font-serif-light
    font-size: 24px
    margin: 40px 60px
    color: #717171
    text-align: center
    @media (max-width: $mobile-width)
      margin: 40px
    p
      font-size: 24px
  .excerpt
    font-family: $font-serif-light
    color: #717171
    font-size: 30px
    margin-bottom: 40px
  .caption
    font-style: italic
    font-size: 14px
    color: #000000
    text-align: center
  a
    font-weight: bold
  hr
    margin: 20px 0

  img
    @media (max-width: $mobile-width)
      max-width: 100% !important
      height: auto !important

.recommended_posts
  display: flex
  justify-content: space-between
  @media (max-width: $mobile-width)
    flex-direction: column
  .post
    box-shadow: 0 0 8px rgba(0,0,0,0.2)
    background-color: #fff
    width: 23%
    border-radius: 10px
    overflow: hidden
    position: relative
    padding-bottom: 50px
    @media (max-width: $mobile-width)
      width: 100%
      margin: 10px 0
    img
      width: 100%
      height: 180px
      object-fit: cover
    .post_name
      display: block
      margin: 0.5em 1em
      line-height: 24px
      text-decoration: none
      color: $petrescue-green
      font-weight: bold
      &:hover
        color: $text-black
        text-decoration: underline
    .article--button
      position: absolute
      bottom: 0
      width: calc(100% - 20px)
      margin: 10px
