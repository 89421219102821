.campaign-collection

  .search__per-page
    @media (max-width: $mobile-width)
      display: block

    .select-container
      min-width: 150px

  .campaign-collection-cards
    .campaign-collection-card
      height: 120px
      position: relative
      display: flex
      box-shadow: 0 0 8px rgba(0,0,0,0.2)
      margin-bottom: 20px
      @media (max-width: $mobile-width)
        flex-direction: column
        height: auto

      .campaign-collection__img
        position: relative

        img
          width: 120px
          @media (max-width: $mobile-width)
            width: 100%

      .campaign-collection__content
        display: flex
        justify-content: space-between
        width: 100%
        padding: 20px
        align-items: center
        @media (max-width: $mobile-width)
          flex-direction: column

        &__header
          +truncate
          width: 180px
          @media (max-width: $tablet-width)
            width: 170px
          @media (max-width: $mobile-width)
            width: 100%
            margin-top: 0
            text-align: center

        &__group
          display: flex
          justify-content: space-between
          @media (max-width: $mobile-width)
            justify-content: center

        &__group-name
          +truncate
          width: 200px
          @media (max-width: $tablet-width)
            width: 150px
          @media (max-width: $mobile-width)
            width: auto
            margin-bottom: 20px

        &__views, &__enquiries
          border: 1px solid $very-light-grey
          padding: 7px
          display: inline-block
          @media (max-width: $tablet-width)
            margin: 5px

        &__socials
          position: relative
          flex-direction: row
          top: 0
          .share_buttons__button
            width: 35px !important
            height: 35px
            margin: 3px
            @media (max-width: $mobile-width)
              margin: 20px 10px

        .button
          margin-bottom: 0

