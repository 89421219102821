@import "sass/lib/variables"
@import "sass/lib/typography"

.fuse_ad_container
  padding: 13px 0 10px
  text-align: center
  box-shadow: inset 0px 14px 10px -10px rgba(92,92,92,0.1), inset 0px -14px 10px -10px rgba(92,92,92,0.1)
  color: #999
  font-size: 12px
  min-height: 146px
  @media (max-width: $mobile-width)
    font-size: 14px

.fuse_ad
  display: flex
  justify-content: center
  margin: 10px 0

.fuse_ad_search__sidebar
  position: sticky
  top: 5px

.sticky_ad_mobile, .sticky_ad_desktop
  height: fit-content
  width: fit-content
  left: 0
  right: 0
  bottom: 0
  margin: 0 auto
  visibility: hidden
  position: fixed
  z-index: $fuse-sticky-ad-index

.sticky_ad_mobile
  @media (min-width: 500px)
    display: none

.sticky_ad_desktop
  @media (max-width: 500px)
    display: none

  &-close
    font-size: 13px
    top: -18px
    position: absolute
    right: 30%
    background-color: rgba(255,255,255, 0.6)
    border: 1px solid black
    border-radius: 4px 4px 0 0
    border-bottom: none
    padding: 2px 3px
    cursor: pointer
