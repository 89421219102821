@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-subscriptions-cards-new
  &__intro
    text-align: center

  &__form
    position: relative
    background-color: $very-dark-grey
    border-radius: 10px
    margin: 0 auto 40px
    max-width: 650px
    padding: 50px

    &__submit
      margin: 20px 0 0 0
      display: block
      width: 100%
