@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-table-td
  // this should match app/components/table/th/component.sass
  padding: var(--c-table-v-padding, #{calcRem(14)}) var(--c-table-h-padding, #{calcRem(16)})
  font-size: var(--c-table-base-font-size, #{calcRem(14)})
  text-align: left
  color: $very-dark-grey
  border-top: 1px solid $very-light-grey

  &--monospace
    font-family: monospace