html
  scroll-behavior: smooth

body
  background-color: $body-background
  -webkit-font-smoothing: antialiased
  font-family: $font-sans
  font-size: 16px
  color: $text-black
  height: auto !important

  input, select, textarea
    font-family: $font-sans

::selection
  background-color: $text-selection-green
  color: $text-black

h1, .h1
  text-align: center
  font-weight: bold
  font-size: 40px
  font-family: $font-serif
  line-height: 46px
  @media (max-width: $mobile-width)
    font-size: 30px
    line-height: 41px

h2, .h2
  font-weight: bold
  font-size: 36px
  font-family: $font-serif
  line-height: 44px
  color: $text-dark-grey
  @media (max-width: $mobile-width)
    font-size: 30px
    line-height: 41px

h3, .h3
  font-weight: bold
  font-size: 22px
  font-family: $font-sans
  line-height: 28px

h4:not([class*="c-"]), .h4
  font-weight: bold
  font-size: 20px
  font-family: $font-sans
  line-height: 26px

a
  color: $text-black

// we exclude component styles so as not to cause compatability issues with component level styles
// TODO: remove these global styles

p
  font-size: 18px
  line-height: 1.5
  a
    color: $petrescue-green
  a:hover
    color: $darkest
    background-color: $petrescue-green

p:not([class*="c-"])
  letter-spacing: -0.014em
  margin-top: 1.3em
  margin-bottom: 1.3em

ul ,ol
  list-style: none
  padding: 0
  font-size: 18px

ul.bullet-list, ol.bullet-list
  list-style: initial
  padding-left: 20px

  li
    margin: 15px 0


a.skip-main
  left: -999px
  position: absolute
  top: auto
  width: 1px
  height: 1px
  overflow: hidden
  z-index: $skip-main-index
  font-weight: bold

  &:focus, &:active
    background-color: #fff
    left: auto
    top: auto
    width: 30%
    height: auto
    overflow: auto
    margin: 10px 35%
    padding: 5px
    border-radius: 15px
    border: 4px solid blue
    text-align: center
    font-size: 1.2em
    z-index: $skip-main-active-index

.white-container
  background-color: #fff
  border: 1px solid #eee !important
  padding: 20px !important
