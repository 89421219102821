@import "sass/lib/variables"

.c-expand-text
  width: 100%
  position: absolute
  bottom: 0
  left: 0
  text-align: center
  margin: 0
  padding: 120px 0 17px
  font-weight: bold
  color: $dark-grey
  cursor: pointer
  background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(252,252,252,0.9206057422969187) 61%, rgba(252,252,252,1) 85%, rgba(252,252,252,1) 100%)
  border-bottom: 1px solid $very-light-grey
  &:after
    display: block
    content: '\f078'
    font-family: FontAwesome
    width: 30px
    height: 30px
    position: absolute
    right: 0
    bottom: 0
.open .c-expand-text
  background-image: none !important
  &:after
    content: '\f077'
