.pass-graybar
  height: 3px
  background-color: #CCC
  width: 100%
  position: relative

.pass-colorbar
  height: 3px
  background-image: url('~images/icons/passwordstrength.jpg')
  position: absolute
  top: 0
  left: 0

.pass-percent, .pass-text
  font-size: 1em

.pass-percent
  margin-right: 5px
