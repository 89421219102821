.donation_modal
  .c-footer-popup__content
    .payment_path
      display: none

    .actions
      width: 240px
      .donate--button
        width: 240px
      .button, .donate--button
        outline: none
        display: block
        margin-bottom: 5px
      .donation_modal--action_text
        color: $petrescue-green
        font-weight: bold
        text-align: center
        margin-top: 0
        font-size: calcRem(20px)

    .email_text
      margin: 0 0 10px 0
      text-align: center
  &--check
    &.init--hidden
      display: none

    label
      display: flex
      align-items: center
      justify-content: left
      max-width: 500px
      margin: auto
      padding: 10px 20px 10px

      .checker
        input
          height: 30px !important
          width: 30px !important

          &:after
            font-size: 28px !important

      .text
        padding: 10px

      @media (max-width: $tablet-width)
        .text
          max-width: 90%
      @media (max-width: $mobile-width)
        .text
          max-width: 87%

  &--select
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    padding: 10px

    &__amount
      align-items: stretch

    &--text
      max-width: 1170px
      margin: 0.5em auto
      font-weight: bold
      padding: 0 20px

    .selection
      margin: 4px
      min-height: 70px
      font-weight: bold
      text-align: center
      vertical-align: middle
      cursor: pointer
      border-radius: 10px
      border: 1px solid transparent
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

      p
        font-weight: normal
        font-size: 1rem
        line-height: 1.4
        margin: 10px 7px

      // only show transition on desktop - to avoid double click
      // on mobile devices (https://css-tricks.com/annoying-mobile-double-tap-link-issue/)
      @media (min-width: $mobile-width)
        transition: all 0.5s ease

      &:hover, &.active
        border: 1px solid $petrescue-green
      &.active
        color: $white
        background-color: $petrescue-green
        span
          color: $white

    &__amount
      .selection
        padding-top: 22px
        width: 20%
        background-color: $very-light-grey
        color: $text-black
        min-height: 135px
        @media (max-width: $tablet-width)
          &:not(.with-input)
            min-height: 180px
        @media (max-width: $mobile-width)
          width: 46%
        &.dollar-sign
          &:before
            content: '$'
            color: #fff
        span
          font-size: 20px
        &:hover
          color: #fff
          background-color: $petrescue-green

  &--check__gift
    margin: 20px
    display: flex
    justify-content: center
    .checker
      display: inline-block
      margin-right: 10px

  &--error_hint
    margin: 1em 0
    text-align: center
    color: $petrescue-red

  &--no_value_hint
    margin: 1em 0
    text-align: center
    color: white

  &--donate_later
    &.init--hidden
      display: none
    input
      margin-bottom: 10px
      padding: 10px
      width: 100%
      display: block
      border: 1px solid #a2a9b1
      border-radius: 10px
      outline: none

      &.error
        border: 1px solid $petrescue-red !important

      &:focus
        border: 1px solid $petrescue-green

    .submit
      padding: 10px

  &.direct
    .payment_path
      &.direct
        display: block

    .actions
      .later, .dismiss
        display: none


/**
  There is an error in node SASS which will strip ":not" selectors so we have to "flatten" the sass and do the below in order
  make sure this legacy code doesn't touch components.

  We can fix this when https://github.com/sass/node-sass/issues/2685 merges and we upgrade OR when these form elements get refactored
  into components
 **/

.donation_modal--select .selection > input.selection-input#{$negate-components-selector}
  width: 80%
  text-align: center
  font-weight: bold
  color: white
  border: none
  outline: none
  background: transparent
  box-shadow: none
  border-radius: unset
  opacity: 0

.donation_modal--select .selection.active > input.selection-input#{$negate-components-selector}
  border-bottom: 1px solid #FFFFFF
  opacity: 1
