@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-pages-rms-notes
  h1,
  h2,
  h3
    font-family: $font-sans
  h1
    font-size: map-get($type-scale, "4xl")
    text-align: left
  h2
    font-size: map-get($type-scale, "3xl")
    color: $text-black
  h3
    font-size: map-get($type-scale, "2xl")
  p,
  ol,
  ul
    font-size: map-get($type-scale, "sm")
    & + &
      margin-top: map-get($gap-sizes, "sm")
  ol,
  ul
    line-height: 1.5
    padding: revert
  ol
    list-style-type: decimal
  ul
    list-style-type: disc
