.national-pet-adoption-month
  text-align: center
  padding-bottom: 50px
  color: #585858
  p 
    text-align: inherit !important

  &__img
    width: 100%
    background-color: #132E22
    @media (max-width: $mobile-width)
      padding: 20px 50px

  &__heading
    font-size: calcRem(30px)
  &__description
    font-size: calcRem(20px)
    max-width: 760px
    margin: 30px auto 80px
    padding: 0 15px
    @media (max-width: $mobile-width)
      margin: 30px auto 80px

  &__target
    font-size: calcRem(20px)
    max-width: 465px
    padding: 10px 15px 120px
    margin: 0 auto 50px
    position: relative
    &:after
      display: block
      content: " "
      width: 30px
      height: 90px
      position: absolute
      right: 0
      left: 0
      margin-left: auto
      margin-right: auto
      bottom: 0
      background-size: contain
      background-image: url('~images/npam/dashed-line.svg')
      background-repeat: no-repeat

  &__gauge
    margin: 0 auto 60px
    max-width: 500px
    width: calc(100% - 30px)
    @media (max-width: $mobile-width)
      margin: 0 15px 50px

  &__counter
    font-weight: bold
    font-size: calcRem(60px)
    margin: 0 auto 70px
    width: fit-content

  h3.goal
    font-size: calcRem(36px)
    color: $text-dark-grey

  &__links
    display: flex
    align-items: center
    justify-content: center
    max-width: 1400px
    margin: 70px auto 0
    @media (max-width: $tablet-width)
      flex-direction: column
  &__link
    position: relative
    margin-bottom: 50px
  &__link-img
    border-radius: 100%
    display: block
    width: 100%
  &__link-img-bonded,
  &__link-img-cats,
  &__link-img-dogs
    position: relative
    margin: 10px
    &:after
      content: ''
      position: absolute
      display: block
  &__link-img-bonded
    &:after
      bottom: 12px
      right: -10px
      background: url('~images/npam/twice_the_love.svg')
      width: 157px
      height: 153px
  &__link-img-cats
    &:after
      bottom: 25px
      left: -10px
      background: url('~images/npam/hearts-2.svg') no-repeat
      width: 100px
      height: 105px
  &__link-img-dogs
    &:after
      top: 12px
      right: -26px
      background: url('~images/npam/hearts-3.svg') no-repeat
      width: 118px
      height: 153px
  &__link-text
    font-size: calcRem(20px)
    text-decoration: none
    margin: 40px auto 20px !important
    display: block
    width: 275px
    height: 125px
    line-height: 31px
    font-weight: 400
    color: #585858

  .button
    min-width: 240px
