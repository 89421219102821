body
  &.feed-_feed, 
  &.listings-feed_show
    padding: 20px
    width: 100%
    background-color: $section-background-color
    @media (max-width: $mobile-width)
      padding: 15px
    #footer
      text-align: center
  &.feed-_feed
    #main 
      max-width: 1470px
      margin-left: auto
      margin-right: auto

    .pagination
      margin: 5px 0

    .petrescue_logo
      float: right
      font-size: 13px
      @media( max-width: $mobile-width)
        text-align: center
        float: none
      img
        width: 115px
        vertical-align: middle
        margin-left: 5px

    .search-results 
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))

    .cards-listings-preview
      &__img img
        width: 100%
      &__content
        &__link
          text-decoration: none
        .listing_desc
          height: 275px
          overflow: hidden
          word-wrap: break-word
          @media (max-width: 1293px)
            height: 285px
          @media (max-width: 1030px)
            height: 300px
          @media (max-width: $mobile-width)
            height: auto
          p
            margin: 0
          a
            color: $petrescue-green

      .button
        display: block
        margin: 0 auto
        width: fit-content
