@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-icon-button
  --button-text-color: #{$text-grey}
  --button-text-color-on-hover: #{darken($text-grey, 10%)}

  display: inline-flex
  justify-content: center
  align-items: center

  text-align: center
  color: var(--button-text-color)
  font-family: $font-sans
  font-weight: bold
  font-size: calcRem(16)
  cursor: pointer
  transition: 0.1s ease-out
  // reset styles
  border: none
  text-decoration: none

  &:hover, &:focus
    color: var(--button-text-color-on-hover)
    text-decoration: underline

  &:disabled
    opacity: 0.8
    cursor: not-allowed

  &--sm
    font-size: calcRem(14)

  &--red
    --button-text-color: #{$text-warning-red}
    --button-text-color-on-hover: #{darken($text-warning-red, 10%)}
