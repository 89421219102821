@import "sass/lib/variables"
@import "sass/lib/typography"

.c-form-legacy-field-block
  margin-bottom: 15px
  border-radius: 10px

  > li
    list-style: none
  
  &--error
    & input
      border-color: $petrescue-red
  &--admin
    background-color: $admin-background-color
    color: $admin-text-color
    border: 1px solid darken($admin-background-color, 10%)
  &__label
    display: block
    font-weight: 600
    margin-bottom: 10px
    font-size: map-get($type-scale, "base")
    // bit hacky but fixes hierachy issue
    &.inline-block
      display: inline-block

  &__input,
  .fs-block
    display: block
    font-size: 1em
    font-family: $font-sans
    font-weight: 500
    color: $text-black
    line-height: 1.5
    padding: .48em .6em
    max-width: 100% 
    width: 100%
    box-sizing: border-box
    margin: 0
    border: 2px solid $very-dark-grey
    border-radius: $form-input-radius
    appearance: none
    background-color: #fff

  &__errors,
  p.inline-errors
    margin-top: 10px
    margin-bottom: 10px
    font-weight: bold
    font-size: map-get($type-scale, "sm")
    color: $error-red
    &:last-child 
      margin-bottom: 0

  .c-checkbox
    display: grid

  .choices-group
    display: flex
    flex-wrap: wrap
    .c-radio
      display: flex
