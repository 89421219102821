@import "sass/lib/variables"
@import "sass/lib/typography"

.c-search__term
  display: flex
  align-items: center
  @media (max-width: $mobile-width)
    flex-direction: column
    width: 100%
    margin: 10px 0
  .c-search__term__label, .c-search__term__input, .c-search__term__select
    margin-right: 15px
    @media (max-width: $mobile-width)
      margin-right: 0
      margin-bottom: 10px

  .c-search__term__helper-text
    display: none
    @media (max-width: $mobile-width)
      display: block

  .c-search__term__input, .c-search__term__select
    @media (max-width: $mobile-width)
      width: 100%

      .c-text-input
        width: 100%

  .c-search__term__input
    position: relative

    .fa-close
      position: absolute
      right: 12px
      top: 10px
      cursor: pointer

  .c-search__term__submit
    margin-bottom: 0
