.search-listings-container
  +clearfix

  .fuse_ad_container
    &--search_incontent_1
      margin-bottom: 20px

.search-listings
  //remove these styles for sidebar sticky ad
  #site-wrapper, .background-container
    overflow: inherit

  &__modify_filters_btn, &__modify_search_btn
    display: none
    position: relative
  &__modify_filters_btn, &__back_to_search_btn
    button
      text-align: center
      width: 100%
      color: $petrescue-green
      font-size: 16px
      padding-bottom: 0
      padding-top: 20px

  &__modify_filters_btn, &__back_to_search_btn_top
    button:before
      content: ""
      background: url('~images/global/dotted-line-down.svg') no-repeat
      width: 60px
      height: 97px
      position: absolute
      bottom: 28px
      left: 0
      right: 0
      margin-right: auto
      margin-left: auto

  &__back_to_search_btn
    display: none
    position: relative

  &__pagination

    .per-page-container
      margin-top: 5px
      display: block
      float: right

      .per-page
        form
          display: inline-block
          width: inherit

          select
            margin: 0 5px
            padding: 0 8px
            height: 23px
            width: 60px
            font-size: 0.8em
            background-position: 100% -2px !important

    .pagination
      width: 50%

      .per-page-container
        margin: 1px 0 0 0
        float: none

      .scale
        display: flex
        float: right

        .pages
          display: flex

        .first, .prev, .page, .next, .last
          height: 30px
          line-height: 30px
          width: 30px
          color: #626262
          text-align: center
          border: 1px solid #ddd
          background-color: #fff
          display: inline-block

          .number
            display: block
          a
            text-decoration: none
            display: block
            &.disabled
              color: #bbb
              cursor: default
        .current
          margin-left: -1px
          text-decoration: none
          font-weight: bold
          background-color: #ffeecf
          border: 1px solid #fda705
        .first
          border-radius: 2px 0 0 2px
        .last
          border-radius: 0 2px 2px 0
        .text
          display: none

  &__main
    display: flex
    align-items: stretch
    align-self: start

  &__filters
    margin-right: 30px
    max-width: 300px

    &__header
      margin: 0 0 25px 0
      font-family: $font-serif
      font-size: calcRem(24px)
      display: flex
      justify-content: space-between
      align-items: center

      &::after
        display: inline-block
        content: ""
        background: center / contain no-repeat url('~images/icons/filter-icon.svg')
        width: 12px
        height: 8px
        margin-left: auto

    &__section

      header
        font-size: calcRem(16px)
        color: $text-black
        font-weight: 800
        cursor: pointer
        position: relative

        &:after
          margin-top: 3px
          content: ''
          color: $text-black
          font-family: FontAwesome
          font-size: 11px
          font-weight: normal
          font-style: normal
          position: absolute
          top: 50%
          transform: translateY(-50%)
          right: 9px


        &.collapsed
          &:after
            content: ''

      hr
        margin: 0
        padding: 0

      p
        font-size: 14px
        font-style: italic
        margin: 0
        padding: 0 10px

      ul
        li
          &:not(.interstate)
            // This is a huge hack to exclude button elements from this selector but necessary for now while
            // due to this element/selector combo being a bit prolific in the codebase and thus hard to fix
            a:not([class*="button"])
              text-decoration: none
              display: block
              padding: 9px 23px
              border-radius: 2px
              &:hover
                background: $filter-active-bg
              &.active
                font-weight: bold
                background: $filter-active-bg
                position: relative
                &:after
                  content: ""
                  color: $text-black
                  font-family: FontAwesome
                  font-size: 11px
                  font-weight: 400
                  position: absolute
                  top: 50%
                  transform: translateY(-50%)
                  right: 8px

          &.interstate
            margin-top: 15px

          .input-container

            select, input
              padding: 10px 14px
              color: $text-black
              border-radius: 10px
              border: none
              background: lighten($very-light-grey, 5%)
              box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.05)
              &::placeholder
                font-style: italic
                color: lighten($text-black, 50%)
            &.filter
              select, input
                width: 100%
                display: inline-block

            &.filter--search
              position: relative
              & input
                padding-right: 34px
              &::after
                content: ""
                background: center / contain no-repeat url("~images/icons/search-icon.svg")
                width: 13px
                height: 14px
                position: absolute
                top: 50%
                transform: translateY(-50%)
                right: 14px
                opacity: 0.3

          .sub-options
            background-color: #f1f2f2
            display: none
            padding: 0 0 0 10px

            &.active
              display: block

            li
              a
                padding: 4px 10px 4px 25px
                font-size: calcRem(14px)
              &:first-child
                margin-top: 0
                padding-top: 8px
          .postcode-filter

            .postcode-filter__inputs
              display: flex
              justify-content: space-between
              align-items: center

            .separator
              display: block
              margin: 0 8px

            .postcode-filter__inputs__postcode-input
              flex-grow: 1
              input
                width: 100%

            .input-container
              display: inline-block
              select
                margin: 0 0 0 8px
                width: 80px
                height: auto
                display: inline-block

        .controls
          text-align: right
          margin-top: 10px
          display: flex
          align-items: center
          .update-button
            flex-grow: 1

    .create-alert
      margin-top: 62px
      margin-bottom: 33px

  &__results
    flex-grow: 1

    .fuse_ad_container
      flex-grow: 1
      flex-shrink: 0
      flex-basis: 100%
      margin: 15px 0

      @supports (display: grid)
        grid-column-start: 1
        grid-column-end: -1


  @media (max-width: $laptop-width)
    &__filters
      &__section
        ul
          li
            form.postcode-filter
              div:nth-child(3n)
                margin: 8px 0 8px 8px
                display: block

                input
                  margin-bottom: 5px
                  width: 95%


  @media (max-width: 940px)
    &__filters
      &__header
        .clear-filters-container
          a
            display: none
    &__results
      width: calc(100% - 220px)

  @media (max-width: $tablet-width)
    &__pagination
      text-align: center

      .pagination
        width: inherit

        .info
          display: block
          float: none
        .per-page-container
          display: none
    &__results
      width: calc(100% - 220px)

  @media (max-width: $mobile-width)
    &__pagination
      .pagination
        margin-top: 10px
        width: 100%

        .scale
          float: none
          justify-content: center

          .first, .page, .last
            display: none

          .prev, .next
            width: 45%
            height: 50px
            a
              font-weight: bold
              margin-top: 8px

          .prev
            margin-right: 2%
            a
              &:after
                padding: 0 8px
                content: 'Previous'
          .next
            margin-left: 2%
            a
              &:before
                padding: 0 8px
                content: 'Next'

    &__modify_filters_btn
      display: block
    &__back_to_search_btn_top, &__modify_filters_btn
      background-color: $section-background-color
      padding-top: 110px
    &__modify_search_btn
      background-color: $section-background-color
      @media (max-width: $tablet-width)
        position: fixed
        bottom: 0
        width: 100%
        left: 0
        padding-bottom: 56px
        z-index: 2


      button
        height: 50px

    &__results, &__filters
      width: 100%
      max-width: unset
      margin-right: 0

    &__filters
      display: none

      &__header
        .clear-filters-container
          a
            display: inline-block

@media (max-width: $mobile-width)
  body.search-listings-show.search-listings__modify_filters
    .footer--external
      margin-bottom: 68px
