@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-admin-table-td
  // this should match app/components/table/th/component.sass
  // padding: 5px 10px
  padding: calcRem(5) calcRem(10)
  font-size: calcRem(14)
  text-align: left
  color: $very-dark-grey
  border-top: 1px solid $very-light-grey

  &--numerical
    font-family: monospace
