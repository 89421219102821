@import "sass/lib/typography"
@import "sass/lib/variables"

.c-select-search
  margin-bottom: 15px
  width: 100%

.c-select-search-select
  position: relative

.c-select-search-input
  position: relative
  margin-bottom: 0 !important
  width: 100%
  border: 2px solid #000000
  box-shadow: 0 0 2px rgba(0, 0, 0, 40%)
  padding: $form-input-padding
  color: #333333
  font-size: calcRem(17px)
  background-color: #fff

  &::-ms-expand
    display: none

.c-select-search-icons
  position: absolute
  display: flex
  align-items: center
  height: 100%
  top: 0
  right: 0.5em

.c-select-search-options
  border: 1px solid #aaa
  cursor: pointer
  z-index: $search-select-index
  position: absolute
  width: calc(100% - 1px)
  background-color: #fff
  border-radius: 0 0 5px 5px
  max-height: 300px
  overflow-y: scroll

  li
    padding: 0.5em
    width: 100%

    &:hover
      background: #5897fb
      color: #fff

.hidden-all
  display: none !important

.hidden-visually
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  white-space: nowrap
  border: 0
