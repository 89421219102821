@import "sass/lib/variables"
@import "sass/lib/typography"

.c-horizontal-logo-list
  display: flex
  justify-content: flex-start
  list-style-type: none
  padding: 0
  margin: 0 0 0 calc(var(--logo-list-gap-scale, 22px) * -1)

  & > &__logo
    display: flex
    align-items: center
    margin-bottom: var(--logo-list-gap-scale, 22px)
    padding-left: var(--logo-list-gap-scale, 22px)
    img
      height: var(--logo-list-max-height, auto)
      max-width: 100%
      object-fit: contain

  &--wrap
    flex-wrap: wrap
