@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-table-th
  font-family: $font-sans
  font-weight: 700
  font-size: var(--c-table-heading-font-size, #{calcRem(16)})
  text-align: left
  // this should match app/components/table/td/component.sass
  padding: var(--c-table-v-padding, #{calcRem(14)}) var(--c-table-h-padding, #{calcRem(16)})
  

  line-height: 1
  color: $text-black
  width: var(--width-percentage, auto)
  border-bottom: 2px solid $very-light-grey
