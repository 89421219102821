.dashboards-show
  .wrapper:not(.nav-wrapper)
    max-width: 1140px
.homepage-hero
  width: 100%
  margin-bottom: 20px
  transition: background 300ms linear
  &.hero-1
    +high-contrast-image($homepage-banner-1)
  &.hero-2
    +high-contrast-image($homepage-banner-2)
  &.hero-3
    +high-contrast-image($homepage-banner-3)
  &.hero-4
    +high-contrast-image($homepage-banner-4)
  &.hero-5
    +high-contrast-image($homepage-banner-5)
  &.hero-6
    +high-contrast-image($homepage-banner-6)
  &.hero-7
    +high-contrast-image($homepage-banner-7)
  &.hero-8
    +high-contrast-image($homepage-banner-8)
  &.hero-9
    +high-contrast-image($homepage-banner-9)
  &.hero-10
    +high-contrast-image($homepage-banner-10)
    @media (max-width: $mobile-width)
      +high-contrast-image($homepage-banner-2)
  &.hero-11
    +high-contrast-image($homepage-banner-11)
  &.hero-12
    +high-contrast-image($homepage-banner-12)
  &.hero-13
    +high-contrast-image($homepage-banner-13)
    @media (max-width: $mobile-width)
      +high-contrast-image($homepage-banner-1)
  &.hero-14
    +high-contrast-image($homepage-banner-14)
  &.hero-15
    +high-contrast-image($homepage-banner-15)
  &.hero-16
    +high-contrast-image($homepage-banner-16)
  .wrapper
    margin-top: 0
    min-height: 610px
    height: auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    color: white
  .createhappiness
    max-height: 350px
    max-width: 100%
    filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.3))
  h3
    a
      color: #fff
  .mobile-show
    display: none

.homepage__intro
  &--heading
    color: $petrescue-green
    font-size: calcRem(36px)
    line-height: 30px
    margin-bottom: 13px
  &--subheading
    font-size: calcRem(30px)
    text-align: center
    margin: 0
  p
    text-align: center
    margin: 1em auto
    max-width: 750px
    @media (max-width: $mobile-width)
      text-align: left
    a
      color: $text-black

  .article--button
    margin: auto
    display: block
    width: 200px
    margin-bottom: 40px

.homepage__impact
  position: relative
  background-size: cover
  margin-top: 60px
  @media (max-width: 850px)
    background-size: contain
    background-position-y: 108px
  &-shadow
    background: url('~images/homepage/background_shadow.svg') no-repeat center center
    width: 100%
    height: 100px
    position: absolute
    bottom: 60px
    background-size: cover

  &__heading
    max-width: 550px
    margin: auto
    margin-bottom: 80px
    text-align: center
    @media (max-width: $mobile-width)
      margin: 0 15px 130px
    h2
      margin-bottom: 10px
      font-size: 30px
      @media (max-width: $mobile-width)
        font-size: 40px
    span
      font-style: italic
      font-size: 12px
      line-height: 26px
  &__stats-container
    display: flex
    max-width: 1100px
    margin: auto
    padding: 0 15px
    justify-content: space-between
    @media (max-width: 850px)
      flex-direction: column
      padding-bottom: 120px
    &__stat
      text-align: center
      position: relative
      margin-bottom: 10px
      @media (max-width: $mobile-width)
        margin-bottom: 20px
      &:after
        content: ""
        background: url('~images/homepage/shadow.svg') no-repeat center center
        width: 170px
        height: 25px
        position: absolute
        bottom: -55px
        left: 0
        right: 0
        margin-right: auto
        margin-left: auto
      &:first-of-type h2:after
        background: url('~images/homepage/confetti3.svg')
        width: 100px
        height: 220px
        top: -180px
        @media (max-width: 850px)
          display: block
          background: url('~images/homepage/confetti4.svg')
          height: 150px
          top: -120px
      &:last-of-type h2:after
        background: url('~images/homepage/confetti1.svg')
        width: 95px
        height: 200px
        top: -160px

      .article--button--strong
        @media (max-width: $mobile-width)
          width: fit-content

    h3
      font-size: 30px
      line-height: 32px
      color: $text-black
      margin-top: 0
      font-family: $font-serif
      @media (max-width: $tablet-width)
        font-size: 26px
    h2
      color: #fff
      display: block
      font-size: 65px
      line-height: 89px
      margin-bottom: 0
      @media (max-width: $tablet-width)
        font-size: 50px
        line-height: initial
      &:after
        content: ""
        background: url('~images/homepage/confetti2.svg') no-repeat
        width: 80px
        height: 90px
        position: absolute
        top: -55px
        left: 0
        right: 0
        margin-right: auto
        margin-left: auto
        @media (max-width: 850px)
          display: none

  &__footer
    background-color: #7FC561
    height: 66px
    width: 100%
    &-images
      max-width: 1100px
      margin: auto
      position: relative
      .image1, .image2
        width: 150px
        height: 150px
        top: -100px
        position: absolute
      .image1
        background: url('~images/homepage/group1.svg') no-repeat
        left: 23%
        @media (max-width: $mobile-width)
          display: none
      .image2
        background: url('~images/homepage/group2.svg') no-repeat
        right: 23%
        @media (max-width: $mobile-width)
          left: 6%

.homepage__search
  background-color: $almost-white
  padding: 20px
  h3
    text-align: center
    margin: 1em auto
    max-width: 640px

  &__pet-links
    display: flex
    flex-wrap: wrap
    justify-content: center

  &__pet-links__item

    width: 170px
    height: 160px
    background: $body-background $pet-link-icons no-repeat 50% 0
    background-size: 100px auto
    margin: 0 10px 10px
    padding: 120px 5px 10px
    text-decoration: none
    text-align: center
    font-size: .9em
    font-weight: bold
    transition: all 500ms ease-in-out
    &.dogs
      background-position: 50% 13px
    &.cats
      background-position: 50% -170px
    &.other
      background-position: 50% -370px

    &.petrescue_search
      position: relative
      background-image: none
      padding: 10px 5px

      &:hover
        background-color: $body-background
        color: $petrescue-green

      @media (max-width: $mobile-width)
        padding: 10px 20px

      form
        justify-content: center
        height: 100%
        display: flex
        flex-direction: column
        align-items: center
        @media (max-width: $mobile-width)
          flex-direction: column-reverse
          justify-content: space-between

        label
          @media (max-width: $mobile-width)
            position: static
            text-align: left

        .search-wrapper
          width: 130px
          display: flex
          @media (max-width: $mobile-width)
            width: 100%
            margin-top: 5px

          input#search_keyword
            border-radius: 5px 0 0 5px
            padding: 10px 5px
            &::placeholder
              font-style: italic
              font-family: $font-serif
            @media (max-width: $mobile-width)
              padding: 5px
              font-size: calcRem(16px)
              margin: 0

          button
            background-color: $petrescue-green
            border: 1px solid $petrescue-green
            color: $text-white
            position: relative
            left: -1px
            height: 42px
            text-transform: uppercase
            border-radius: 0 5px 5px 0
            font-weight: 700
            &:hover,
            &:focus
              background-color: darken($petrescue-green, 10%)
              border-color: darken($petrescue-green, 10%)
            @media (max-width: $mobile-width)
              padding: 0 8px

    label
      position: absolute
      bottom: 21px
      text-align: center
      width: 100%
      color: #333
    &:hover
      @media (pointer:fine)
      background-color: $petrescue-green
      color: $very-dark-grey
      @include text-selection($text-selction-light)

@media (max-width: $mobile-width)
  .homepage-hero
    h1
      font-size: 2.4em
    h2, h3
      font-size: 1.2em

  .homepage__search__pet-links__item
    width: 100%
    margin: 0 0 10px
    text-align: left
    padding: 30px 20px
    height: 90px
    font-size: 18px
    background-size: 65px
    border-radius: 8px
    &.dogs
      background-position: 93% 9px
    &.cats
      background-position: 93% -110px
    &.other
      background-position: 93% -240px

.homepage__blog-preview,
.homepage__blog-preview__wrapper
  display: flex
  box-shadow: 0 1px 0 0 rgba(0,0,0,.1)
  border-radius: 10px
  background-color: #fff
  overflow: hidden
  img.homepage__blog-preview__img
    width: 65%
    height: 400px
    object-fit: cover
    @media (max-width: 718px)
      width: 100%
      height: auto
      max-height: 400px
  @media (max-width: 718px)
    flex-wrap: wrap
    flex-direction: column-reverse

.homepage__blog-preview__content
  padding: 25px
  .homepage__blog-preview__content__copy
    margin: 0 0 10px
  h2.homepage__blog-preview__content__copy
    color: $petrescue-green
    overflow: hidden
  @media (max-width: 718px)
    margin: 0
    max-width: 100%

.homepage__article-content__heading
  font-size: 40px
  line-height: 46px
  color: $text-black

.homepage__articles
  background: url('~images/homepage/search-background.svg') no-repeat, #f2f2f2
  background-position-y: bottom

.homepage__article-content
  .homepage__articles-card
    img
      width: 100%
      object-fit: cover
    &__details
      margin: 15px
      padding-bottom: 65px
      a
        &:hover
          color: $text-black
          text-decoration: underline
       
  &__listings
    &__link
      position: relative
      text-align: right

      &:first-child
        img
          filter: grayscale(80%)
      &:hover
        &:first-child img
          filter: none
        img
          transform: translateY(-4px)
          box-shadow: 0 0 8px rgba(0,0,0,0.15)
        span
          background-color: $petrescue-green
      img
        transition: 0.1s ease-out
        object-fit: cover
        width: 100%
        box-shadow: 0 1px 0 0px rgba(0, 0, 0, 0.1)
      span
        transition: 0.5s ease-out
        position: absolute
        left: -3px
        font-size: 20px
        bottom: 30px
        color: #fff
        background-color: $very-dark-grey
        font-family: "Source Serif Pro", serif
        padding: 6px 10px

.cards-common-subscribe-newsletter_container
  .cards-common-subscribe-newsletter
    .email
      width: 90% !important

@media (max-width: $mobile-width)
  .cards-common-subscribe-newsletter_container
    .cards-common-subscribe-newsletter
      padding: 0 1.2em

      .email
        width: 100% !important
