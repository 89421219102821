@import "sass/lib/variables"
@import "sass/lib/typography"

.c-form-legacy-hint
  font-size: map-get($type-scale, "sm")
  line-height: 1.5
  margin-top: 10px
  margin-bottom: 10px
  &:last-child
    margin-bottom: 0
