.select2-container#{$negate-components-selector}
  &:focus
    outline: 0
  .select2-selection
    border: 2px solid $form-input-border-color
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4)
    min-height: $form-input-height
    border-radius: $form-input-radius
    height: auto
    display: flex
    align-items: center
    .select2-selection__rendered
      // line-height: $form-input-height
      li
        @media (max-width: $mobile-width)
          max-width: 269px
    .select2-selection__arrow
      height: 100%
      top: 0
      b
        border-width: 6px 5px 0 5px
        margin-left: -7px
  .select2-selection--multiple
    .select2-selection__choice
      border: 0
      line-height: 25px
      margin-top: 4px
      .select2-selection__choice__remove
        color: #FF3333

.select2-dropdown--below
  top: -1px
  border-color: #D7D9DB
  .select2-results__options
    li
      padding: 3px

.error
  .select2-selection
    border: 1px solid #E21B2E

/**
    Overides:
    These styles are copied from select2's css file but with some juiced up selectors so they can override our css cascade which
    otherwise would introduce bugs.
 **/

.select2-container--default .select2-search--inline .select2-search__field#{$negate-components-selector}
  background: transparent
  border: none
  outline: 0
  box-shadow: none
  -webkit-appearance: textfield

.select2-container--default .select2-search--dropdown .select2-search__field#{$negate-components-selector}
  // height: $form-input-height
  padding: $form-input-padding

.select2-container .select2-search--inline .select2-search__field#{$negate-components-selector}
  box-sizing: border-box
  border: none
  font-size: 100%
  margin-top: 5px
  padding: 0
