@import "sass/lib/variables"

@each $name, $colour in $colours
  #{'.bg-' + $name}
    background-color: $colour

.mask-contain
  mask-size: contain
  mask-repeat: no-repeat
  mask-position: center center
