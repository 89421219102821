@import "sass/lib/typography"
@import "sass/lib/variables"

.c-p
  font-size: var(--util-font-size, #{calcRem(18)})
  line-height: var(--util-line-height, 1.5)
  
  &--no-implicit-spacing
    margin: var(--util-margin-top, 0) var(--util-margin-right, 0) var(--util-margin-bottom, 0) var(--util-margin-left, 0) 
     
  & + &:not(&--no-implicit-spacing)
    margin-top: 1.3em
