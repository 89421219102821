@import "sass/lib/variables"
  
/**
  Generates utility size classes.
 **/

$size-amounts: (0, 50, 70, 100, 150, 200, 250, 300, 350, 450, 720, 960, 1200, 1440) // Adjust this to include the pixel amounts you need.

@each $size in $size-amounts
  .mw-#{$size}
    max-width: #{$size}px
  .w-#{$size}
    width: #{$size}px
  .minw-#{$size}
    min-width: #{$size}px
  .minh-#{$size}
    min-height: #{$size}px
  .mh-#{$size}
    max-height: #{$size}px
  .h-#{$size}
    height: #{$size}px

@each $width, $value in $container-sizes
  .mw-#{$width}
    max-width: $value
  .w-#{$width}
    width: $value
  .mh-#{$width}
    max-height: #{$value}
  .h-#{$width}
    height: #{$value}

.full
  height: 100%
  width: 100%

.mh-full
  max-height: 100%

.mw-full
  max-width: 100%

.w-third
  width: 31%
  @media (max-width: $mobile-width)
    width: 100%

.w-fit-content
  width: fit-content
