@import "sass/lib/variables"
@import "sass/lib/typography"

.c-mega-nav__dropdown__list
  margin-right: 50px
  text-align: left
  &-heading
    font-size: calcRem(20px)
    margin-top: 0
    margin-left: 10px
    font-family: $font-serif


