.admin-tools-pet_search
  .search__filter__text
    display: flex

    .search_input
      display: flex
      position: relative
      @media (max-width: $mobile-width)
        flex-direction: column

      .search_term
        width: 400px !important

      #search_type
        position: absolute
        right: 24px
        top: 2px
        height: calc($form-input-height - 4px)
        border-radius: 0
        border: transparent
        box-shadow: none
        width: fit-content !important
        border-radius: 0 $form-input-radius $form-input-radius 0
        border-left: 1px solid #000
        padding: 0 32px 0 5px
        margin-bottom: 0
        @media (max-width: $mobile-width)
          position: relative
          width: 100%
          top: 0
          right: 0
          border: 2px solid #000000
          border-radius: $form-input-radius
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.4)

  table
    tr
      &.rehomed
        background-color: #e8fbda
      &.on_hold
        background-color: #ffdc9d
      &.removed, &.suspended
        background-color: #ffe4e4

      .state
        margin-top: 5px
        display: block
