@import "sass/lib/variables"

/**
    Generates utility padding and margins classes.
 **/

$border-thicknesses: (0, 1, 2, 3) // Adjust this to include the pixel amounts you need.
$border-sides: (top, bottom, left, right) // Leave this variable alone

[class*="border"]:not([class*="tw-"])
  border-style: var(--border-style, solid)

@each $side in $border-sides
  @each $thickness in $border-thicknesses
    .border-#{$side}-#{$thickness}
      border-#{$side}: #{$thickness}px solid var(--border-color, #{$dark-grey})

@each $thickness in $border-thicknesses
  .border-v-#{$thickness}
    border-left: #{$thickness}px solid var(--border-color, #{$dark-grey})
    border-right: #{$thickness}px solid var(--border-color, #{$dark-grey})

@each $thickness in $border-thicknesses
  .border-h-#{$thickness}
    border-top: #{$thickness}px solid var(--border-color, #{$dark-grey})
    border-bottom: #{$thickness}px solid var(--border-color, #{$dark-grey})

@each $name, $colour in $colours
  #{'.border-' + $name}
    border-color: var(--border-color, #{$colour})
  
  #{'.hover-border-' + $name +':hover'}
    border-color: var(--hover-border-color, #{$colour})
  
  #{'.focus-border-' + $name +':focus'}
    border-color: var(--focus-border-color, #{$colour})

@each $thickness in $border-thicknesses
  .border-#{$thickness}
    border-width: #{$thickness}px

// Not sure if this is the right place for this
.focus-outline
  &:focus
    outline-offset: 2px
    outline-style: solid
    outline-width: 1px
    outline-color: var(--focus-outline-color, currentcolor)
