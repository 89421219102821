@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-valentines-day-promo-banner
  $horizontal-spacing: calcRem(16)

  overflow: hidden
  padding: 15px 15px
  position: relative
  background-color: $petrescue-green
  color: white

  @media (min-width: $tablet-small-width)
    display: flex
    justify-content: center
    align-items: center
    padding: 20px 15px

  & &__line
    flex-shrink: 0
    position: absolute

    // position the line on mobile so it visually nests in the right
    // hand side corner
    top: 49px
    left: calc(100% - 132px)
    transform: rotateY(180deg)

    @media (min-width: $tablet-small-width)
      position: static
      transform: rotateY(0deg)

  & &__cta // extra & set to increase specificity
    min-width: 200px
    margin-top: 16px

    @media (min-width: $tablet-small-width)
      margin-top: 0
      margin-left: $horizontal-spacing

  &__text
    font-family: $font-serif
    vertical-align: middle
    position: relative
    line-height: 1.5
    font-size: calcRem(20px)
    margin-top: 0
    margin-bottom: 0

    @media (min-width: $tablet-small-width)
      font-size: calcRem(22px)
      line-height: 1

    & > span
      font-weight: bold
      display: block

      @media (min-width: $tablet-small-width)
        font-weight: normal
        display: inline
