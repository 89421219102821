@import "sass/lib/variables"
@import "sass/lib/typography"

.c-our-work-outcomes-tile
  padding: 0 1rem
  display: flex
  gap: 1rem
  align-items: center
  border-bottom: 1px dashed black
  font-family: $font-serif
  font-weight: bold

  @media (min-width: 768px)
    padding: 0 3rem 0 1rem


  &-icon
    flex: 0 0 40px
    height: 40px
    display: block
    background-repeat: no-repeat
    background-size: contain
  &-icon.safety
    background-image: url('~images/our-work/safety.svg')
  &-icon.home
    background-image: url('~images/our-work/home.svg')
  &-icon.capacity
    background-image: url('~images/our-work/capacity.svg')
  &-icon.recognition
    background-image: url('~images/our-work/recognition.svg')
  &-icon.prevent
    background-image: url('~images/our-work/prevent.svg')
  &-icon.reduce
    background-image: url('~images/our-work/reduce.svg')
