$z-index-amounts: (1, 2, 3) // Adjust this to include the z index amounts you need.
$space-amounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100) // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right) // Leave this variable alone
$align: (start, center, end)

.flex
  display: flex
  width: 100%
  align-items: center

  @media (max-width: $mobile-width)
    flex-direction: column

.flex-grow
  flex-grow: 1

.flex-no-shrink
  flex-shrink: 0

.space-between
  justify-content: space-between

.flex-center
  justify-content: center

.flex-column
  flex-direction: column

.flex-row
  flex-direction: row

.flex-wrap
  flex-wrap: wrap

.relative
  position: relative

.absolute
  position: absolute

.centered
  text-align: center
  margin: auto

.justify-content
  &-end
    justify-content: flex-end
  &-even
    justify-content: space-evenly
  &-center
    justify-content: center

@each $position in $align
  .align-items-#{$position}
    align-items: $position
    
  .align-self-#{$position}
    align-self: $position

.flex-center-column
  display: flex
  flex-direction: column
  align-items: center

@each $space in $space-amounts
  @each $side in $sides
    .#{$side}-#{$space}
      #{$side}: #{$space}px

@each $index in $z-index-amounts
    .z-#{$index}
      z-index: #{$index}

.overflow-x-auto 
  overflow-x: auto
