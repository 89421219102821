.payments__new
  .christmas_2022

    .christmas_2022-jump-to-payment 
      .c-donate-button
        border-radius: 10px !important

.c-campaign-banner
  .christmas_2022
    .c-container-standard
      max-width: map-get($container-sizes, "thinner")
      .c-campaign-banner_inner
        justify-content: space-between
        .c-campaign-banner__text
          position: relative
          &:after
            height: 30px
            margin-left: 10px
            position: absolute
            width: 90px
            background-repeat: no-repeat
            background-image: url('~images/global/dotted-line-white.svg')
            @media (min-width: $tablet-width)
              content: ''

.christmas_2022
  .advent-calendar
    &--container
      padding: 0 30px 
      @media (min-width: $laptop-width)
        padding: 0 15px 

.c-footer-popup.christmas_2022
  .image
    width: 23%
    @media (max-width: $tablet-small-width)
      width: 80%
    .main-image
      border-radius: unset
    .main-image--mobile
      display: none
      max-width: 100%
      @media (max-width: $tablet-small-width)
        display: block

  .c-popups-donations--action_text
    @media (max-width: $tablet-small-width)
      display: none   

.c-campaign-banner.christmas_2022
  .c-campaign-banner__gift-icon
    width: 26%
    height: auto
    @media (max-width: $tablet-width)
      display: none
  .c-campaign-banner__text
    max-width: 530px
