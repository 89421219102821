@import "sass/lib/variables"
  
.c-payment-form-field
  label
    display: block
    margin-bottom: 5px
    font-weight: 600 !important

  .hint-above
    color: var(--hint-above-color, #{$light-grey})
    
  .country-list
    background: $very-dark-grey
