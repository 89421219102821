@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-table
  --c-table-v-padding: #{calcRem(14)}
  --c-table-h-padding: #{calcRem(16)}
  --c-table-base-font-size: #{calcRem(14)}
  --c-table-heading-font-size: #{calcRem(16)}

  font-variant-numeric: lining-nums tabular-nums
  border-collapse: collapse
  width: 100%

  &--sm
    --c-table-v-padding: #{calcRem(5)}
    --c-table-h-padding: #{calcRem(10)}
