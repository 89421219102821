.pr-note
  margin: 0 auto
  padding: 10px 20px
  width: 100%
  max-width: 1080px
  background-color: $almost-white
  text-align: center

  .heading
    padding-bottom: 5px
    font-weight: bold
    border-bottom: 1px solid $dark-grey

  .content
    margin-top: 5px
