@import "sass/lib/variables"

.c-payment-form
  .payments__new--widget_payment
    &--error_hint
      margin: 1em 0
      text-align: center
      color: $petrescue-red

  &__payment-option
    &.active
      --border-color: $almost-white
      --hover-border-color: $almost-white

  