.records-container
  .search__filters
    form
      display: flex
      justify-content: space-between
      align-items: center
      @media (max-width: $mobile-width)
        display: block
    .search-wrapper
      display: flex
      align-items: center
      @media (max-width: $mobile-width)
        display: block
    .pagination-wrapper
      display: flex
      justify-content: flex-end
      align-items: center
      @media (max-width: $mobile-width)
        flex-direction: column
        justify-content: flex-start
        margin-top: 13px
      & > .pagination
        margin-left: 20px
  .table_form
    flex-grow: 1
    @media (max-width: $mobile-width)
      width: 100%
  table.records-container__table
    width: 100%
    thead
      tr th
        text-align: left
        padding: 0 8px
        font-size: 14px
        max-width: 50%
        &:last-child
          padding: 8px 0 8px 0
        a
          text-decoration: none
          i
            margin-left: 5px
            font-size: 11px
    tbody
      tr
        font-size: 15px
        border-top: 1px solid #eee
        td
          padding: 8px
          li
            display: block
            width: 100%
          @media (max-width: $tablet-width)
            word-break: break-all
        td:last-child
          padding: 8px 0 8px 0
        .name
          max-width: 200px
        .button
          margin-right: 10px
          margin-bottom: 0
          min-width: 100px
          @media (max-width: $tablet-width)
            margin-bottom: 10px
        .button:last-of-type
          margin-right: 0
          @media (max-width: $tablet-width)
            margin-bottom: 0
        .image
          a img
            width: 50px
            height: 50px
            margin: 0

  .mass-records-update
    border-top: 1px solid $light-grey
    display: none
    flex-wrap: wrap
    margin: 15px 0 !important
    padding: calcRem(16px) 0 !important

    @media (min-width: $tablet-width)
      display: flex

    &--row
      &:first-child
        margin-bottom: calcRem(16px)

      @media (min-width: $mobile-width)
        &__xs
          width: 50%

        &__s
          width: 75%

    .left
      white-space: nowrap
      width: 100%

      @media (min-width: $mobile-width)
        width: auto

      select
        margin-bottom: 0

      .button
        margin: 0
        padding: calcRem(5px) calcRem(25px)

    .right
      flex-grow: 1
      margin: calcRem(16px) 0
      min-width: 52%
      width: min-content

      @media (min-width: $mobile-width)
        margin: calcRem(16px) calcRem(32px)

      .hint
        border: 2px dashed $light-grey
        border-radius: 8px
        margin: 0 auto
        padding: 0 2em

        p
          color: $text-light-grey
          font-size: calcRem(14px)

// Corrections for breed output on admin/listings#new
p#breed_output
  color: $petrescue-green
  font-size: 0.9em
  font-weight: bold
  margin-top: 0

// Bump listings
.bump-listing
  margin-bottom: 20px

//listing form
// Hack fix padding
.listing_form
  padding-left: 15px
  padding-right: 15px
  .listing_form
    padding-left: 0
    padding-right: 0
.listing_form
  .listing_media_container
    display: flex
    justify-content: space-between
    @media(max-width: $tablet-width)
      flex-direction: column

  [data-states]
    display: none

  .age .flex
    li
      margin-right: 5px
      @media (max-width: $mobile-width)
        margin-right: 0

  .age_container
    justify-content: space-between
    label
      font-weight: 500
  #listing_interstate_input fieldset, #listing_gender_input fieldset, #listing_desexed_input fieldset, #listing_contact_preferred_method_input, #listing_vaccinated_input, #listing_wormed_input, #listing_heart_worm_treated_input, #listing_bred_in_care_of_group_input ol
    width: 50%
    @media (max-width: $mobile-width)
      width: 100%
  #listing_display_contact_preferred_method_only_input
    padding-top: 10px
    border-top: 1px solid #eee
  .datepicker_container
    span
      display: none
    @media (max-width: $mobile-width)
      display: flex
      align-items: center
      justify-content: center
      border: 1px solid #D7D9DB
      width: 100%
      border-radius: 5px
      padding: 5px
      margin: 12px 0
      text-align: center
      span
        display: inline-block
  #listing_date_of_birth_input
    width: 35px
    margin: 15px 0 0 0
    @media (max-width: $mobile-width)
      display: inline-block
      margin: 0
      height: 25px

  .character-count
    text-align: right
    opacity: 0.6
    font-size: 13px
    margin-bottom: 10px
    @media (max-width: $mobile-width)
      font-size: 14px
      margin: 0 0 10px 0

// Do not display options list (for firefox)
.listings-controller
  .options-for-species-dropdown
    display: none
