.admin-groups
  table
    tr
      &.pending
        background-color: lighten($petrescue-yellow, 30%)
      &.suspended, &.deleted
        background-color: $very-light-grey

.admin-groups-groups-edit
  form
    .choices-group
      justify-content: flex-start
    .choice
      width: 33%
      @media (max-width: $tablet-width)
        width: 50%
    .avatar
      position: relative
      width: 100%
      icon
        margin: 0 auto
      img
        display: block
        max-width: 100%
        margin: 0 auto
      li
        text-indent: 5px
        margin-bottom: 0
      #group_avatar
        border: 0
    #group_species_input .choice, #group_states_input .choice
      @media (max-width: $mobile-width)
        width: 50%
    .group-settings
      textarea
        height: auto

.admin-groups-listings
  @media (max-width: $tablet-width)
    .search__displaying
      margin: 0 auto
      margin-bottom: 10px

    .search-main-content
      flex-direction: column
      width: 100%

      .filters-sidebar
        display: block
        width: 100%

      .listing-container
        border-bottom: 1px solid #eee
        margin-top: 10px

        .listing-container__photo
          img
            margin: 0 auto
            display: block

        .listing-details
          margin-bottom: 20px
          h3
            margin: 10px 0
          .active
            color: $petrescue-green
          .on_hold
            color: $petrescue-orange
          .rehomed
            color: #005DA5
          .removed, .suspended
            color: $petrescue-red
          p
            margin: 10px 0
