//users table
.admin-users-index, .admin-groups-users
  table
    .button, .button--red
      margin: 10px 8px 0 0
    .button--red
      margin: 10px 0 0 0
  @media (max-width: $tablet-width)
    .button, .button--red
      margin-bottom: 20px
.admin-users-index .records-container table tbody tr .button
  min-width: 75px

.admin-users .wrapper .top-buttons, .admin-groups-users .wrapper .top-buttons
  display: flex
  margin-bottom: 15px
  align-items: center
  justify-content: space-between
  flex-wrap: wrap
  > a, > button
    min-width: 130px
    padding: 8px 15px

  @media (max-width: $mobile-width)
    flex-direction: column
    -webkit-flex-direction: column
    -ms-flex-direction: column
    -moz-flex-direction: column
    a
      margin: 0 0 20px 0
      width: 100%
