.subscriptions__cards__thank_you
  text-align: center

  .large_image
    margin: 0 auto
    display: block
    width: 30%
    border-radius: 50%
    @media (max-width: $mobile-width)
      width: 100%

  .small_logo
    width: 200px
    margin-top: 25px

  .motto_text
    margin-top: 0
    font-weight: bold
    text-align: center !important
