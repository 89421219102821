@import "sass/lib/variables"

.c-article-card-small
  &__card
    text-decoration: none
    transition: all ease 0.2s
    padding: 0 40px
    display: block
    font-weight: 700
    text-align: center
    border-radius: 10px
    background-color: #efefef
    position: relative
    height: 106px
    display: flex
    align-items: center
    justify-content: center
    @media (max-width: $mobile-width)
      padding: 0 25px
    &:hover
      background-color: #2F2F2E
      transform: translateY(-6px)
      color: #fff

  .disabled
    &:hover
      background-color: #efefef
      transform: none
      color: inherit

  .fa-check-circle
    position: absolute
    top: -10px
    right: 20px
    font-size: 29px
    color: #C7C7C7
