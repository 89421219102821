@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-pill-button
  --button-text-color: white
  --button-primary-color: #{$petrescue-green}
  --button-primary-color-on-hover: #{darken($petrescue-green, 10%)}

  display: inline-flex
  justify-content: center
  align-items: center

  text-align: center
  padding: calcRem(14) calcRem(30)
  color: var(--button-text-color)
  background-color: var(--button-primary-color)
  font-family: $font-sans
  font-weight: bold
  font-size: calcRem(16)
  cursor: pointer
  border-radius: 9999em
  transition: 0.1s ease-out
  // reset styles
  border: none
  text-decoration: none

  &:hover, &:focus
    background-color: var(--button-primary-color-on-hover)

  &:disabled
    opacity: 0.8
    cursor: not-allowed

  &--sm
    padding: calcRem(10) calcRem(20)
    font-size: calcRem(14)
  &--xs
    padding: calcRem(5) calcRem(10)
    font-size: calcRem(12)

  &--grey
    --button-primary-color: #{$very-dark-grey}
    --button-primary-color-on-hover: #{darken($very-dark-grey, 10%)}

  &--lightgrey
    --button-primary-color: #{$light-grey}
    --button-primary-color-on-hover: #{darken($light-grey, 10%)}
    --button-text-color: #{$text-black}

  &--yellow
    --button-primary-color: #{$petrescue-yellow}
    --button-primary-color-on-hover: #{darken($petrescue-yellow, 10%)}
    --button-text-color: #{$text-black}
  
  &--red
    --button-primary-color: #{$petrescue-red}
    --button-primary-color-on-hover: #{darken($petrescue-red, 10%)}

  &--offwhite
    --button-primary-color: #{$section-background-color}
    --button-primary-color-on-hover: #{darken($text-white, 10%)}
    --button-text-color: #{$very-dark-grey}

  // TODO: Need to confirm this to use the colour scheme property instead
  &.button--grey
    background-color: $very-dark-grey
    border-color: $very-dark-grey
    &:hover
     background-color: darken($very-dark-grey, 10%)

  &.button-d-block
    display: block
    width: -webkit-max-content
    width: -moz-max-content
    width: max-content
