body.redirect-call
  .content
    margin: 0 auto
    width: 70%

    h1#logo
      a
        background: transparent $pr-logo no-repeat 0 50%
        text-indent: -9999px
        height: 40px
        margin-top: 5px
        width: 222px
        display: block

    .link-preview
      color: $vivid-blue
      word-break: break-word
    .last
      padding-bottom: 1.5em
    .actions
      .button
        width: 100%

  #footer
    text-align: center
