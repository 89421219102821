@import "sass/lib/variables"

[class*="c-container-"]
  padding: var(--util-padding-top, 0) var(--util-padding-left, #{$container-padding}) var(--util-padding-bottom, 0) var(--util-padding-right, #{$container-padding})

@each $width, $value in $container-sizes
  .c-container-#{$width}
    max-width: $value

.c-container
  &--admin
    background-color: $admin-background-color
    border: 1px solid darken($admin-background-color, 10%)
    --util-padding-top: #{$container-padding}
    --util-padding-bottom: #{$container-padding}
