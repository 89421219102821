$photo-breakpoint: 650px

.pet-listing .background-container
  margin-bottom: 10px
  .wrapper
    margin: 0 auto

.admin-edit
  .flex
    align-items: stretch
    justify-content: space-between

    &--end
      justify-content: flex-end

      .col
        margin-right: 1em

    &--space-around
      justify-content: space-around

  &-container
    background-color: #f6f6f6
    padding: 20px
    margin-bottom: 15px
    width: 32%
    min-height: 260px
    position: relative
    @media (max-width: $mobile-width)
      width: 100%
      height: auto

    &-numbers
      display: flex
      justify-content: space-between
      border-bottom: 1px solid #333
      padding-bottom: 5px

      .flex
        align-items: center
        justify-content: left
        @media (max-width: $mobile-width)
          flex-direction: row

        i.fa-eye
          font-size: 25px
        i.fa-envelope
          font-size: 15px
          background-color: #333
          border-radius: 50%
          color: #fff
          padding: 7px
        p
          margin: 7px

    h3
      margin-top: 0
    h3.status span
      color: $petrescue-green
      text-transform: uppercase

    &-actions
      display: flex
      justify-content: space-between
      flex-wrap: wrap

      .edit, .c-bump-button, .clone, .notes
        width: 49%
        span
          width: 100%
      a
        width: 100%
      a.button-active
        border-color: $petrescue-green
        color: $petrescue-green
        background-color: #DFECD1

      a.button-active.on_hold
        border-color: $petrescue-orange
        color: $petrescue-orange
        background-color: #fce7bf

      a.button-active.rehome
        border-color: #005DA5
        color: #005DA5
        background-color: #EAF3FB

      button
        cursor: pointer

      .space
        &--top
          margin-top: calcRem(16px)

      .hint
        font-size: calcRem(16px)
        color: $text-light-grey

    &__history, &__remove
      font-weight: bold
      position: absolute
      bottom: 20px
      @media (max-width: $tablet-width)
        position: relative
        padding-top: 10px
        bottom: 0

    &__remove, &__remove a
      color: #d0021b
      right: 20px
      @media (max-width: $tablet-width)
        right: 0

  .admin_notice
    text-align: center
    background-color: $petrescue-green
    padding: 10px
    margin-bottom: 0
    color: #fff
    font-weight: bold
  .notice
    margin: 0
    text-align: center
    padding: 10px
    font-style: italic
    font-size: calcRem(14px)

  &__petrescue-admin
    background-color: #EAF3FB
    border: 1px solid #b2d6f3
    color: #005DA5
    padding: 15px
    display: flex
    justify-content: space-between
    align-items: center
    @media (max-width: $mobile-width)
      flex-direction: column

    &.block
      margin-bottom: 1em
      width: 100%
      display: block

    .buttons
      @media (max-width: $mobile-width)
        width: 100%
        padding: 15px

      a
        margin: 0 2px
        text-decoration: none
        border: 1.8px dashed #005DA5
        color: #005DA5
        font-weight: bold
        padding: 5px 8px
        border-radius: 5px
        &:hover
          background-color: darken(#EAF3FB, 10%)
        @media (max-width: $mobile-width)
          width: 100%
          display: block
          text-align: center
          margin-bottom: 15px
          border-radius: 50px

.pet-listing__photos
  position: relative
  display: flex
  width: 100%
  max-width: 1140px
  margin: 0 auto
  flex-wrap: wrap
  padding: 30px 30px 0

  ::-webkit-scrollbar
    width: 7px
  ::-webkit-scrollbar-thumb
    border-radius: 4px
    background-color: rgba(0, 0, 0, .5)

  @media (max-width: $mobile-width)
    padding: 0
.pet-listing__photos__main
  width: 60%
  position: relative
  .listing-slider
    ul li
      overflow: hidden
      position: relative
    .image_count_counter
      position: absolute
      right: 0
      bottom: 0
      color: #fff
      padding: 10px
      background-color: rgba(0,0,0,0.5)
    .not-active
      border-radius: unset
  .listings__photos__img
    display: block
    width: 100%
  .listings__photos__bonded-besties
    position: absolute
    left: -42px
    top: -70px
    width: 160px
    @media (max-width: $mobile-width)
      width: 150px
      left: 0
      top: -50px
  @media (max-width: $photo-breakpoint)
    width: 100%

.pet-listing__photos__right
  width: 38%
  margin-left: 2%
  @media (max-width: $photo-breakpoint)
    width: 100%
    margin: 0 10px

.pet-listing__photos__thumbnails
  max-height: 520px
  margin: 0
  display: flex
  flex-wrap: wrap
  align-items: center
  overflow: scroll

  @media (max-width: $photo-breakpoint)
    height: auto
    margin-top: 15px

.pet-listing__photos__thumnail
  width: 30%
  margin: 0 4px 8px 4px
  position: relative
  transition: .1s ease-out
  &:hover
    transform: scale(1.05)
  img
    width: 100%
  a
    &:focus-visable
      outline: 2px solid #5E9ED6
  &.active
    padding: 11px 5px
    background-color: $very-light-grey

.pet-listing__subtitle
  color: $petrescue-green
  font-size: 1.5em
  margin: 1em 0
  @media (max-width: $mobile-width)
    margin: 1em 0
    font-size: 1.3em

.pet-listing__video__thumbnails
  display: flex
  flex-wrap: wrap
.c-listing-video-thumbnail
  width: 25%
  @media (max-width: $mobile-width)
    width: 50%
    &:nth-child(even)
      padding: 0 0 10px 10px
    &:nth-child(odd)
      padding: 0 10px 10px 0

.remove_from_favourites
  background-position: right

.pet-listing .add_remove_favourites .c-favourite-btn
  z-index: $favourite-button-index
  position: absolute
  right: 10px
  top: 10px

.pet-listing__content
  .flex-wrapper
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  .flex-wrapper__left
    overflow-wrap: break-word
    width: 60%
    padding: 0
  .flex-wrapper__right
    width: 38%
    max-width: 430px
    padding: 0 0 0 10px
  @media (max-width: $tablet-width)
    .flex-wrapper__right, .flex-wrapper__left
      width: 100%
      max-width: 100%
      padding: 0
      margin: 0
  .last_updated_at
    margin: 0
    font-size: 12px

.pet-listing__content__wrapper
  display: flex
  justify-content: space-between

.pet-listing__content__left
  width: 60%
  margin-left: 20px
  @media (max-width: $tablet-width)
    width: 100%
.pet-listing__content__right
  text-align: right

.pet-listing__content__name
  color: $petrescue-green
  margin: 0 0 10px
  text-align: left

.pet-listing__content__breed,.pet-listing__content__feature
  margin: 0 0 10px
  font-weight: normal
  @media (max-width: $mobile-width)
    font-size: 16px !important
.pet-listing__content__feature
  font-weight: bold
  margin-bottom: 0

.pet-listing__adoption_poster

  &_button, .button
    display: inline-block
    text-transform: uppercase
    font-size: calcRem(12px)
    padding: 12px 18px
    font-weight: 600
    margin-bottom: 0
    vertical-align: middle
    line-height: 1
    margin-bottom: 10px

  &_button
    background-color: $pr-grey-dark
    border-radius: 8px
    color: white
    text-decoration: none
    text-align: center
    margin-left: 8px
    @media (max-width: $mobile-width)
      margin-left: 0
    &:hover
      background-color: darken($pr-grey-dark, 10%)
    .fa-print
      font-size: calcRem(16px)
      margin-right: 4px
      vertical-align: text-bottom

.pet-listing__content__bio
  margin-left: 20px
  @media (max-width: $mobile-width)
    margin-left: 0

.pet-listing__status
  font-weight: bold
  font-size: 19px

  .status-banner__reason
    margin-left: 5px
    border-left: 1.3px solid $dark-grey
    padding-left: 5px


.pet-listing__content
  .foster_needed
    text-align: center
    font-size: calcRem(14px)
    margin: 15px 0
    font-weight: bold
    text-transform: uppercase
    color: $text-dark-grey
    border-radius: 20px
    background-color: #E7F1E2
    padding: 20px 10px
    &--img
      display: block
      margin: 0 auto 5px
    @media (max-width: $mobile-width)
      font-size: .8em
      line-height: 22px
      text-align: center

.listing-details
  background-color: #fff
  border-radius: 20px
  padding: 30px
  box-shadow: 0px 2px 2px 0px #ddd

  &__title
    color: $petrescue-green
    text-transform: uppercase
    font-size: calcRem(12px)
    font-weight: bold
    margin: 0
    line-height: 1.6
    &::selection
      color: $very-dark-grey

  &__detail
    margin: 0 0 20px
    color: $text-grey
    overflow-wrap: break-word
    &::selection
      color: $very-dark-grey
    a
      color: $text-grey
      &::selection
        color: $very-dark-grey
    &:last-of-type
      margin: 0

  .admin-view, .group-member-view
    border-radius: 10px
    padding: 15px
    margin-top: 20px

.listing-details__table
  margin: 34px 0
  background-color: #F7F7F7
  border-radius: 20px
  padding: 10px
  box-shadow: 0px 2px 2px 0px #ddd

  &-heading
    text-indent: 26px
    font-size: calcRem(15px)
    margin-bottom: 3px
    font-weight: bold
    @media (max-width: $mobile-width)
      text-indent: 16px

  .table
    border-collapse: collapse
    width: 100%
    border-radius: 10px
    overflow: hidden

  tr:nth-child(even)
    background-color: #fff
    td
      padding: 6px

  td
    min-width: 37px
    padding: 8px
    &:first-child
      text-align: center

  tr.false
    td:first-child
      color: $petrescue-red
    td
      color: $text-grey

  .fa-ban
    color: $text-black

  .fa-thumbs-up
    color: #F6C951
  .fa-thumbs-up, .fa-ban
    font-size: calcRem(18px)

  &__detail
    font-size: calcRem(14px)

  .boolean-image-true
    padding-left: 25px
    margin-left: 10px
    background: transparent url('~images/icons/boolean-yes.svg') no-repeat 0px 50%
    &.boolean-image-no
      background-image: url('~images/icons/boolean-no.svg')
.pet-listing__share-section
  .share_buttons__button
    @media (min-width: $tablet-width)
      border-radius: 0

      &.share_facebook
        background-color: $facebook-blue
        &:hover
          background-color: darken($facebook-blue, 10%)
      &.share_twitter
        background-color: $twitter-blue
        &:hover
          background-color: darken($twitter-blue, 10%)
      &.share_linkedin
        background-color: $linkedin-blue
        &:hover
          background-color: darken($linkedin-blue, 10%)
  .pet-listing__subtitle
    text-align: center
    margin: 15px 0 6px
  &__text
    margin: 0
    text-align: center !important
.report-section
  text-align: center
  @media (max-width: $tablet-width)
    margin: 22px 0 5px
  a.pet-listing__report-link
    color: $petrescue-red
    font-size: 12px
    margin-bottom: 20px
    display: block
    &:hover
      color: darken($petrescue-red, 10%)

#listing_multiple_animals_input, #listing_foster_needed_input, #listing_senior_input, #listing_shy_cats_campaign_input
  label
    font-weight: bold

.group_policy_level
  font-weight: bold
  margin-bottom: 13px
  font-style: italic
  .flexible
    color: $petrescue-green
  .moderate
    color: #0ab8e0
  .strict
    color: #c12ce5
  .level
    display: inline-block
    a
      &:hover
        color: darken($very-dark-grey, 10%)
.group_policy_level_link
    color: $petrescue-green
    font-style: italic
    padding-left: 28px
    display: block
    position: relative
    &:before
      content: ""
      background: url("~images/global/question-bubble.svg")
      width: 23px
      height: 20px
      display: block
      position: absolute
      left: 0

.admin-view
  background-color: $admin-background-color
  color: $admin-text-color
  padding: 10px
  p
    color: $admin-text-color

  td
    border-color: darken($admin-background-color, 10%)

.group-member-view
  background-color: $group-member-background-color
  color: $group-member-text-color
  padding: 10px
  p
    color: $group-member-text-color

  td
    border-color: darken($group-member-background-color, 10%)
