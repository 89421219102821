@import "sass/lib/variables"

.c-flash
  position: fixed
  width: 100%
  top: 0
  left: 0
  z-index: $notices-index

  &__container
    --text-color: #{$text-black}
    --anchor-hover-color: #{$petrescue-green}

    width: 100%
    padding: 20px

    text-align: center
    font-weight: bold
    position: relative
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.1)

    &--notice
      --anchor-hover-color: #{$petrescue-green}
      background-color: rgba(247, 247, 247, 0.95)

    &--alert
      --anchor-hover-color: #{$petrescue-red}
      background-color: #FFB9B9

    &--warning
      --anchor-hover-color: white
      background-color: $petrescue-orange

  &__message
    margin: 0
    color: var(--text-color)
    @media (max-width: $mobile-width)
      font-size: 15px

    &:before
      content: '\f12a'
      font-family: FontAwesome
      color: $petrescue-red
      margin-right: 8px
      background-color: #fff
      border-radius: 100%
      width: 25px
      height: 25px
      display: inline-block
      @media (max-width: $mobile-width)
        display: block
        margin: 0 auto

    // These styles are required due to conflicting global default styles for anchors
    & > a
      font-weight: inherit
      color: inherit
      &:hover
        color: var(--anchor-hover-color)
        background-color: transparent

  &__countdown, &__close
    position: absolute
    right: 13px
    top: 25px
    font-weight: normal
    font-style: italic
    font-size: 12px
    color: #B10000
    cursor: pointer
    @media (max-width: $mobile-width)
      margin-top: 10px
      position: relative
      top: 0
      right: 0

    .fa-times
      margin-left: 5px

  &__close
    font-weight: bold
    cursor: pointer
