.admin-tools-banned_donors
  .records-container
    width: 70%

  .formtastic
    li
      list-style: none
    #error_explanation
      li
        color: #900
