.rehome-via-petrescue
  margin-top: 120px
  @media (max-width: $mobile-width)
    margin-top: 50px

  &__image-1
    margin-right: 75px
    @media (max-width: $tablet-width)
      margin-right: 50px
    @media (max-width: 600px)
      display: none

  &__tick-list li
    margin: 20px 0
    padding-left: 30px
    &:before
      content: ''
      height: 25px
      width: 25px
      background-image: url('~images/rehome-via-petrescue/tick.svg')
      background-size: contain
      background-repeat: no-repeat
      background-position: center
      display: inline-block
      vertical-align: bottom
      position: absolute
      left: -5px
      top: -2px

  &__peace-of-mind
    &__point-icon
      width: 118px
      height: 118px
      background-color: #5C5C5C
      border-radius: 100%
      display: flex
      justify-content: center
      align-items: center
      margin: 0 auto 40px
      position: relative
      @media (max-width: $mobile-width)
        margin-right: auto !important
      &:after
        width: 40px
        height: 40px
        content: ""
        background-image: url('images/rehome-via-petrescue/tick.svg')
        position: absolute
        top: 0
        right: 0

    &__point-title
      margin-bottom: 19px
      @media (max-width: $mobile-width)
        text-align: center

    &__point-title.small
      font-size: calcRem(16px)

      span
        font-size: calcRem(14px)
        display: block
        margin-top: 15px

    &__point-body
      margin-bottom: 40px
      @media (min-width: $tablet-width)
        margin-bottom: 0
        max-width: 240px

  &__image
    @media (max-width: $mobile-width)
      display: none
  &__image-3
    margin-left: 120px
    @media (max-width: $tablet-width)
      margin-left: 0
    @media (max-width: $mobile-width)
      display: none
  &__blob-3
    position: absolute
    top: 70px
    right: -80px
    @media (max-width: $tablet-width)
      display: none
