@import "sass/lib/variables"
@import "sass/lib/typography"

.js-c-recently-adopted
  height: 500px
  margin:  50px auto
  max-width: 400px
  &_container
    width: 100%
    height: 0
    padding-top: 100%
    position: relative
    margin: 0 auto 50px
  #js-next-recently-adopted
    display: none
  &_img
    height: 100%
    img
      border-radius: 100%
      overflow: hidden
      object-fit: cover
  .slides
    position: absolute
    top: 0
    width: 100%
    height: 100%
    img
      width: 100%
      height: 100%
    &-details
      position: absolute
      bottom: 0px
      right: 0
      @media (min-width: $mobile-width)
        bottom: 10px
        right: -30px
      .name, .group, .adopted_at
        background-color: $petrescue-green
        padding: 5px 10px
        margin: 10px 0
        color: #fff
        width: fit-content
      .name
        font-size: calcRem(30px)
        @media (min-width: $mobile-width)
          font-size: calcRem(36px)
      .adopted_at
        font-size: calcRem(18px)
        line-height: inherit

  .pet_loader
    text-align: center
    height: 400px
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    position: absolute
    top: 0
    .image
      width: 100px
      height: 98px
      margin: 0 auto
      background-image: url('~images/icons/pet_loader_sprite.png')
      background-size: 950px
      animation: play 9s steps(8) infinite

  @keyframes play
    from
      background-position: left
    to
      background-position: right
