.admin-logs-index

  .wrapper.records-container
    // Set the wrapper to the full width of the page, so that
    // there is less chance you have to scroll horizontally
    max-width: unset

  .filters-sidebar
    min-width: 90px
    width: 45%
    max-width: 230px
    flex-shrink: 0
    @media (max-width: $mobile-width)
      width: 100%

  .listing-container
    overflow: hidden

  .table-wrapper
    overflow: auto
    max-width: 100%
    background: linear-gradient(to right, white 30%, rgba(255,255,255,0)), linear-gradient(to right, rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, rgba(0,0,0,.3), rgba(0,0,0,0)), radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.3), rgba(0,0,0,0)) 0 100%
    background-repeat: no-repeat
    background-color: white
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%
    background-position: 0 0, 100%, 0 0, 100%
    background-attachment: local, local, scroll, scroll

  .table-wrapper table
    width: auto

  .table-wrapper td
    $line-height: 1rem
    $max-lines: 5
    vertical-align: top
    font-size: 0.85rem

  .table-wrapper td code
    // stop the table from growing too long with large messages
    display: block
    max-width: 500px
