.header--external
  background-color: $body-background
  @media (max-width: $mobile-width)
    position: sticky
    top: 0
    z-index: $main-nav-index
  .logo
    position: relative
  #logo
    margin: 0
  #logo a
    background: transparent $pr-logo no-repeat 0 50%
    text-indent: -9999px
    height: 40px
    width: 202px
  .header--external__navigation__confetti
    width: 10px
    height: 10px
    display: block
    position: absolute
    background-size: contain
    background-repeat: no-repeat
    @media(max-width: $tablet-width)
      display: none
  .confetti-1
    background-image: url('~images/global/confetti-heart.svg')
    left: -140px
    top: 20px
  .confetti-2
    background-image: url('~images/global/single-confetti.svg')
    left: -115px
    top: 10px
    transform: rotate(240deg)
  .confetti-3
    background-image: url('~images/global/confetti-heart.svg')
    left: -60px
    top: -5px
    transform: rotate(40deg)
  .confetti-4
    background-image: url('~images/global/single-confetti.svg')
    left: -76px
    top: 17px
  .confetti-5
    background-image: url('~images/global/single-confetti.svg')
    left: -40px
    top: 27px

  .confetti-6
    background-image: url('~images/global/single-confetti.svg')
    right: -45px
    top: 10px
    transform: rotate(200deg)
  .confetti-7
    background-image: url('~images/global/confetti-heart.svg')
    right: -65px
    top: 0px
    transform: rotate(20deg)
  .confetti-8
    background-image: url('~images/global/single-confetti.svg')
    right: -80px
    top: 24px
  .confetti-9
    background-image: url('~images/global/single-confetti.svg')
    right: -115px
    top: 17px
    transform: rotate(40deg)
  .confetti-10
    background-image: url('~images/global/confetti-heart.svg')
    right: -142px
    top: 10px
  .wrapper
    margin: 0 auto
    padding: 0

.header--external__navigation
  margin: 0
  padding: 10px 30px
  display: flex
  justify-content: space-between
  height: 71px
  @media (max-width: $mobile-width)
    padding: 10px

  li.nav
    align-self: center
    margin: -10px 0 -10px 0px
    a
      display: block
      padding: 15px 15px
      text-decoration: none
      font-size: .85em
      font-weight: 600
      text-transform: uppercase
      &:focus
        text-decoration: underline
    &.donate, &.mobile_donate
      margin: 10px 0
      max-width: 96px
      a
        padding: 8px 20px
        margin-bottom: 0
    &.donate-right
      margin-left: 15px
    &.mobile_donate
      margin-right: 10px
      @media (min-width: $tablet-width)
        display: none
    .nav-icon
      width: 50px
      height: 70px
      position: relative
      &:hover
        transform: rotate(20deg)
      @media (max-width: $mobile-width)
        width: 40px
    &.conversations
      position: relative
      a
        background: url('~images/global/chat.svg') no-repeat center
    &.profile a
      background: url('~images/global/profile.svg') no-repeat center

  .header--external__navigation__left
    width: 30%

  .header--external__navigation__right
    display: flex
    justify-content: flex-end
    width: 30%
    align-items: center
    @media (max-width: $tablet-width)
      width: auto

  li.notifications
    position: relative
    @media (max-width: $mobile-width)
      font-size: calcRem(15px)
      position: initial

    i
      background: url('~images/global/bell.svg') no-repeat center
      cursor: pointer
      display: block

  .unread-count
    top: 20px
    right: 13px
    width: 12px
    height: 12px
    background-color: $petrescue-red
    position: absolute
    border-radius: 50%

  li.left
    margin-right: auto
    display: flex
    align-items: center
  li.nav.favourites a
    background: url('~images/global/favourite.svg') no-repeat center
    &:hover
      background: url('~images/global/favourite-full.svg') no-repeat center
  li .login
    &:hover
      text-decoration: underline
  li.signup
    a
      padding: 12px
      margin: 17px 0
      line-height: 1em
      border-radius: 12px

  .header__menu-link
    line-height: 40px
    @media (max-width: $mobile-width)
      padding: 15px 0 15px 10px
      margin: 0

  @media (max-width: 1000px)
    li.nav.signup
      display: none
  @media (max-width: $mobile-width)
    #logo a
      width: 155px
      background-size: auto 30px
  @media (max-width: $mobile-small-width)
    #logo a
      width: 130px
      background-size: auto 24px

.header__hamburger
  width: 20px
  height: 16px
  display: inline-block
  .hamburger
    display: inline-block
    height: 2px
    width: 18px
    background-color: $nav-text-color
    position: relative
    top: -4px
    transition: all linear 100ms
    &:before, &:after
      content: ""
      background-color: $nav-text-color
      width: 18px
      height: 2px
      position: absolute
      top: 6px
    &:after
      top: -6px
  &.open .hamburger
    transform: rotate3d(0, 0, 1, 45deg)
    &:before, &:after
      top: 0
      left: 0
      transform: rotate3d(0, 0, 1, 90deg)

.header-subnav-container
  background-color: #F5F5F5
  border-top: 1px solid #DDD
  position: relative
  &.open
    display: block

  .wrapper
    padding: 0
    margin: 0 auto

.header-subnav
  display: flex
  justify-content: space-between
  padding: 0 15px 0 30px
  font-weight: 700
  font-size: calcRem(14px)
  @media (max-width: 930px)
    justify-content: flex-start
  @media (max-width: $mobile-width)
    font-size: calcRem(14px)
    padding: 0 10px

  // We want the sections of the header nav to be equal width untill it gets too small to do so
  .header-subnav__section
    @media (min-width: 1190px)
      width: 25%
      &.header-subnav__search-links
        width: 50%
  
  .header-subnav__link
    text-decoration: none
    color: $nav-text-color
    padding: 12px 14px 8px 14px
    display: inline-block
    border-bottom: 4px solid #F5F5F5
    cursor: pointer
    &.show_tablet
      display: none
    @media (max-width: $mobile-width)
      padding: 12px 3px
    @media (max-width: $tablet-width)
      &.hide_tablet
        display: none
      &.show_tablet
        display: inline-block
    &:hover
      background-color: #fff
      border-color: #fff
    &.current
      border-bottom: 4px solid $petrescue-green
    &.active
      background-color: #fff
      border-color: #fff

  &__menu
    flex-shrink: 0
    order: 1
    text-transform: uppercase
    &-link
      text-decoration: none
      color: $nav-text-color
      display: inline-block
      padding: 12px 14px 8px 0

    .header-subnav__menu-text
      @media (max-width: 550px)
        display: none

  &__search-links
    order: 2
    display: flex
    text-transform: uppercase
    flex-grow: 1
    justify-content: center
    @media (max-width: 820px)
      .hide_tablet
        display: none
      .show_tablet
        display: inline-block

  &__articles
    order: 3
    text-align: right
    color: $nav-text-color
    font-weight: 400
    @media (max-width: $mobile-width)
      margin: 0

    &__article:active
      font-weight: bold

.header--member
  background-color: $very-dark-grey
  @media (max-width: $mobile-width)
    top: 72px // equal to main header height
    z-index: $main-nav-index
  .wrapper
    max-width: 1270px
    margin: 0 auto
    position: relative
    @media (max-width: 1110px)
      padding: 0
  ::-webkit-scrollbar
    display: none
  i
    color: $white
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(51,51,51,1) 50%)
    padding: 5px 10px 5px 20px
    font-size: calcRem(24px)
    position: absolute
    right: 0
    height: 100%
    z-index: 2
    @media (min-width: $tablet-width)
      display: none
  .header--member__navigation
    list-style: none
    margin: 0
    display: flex
    justify-content: center
    flex-wrap: nowrap
    overflow-x: scroll
    li:not(.c-menu-item )
      white-space: nowrap
      a
        color: $text-white
        display: inline-block
        font-weight: bold
        text-transform: uppercase
        padding: 10px 20px 6px 20px
        text-decoration: none
        font-size: .9em
        border-bottom: 4px solid $very-dark-grey
        &:hover
          background-color: $petrescue-green
          border-bottom: 4px solid $petrescue-green
        &.current
          border-bottom: 4px solid $petrescue-green
    @media (max-width: $tablet-width)
      justify-content: flex-start
