.verifications
  .content-wrapper
    max-width: 960px
  .actions
    ol
      .action
        a
          min-width: auto

.verification-setup
  .content-wrapper
    .intl-tel-input
      width: 100%

.verification-checks, .verification-setup, .verification-recovery
  .actions
    max-width: 640px
    display: flex
    align-items: center
    justify-content: center

.verification-checks
  .content-wrapper
    .token
      color: $bright-blue
      font-weight: bold

    .verification-recovery
      width: 100%
      text-align: right

.verification-recovery-codes, .verification-recovery, .verification-resend
  .content-wrapper
    max-width: 960px

  &--background
    width: 100%
    max-width: 640px
    background-color: #f6f6f6
    padding: 30px
    margin: 0 auto 35px

  .recovery-code
    display: flex
    justify-content: space-around

    span
      padding: 0.5em 1em
      font-size: 2em
      font-weight: bold
      border: 4px dashed #000

.verification-recovery, .verification-setup, .verification-resend
  .content-wrapper
    .actions
      ol
        display: flex
        justify-content: space-around

      .action
        a
          margin-right: 0
