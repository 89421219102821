.search .modify-search
  width: 100%
  display: none
  @media (max-width: $mobile-width)
    display: block

.search_container
  display: flex
  flex-flow: column
  @media (max-width: $mobile-width)
    flex-flow: column-reverse

.pagination-filters
  background-color: white
  padding: 20px 0
  border-radius: 6px
  margin-bottom: 30px

  .pagination-filters__wrapper
    display: flex
    align-items: center
    overflow: visible

    .pagination-info
      font-size: 16px

    .clear-filters
      display: block
      margin-left: 33px
      color: $text-selction-dark
      font-weight: 600

    .per-page-container
      float: none
      margin: 0
      margin-left: auto

    .per-page-container .per-page
      display: flex
      justify-content: center
      align-items: center

    .per-page-container .per-page .text
      font-size: 14px
      font-weight: bold

    .per-page-container .per-page .pagination-select#per-page
      margin: 0px 10px
      padding: 0 10px
      height: 36px
      width: 63px
      font-size: 14px
      box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.42)

    @media (max-width: $tablet-width)
      display: block

      .clear-filters
        margin: 20px 0
        text-align: center

      .pagination-info
        text-align: center

      .per-page-container
        margin-top: 15px

.search__filters
  flex-wrap: wrap
  padding: 15px
  background-color: $searchbar-grey
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 15px
  @media (max-width: $mobile-width)
    margin: 0 0 20px 0
    justify-content: center
  form
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    width: 100%
    align-items: center
    @media (max-width: $mobile-width)
      justify-content: center
  input[type='text'], select
    margin-bottom: 0 !important
    @media (max-width: $mobile-width)
      margin-bottom: 10px !important

  .search__displaying
    @media (max-width: $tablet-width)
      margin-bottom: 10px
    .select2-container
      display: none
      @media (max-width: $mobile-width)
        display: block

  span
    font-size: 13px
  .button
    margin-bottom: 0
    @media (max-width: $mobile-width)
      order: 5

.search__filters.bottom
  margin-bottom: 0

.search__filter__text
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 20px
  input[type="text"]
    margin: 0 20px
    width: 300px !important
    margin-bottom: 0 !important

  .select2-hidden-accessible, .select2-container, .c-select-search
    margin: 5px 20px !important
    width: 100px !important

  .submit input
    width: 120px
    margin: 0
    border: 0

  .search_input
    position: relative

  .fa-close
    position: absolute
    right: 30px
    top: 10px
    cursor: pointer

  @media (max-width: $mobile-width)
    margin-bottom: 0
    flex-direction: column

    input, select
      width: 100% !important
      margin-bottom: 15px !important

    .select_container
      display: flex
      flex-direction: row
      align-items: baseline
      justify-content: space-between
      margin: 0 20px
      width: 100%
      select
        margin: 0 0 0 10px
    .submit
      width: 100% !important

.search-main-content
  display: flex
  flex-wrap: nowrap
  @media (max-width: $mobile-width)
    flex-direction: column

  .filters-sidebar
    width: 24%
    margin-right: 20px
    min-width: 220px
    @media (max-width: $mobile-width)
      min-width: 200px
      width: 100%
      display: none
  main
    width: 74%
    @media (max-width: $tablet-width)
      width: calc(100% - 220px)
    @media (max-width: $mobile-width)
      width: 100%

.filter-box
  margin-bottom: 5px

  & + &
    border-top: 2px solid var(--border-color, $very-light-grey)
    margin-top: 20px
    padding-top: 20px

  &:last-of-type:not(:first-of-type)
    border-bottom: 2px solid var(--border-color, $very-light-grey)
    padding-bottom: 20px

  &--blue
    --border-color: #{$admin-border-color}
    padding-left: 10px
    padding-right: 10px
    padding-bottom: 10px
    color: $admin-text-color
    background-color: $admin-background-color
    border-bottom: 2px solid $admin-border-color
    header, a, a.active
      color: $admin-text-color

  .filter-box-header

    font-size: calcRem(16px)
    color: $text-black
    font-weight: 800
    cursor: pointer
    position: relative
    background: transparent $plus-icon no-repeat 95% 50%
    background-size: 12px

  ul
    margin: 0
    // filter box uls are given this padding so that the spacer between the heading and the
    // input lists doesn't collapse when animating shut with the accordian
    padding-top: 19px

    li
      font-size: calcRem(16px)
      line-height: 1.5
      margin: 2px 0
      padding: 0
      cursor: pointer

      &:first-child
        margin-top: 0
      &:last-child
        margin-bottom: 0

      @media (max-width: $mobile-width)
        font-size: inherit

      &:not(.interstate)
        // This is a huge hack to exclude button elements from this selector but necessary for now while
        // due to this element/selector combo being a bit prolific in the codebase and thus hard to fix
        a:not([class*="button"])
          text-decoration: none
          display: block
          padding: 9px 23px
          border-radius: 2px
          &:hover
            background: $filter-active-bg

        // This is a seperate selector due to the issue where some search filters are given an .active class on
        // their list element. Otheres are given the .active class on their anchor tag. This would be a bit of
        // work to unwind so for now we have this crazy selector.
        &.active > a:not([class*="button"]), & > a.active:not([class*="button"])
          display: block
          font-weight: bold
          background: $filter-active-bg
          position: relative
          &:after
            content: ""
            color: $text-black
            font-family: FontAwesome
            font-size: 11px
            font-weight: 400
            position: absolute
            top: 50%
            transform: translateY(-50%)
            right: 8px

  .filter-box-options
    display: none

  &.expanded
    .filter-box-header
      background-image: $minus-icon
    .filter-box-options
      display: block

.filter-box-options
  &.sub-options li.active::after
    right: 0
  button
    font-size: 14px

.filter-box-link
  font-size: calcRem(12px)
  font-style: italic
  margin-top: 10px
  display: block

.search-results__listing-wrapper
  width: 100%

.search-results
  display: flex
  flex-wrap: wrap
  clear: both

  @supports (display: grid)
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))
    grid-gap: 15px
    grid-auto-flow: row dense
    clear: both

    .search-results__no-results
      grid-column: 1 / end
      grid-row: 1 / 1
      text-align: center

  .search-listing
    &-img img
      width: 100%
      display: table-row
      border-radius: 10px 10px 0 0
    .shadow
      display: none
      background-color: rgba(0,0,0,0.35)
      box-shadow: none
      position: absolute
      top: 2px
      left: 2px
      height: calc(100% - 4px)
      width: calc(100% - 4px)
      border-radius: 10px

//blog_post_category page
.search-results-with-filter-bar
  width: 73%
  @media (max-width: $mobile-width)
    width: 100%

.not-active
  background-color: rgba(0,0,0,0.35)
  position: absolute
  top: 0
  left: 0
  width: 100%
  padding-top: 100%
  border-radius: 10px 10px 0 0
  z-index: $listing-status-banner-bg-index
.status-banner
  background-color: $very-dark-grey
  width: 100%
  color: #fff
  padding: 8px
  text-align: center
  font-weight: bold
  position: absolute
  top: 17px
  left: -34%
  font-size: 12px
  text-transform: uppercase
  transform: rotate(-45deg) translate(-6.25%, 22.5%)
  z-index: $listing-status-banner-index
  .status-banner__reason
    display: block

  p
    line-height: calcRem(22px)
    margin: 0

  .normal
    text-transform: none
    font-size: calcRem(16px)
    font-weight: 500

.search__per-page
  @media (max-width: $tablet-width)
    padding: 10px
  @media (max-width: $mobile-width)
    display: none
  select
    background-position: 100% 0px !important
    text-indent: 5px
    border-color: #ddd
    border-radius: 2px
    width: 55px
    height: 25px
    font-size: 13px
