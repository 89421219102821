.admin-tools
  .tools-menu
    .filter-box 
      width: 100%
      margin: 0
      h3
        margin: 0
        border-bottom: 1px solid $dark-grey
        padding: 15px 20px
        background-color: #fff
      ul
        background-color: darken($almost-white, 3%)
        padding: 10px 20px
        margin: 0
        list-style: none
        li
          padding: 6px 0
          font-size: inherit
          margin: 0
          &:hover
            background: transparent
          a
            padding: 0

.admin-tools-users
  .search-main-content
    margin: 60px
    table
      width: 80%
      margin: auto
      border: 1px solid #ddd
      th, td
        padding: 10px !important
        border: 1px solid #ddd

.admin-tools-subscriptions
  .statistics
    display: flex
    justify-content: center

    .item
      padding: 15px
      background-color: #fff
      border: 1px solid #eee

      .title
        font-size: 1.2em
      .value
        text-align: center

.admin-tools-charges
  &-index
    &--table
      tbody
        tr
          cursor: pointer
  &-show
    &--table
      margin: 2em auto

      thead
        th
          padding: 18px 0 8px 0
          color: $petrescue-green
          font-size: 1.4em
      tbody
        th
          text-align: left
          padding: 0 7px
          &.center
            text-align: center
        td
          padding: 0 7px
          .cancel
            color: $petrescue-red
            &.disabled
              opacity: 0.5


.admin-tools-cat_origin_stats
  table
    tbody tr:last-child
      font-weight: bold

.admin-tools-group_donations
  .higlights
    th
      background-color: $petrescue-green
      color: #fff
      padding: 10px
      text-align: center !important
      border: 5px solid
      margin-right: 10px
  .standard_table
    width: 650px
    margin: 0 auto
    padding-top: 30px
    td, th
      border: 1px solid #eee
      padding: 5px

.admin-tools-chat_errors
  .controls
    +clearfix

    a
      color: $bright-blue
      text-decoration: none

    .right
      float: right

  .white-container
    padding: 0 !important
    width: 100%

  .tabs
    display: flex
    flex-flow: row wrap

    a
      height: 35px
      line-height: 35px
      text-decoration: none
      font-weight: bold
      flex: 1
      outline: 0

      &:hover, &.active
        background-color: $petrescue-green
        color: $almost-white


  &--table
    padding: 0
    table-layout: fixed

    .expandable-control
      height: 35px
      line-height: 35px
      border-top: 1px solid $almost-white

      &:first-child
        border-top: none

      .heading
        font-weight: bold
        color: $petrescue-green
        cursor: pointer

    .error-content
      padding: 0
      display: none
      background-color: $almost-white
      position: relative

      pre
        margin: 0 auto
        padding-top: 20px
        width: 95%
        text-align: left
        background-color: $almost-white

  .copyable-control
    top: 15px
    right: 15px
    position: absolute
    cursor: pointer

.admin-tools-connection_statement_cache
  #site-wrapper
    margin: 0 auto 25px auto
    width: 95%

    ul
      border: 1px solid #ccc

      li
        padding: 10px 15px
        border-bottom: 1px solid #ccc

        &:last-child
          border-bottom: none

//Photo search
.photo_search_results
  flex-wrap: wrap
  align-items: baseline

  .photo_container
    width: 33%
    margin-bottom: 30px

    h3
      margin: 5px 0
    p
      margin: 5px 0

.admin-tools-photo_search-excluded_groups
  form
    display: flex
    justify-content: space-between
    align-items: baseline
    @media (max-width: $mobile-width)
      flex-direction: column

  #photo_search ul
    li
      margin: 5px 0
      a
        margin-left: 15px
        i
          color: $petrescue-red
