@import "sass/lib/variables"

// For sticky sidebars
.conversations-show #site-wrapper,
.listings-conversations-show #site-wrapper
  @media (min-width: $laptop-large-width)
    overflow: initial

.c-chat-wrapper
  display: grid
  grid-template-columns: 1fr
  grid-gap: 25px
  margin-top: 25px
  align-items: start
  @media (min-width: $tablet-small-width)
    grid-template-columns: 40% auto
  @media (min-width: $tablet-large-width)
    grid-template-columns: 30% auto
  @media (min-width: $laptop-large-width)
    grid-gap: 50px
    grid-template-columns: 1.3fr 3fr 1fr
  h1
    a
      color: $petrescue-green
  .chat-listing-details
    @media (min-width: $tablet-small-width)
      grid-column: 1
      grid-row: 1
    @media (min-width: $laptop-large-width)
      grid-row: auto
      grid-column: auto
      position: sticky
      top: 10px
  .chat-enquiry-details
    @media (min-width: $tablet-small-width)
      order: 2
    @media (min-width: $laptop-large-width)
      order: unset
      position: sticky
      top: 10px
  .chat-main
    order: 2
    @media (min-width: $tablet-small-width)
      order: unset
      grid-row: span 2
    @media (min-width: $laptop-large-width)
      grid-row: auto
    .message-container
      @media (min-width: $tablet-small-width)
        margin-bottom: 0 !important

    &__h1
      font-size: calcRem(24px)
      line-height: 1.3
      @media (min-width: $tablet-small-width)
        font-size: calcRem(32px)
      @media (min-width: $laptop-large-width)
        font-size: calcRem(40px)

  .chat-breadcrumb,
  .join-conversation--cta
    font-weight: bold
    text-align: center
    color: $very-dark-grey
    > a
      display: inline-block
      padding: 10px 15px
      border-radius: 20px
      background: $very-light-grey
      color: $very-dark-grey
      text-decoration: none

  .chat-sidebar,
  .conversation-icon
    width: 40px
    margin-left: auto
    margin-right: auto
    display: block
  
  .enquiry-controls 
    display: flex
    flex-wrap: wrap
    justify-content: space-evenly
    grid-gap: 15px
