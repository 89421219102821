@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-campaign-banner
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  padding: 15px 0
  position: relative
  background-color: $very-dark-grey
  color: white

  @media (min-width: $tablet-small-width)
    padding: 20px 15px

  &_inner 
    display: flex
    gap: 15px
    flex-direction: row
    align-items: center

  &__button-wrap
    display: flex
    gap: 15px
    flex-direction: column-reverse

  &__button-wrap--no-icon
    @media (min-width: $mobile-width)
      flex-direction: row
      align-items: end

  & &__cta // extra & set to increase specificity
    text-transform: uppercase
    font-size: calcRem(13)
    min-width: 150px
    padding-left: 1rem
    padding-right: 1rem

  &__gift-icon
    display: none
    color: white
    min-width: 50px
    height: 48px
    transform: translateY(-1px) // fix visual weight
    flex-shrink: 0

    @media (min-width: $tablet-large-width)
      display: block

  &__text
    margin-top: 0
    margin-bottom: 0
