#auth-connected
	ul
		margin-bottom: 20px
	header,
	section
		box-sizing: border-box
	header
		border-bottom: 1px solid $form-input-border-color
		position: relative
		padding: 10px 20px
		.sign-out
			position: absolute
			top: 22px
			right: 20px
	section
		display: table-cell
		width: 50%
		padding: 20px
		&.current_user
			border-right: 1px solid $form-input-border-color
	.image
		float: right
	.hint
		display: block
		font-style: italic
		font-size: 13px
		margin-top: 10px
		opacity: 0.7
