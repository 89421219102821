@import "sass/lib/variables"

$size: 1.5em

.toggle_radio.input
  .choices .choices-group .choice
    flex-grow: 1
    margin: 0
    &:first-child .c-toggle-radio__control
      border-radius: 10px 0 0 10px
      border-left: 2px solid black
    &:last-child .c-toggle-radio__control
      border-radius: 0 10px 10px 0

.c-toggle-radio

  &--error
    .c-toggle-radio__control
      color: $petrescue-red

  &__input
    &__el
      opacity: 0
      position: absolute
      &:checked + .c-toggle-radio__control
        background-color: $very-dark-grey
        border-color: $very-dark-grey
        color: white
      &:focus + .c-toggle-radio__control
        box-shadow: 0 0 0 2px $focus-blue
      &:disabled + .c-toggle-radio__control
        color: $dark-grey
        background-color: #DEDEDE

  &__control
    text-align: center
    display: block
    font-size: 1em
    color: $pr-grey-dark
    background-color: white
    border: 2px solid black
    border-left: none
    padding: 8px
    cursor: pointer
