@import "sass/lib/variables"

.c-logo--header
  position: relative
  align-self: center
  &__logo
    text-indent: -9999px
  &__confetti
    display: none
    @media(min-width: $tablet-width)
      display: block
      width: 10px
      height: 10px
      position: absolute
      background-size: contain
      background-repeat: no-repeat
    &--1
      background-image: url('~images/global/confetti-heart.svg')
      left: -140px
      top: 20px
    &--2
      background-image: url('~images/global/single-confetti.svg')
      left: -115px
      top: 10px
      transform: rotate(240deg)
    &--3
      background-image: url('~images/global/confetti-heart.svg')
      left: -60px
      top: -5px
      transform: rotate(40deg)
    &--4
      background-image: url('~images/global/single-confetti.svg')
      left: -76px
      top: 17px
    &--5
      background-image: url('~images/global/single-confetti.svg')
      left: -40px
      top: 27px
    &--6
      background-image: url('~images/global/single-confetti.svg')
      right: -45px
      top: 10px
      transform: rotate(200deg)
    &--7
      background-image: url('~images/global/confetti-heart.svg')
      right: -65px
      top: 0px
      transform: rotate(20deg)
    &--8
      background-image: url('~images/global/single-confetti.svg')
      right: -80px
      top: 24px
    &--9
      background-image: url('~images/global/single-confetti.svg')
      right: -115px
      top: 17px
      transform: rotate(40deg)
    &--10
      background-image: url('~images/global/confetti-heart.svg')
      right: -142px
      top: 10px
