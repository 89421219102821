@import "sass/lib/variables"
@import "sass/lib/typography"

.c-our-team-card-container
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  max-width: 950px
  margin: 0 auto
  padding: 0 15px
  @media(max-width: $mobile-width)
    justify-content: center
