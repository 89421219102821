@import "sass/lib/variables"
  
[class*="c-flex-cols-"]
  display: flex
  flex-wrap: wrap
  width: auto
  .c-cell
    width: 100%
  @each $size, $value in $gap-sizes
    &.gap-#{$size}
      margin-left: -$value
      .c-cell
        padding-left: $value
        margin-bottom: $value
  
@for $cols from 1 through 10 
  .c-flex-cols-#{$cols}
    justify-content: center
    .c-cell
      @if $cols == 2 
        @media (min-width: $mobile-width)
          width: calc(100% / 2)
      @if $cols > 2
        @media (min-width: $tablet-width)
          width: calc(100% /#{$cols})
      @if $cols > 4
        @media (min-width: $mobile-width)
          width: calc(100% / 2)
        @media (min-width: $tablet-width)
          width: calc(100% /3)
        @media (min-width: $laptop-width)
          width: calc(100% /#{$cols})
      @if $cols >= 6
        width: calc(100% / 2)
        @media (min-width: $tablet-width)
          width: calc(100% / 3)
        @media (min-width: $laptop-width)
          width: calc(100% /#{$cols})

    &.gap-lg
      .c-cell
        @if $cols == 2
          width: 100%
          @media (min-width: $tablet-width)
            width: calc(100% / 2 )
