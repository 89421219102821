@import "sass/lib/variables"
@import "sass/lib/typography"

.c-mega-nav-list-item
  padding: 10px
  &:hover
    background-color: $almost-white
    .c-mega-nav-list-item
      &-icon
        background: $white
      &-text
        color: $petrescue-green
      &-sub-text
        color: $text-dark-grey
    .c-mega-nav__dropdown__list-text
      color: $petrescue-green

  &:active
    .c-mega-nav-list-item-icon 
      transform: rotate(12deg)
      
  & > a
    text-decoration: none
    position: relative
    display: flex
    align-items: center

  &-icon
    background-color: $almost-white
    border-radius: 100%
    width: 50px
    height: 50px
    margin-right: 10px
    transition: transform ease-out 0.2s
    img
      width: 50px
      height: 50px
      padding: 10px
      object-fit: contain

  &-text
    margin: 0
    font-size: calcRem(15px)
    transition: color ease-out 0.2s

  &-sub-text
    font-style: italic
    color: $text-grey
    transition: color ease-out 0.2s
    font-size: calcRem(12px)
     