.signup-page
  .highlight
    position: relative
    &:after
      content: ''
      position: absolute
      background: url('~images/signup/line.svg') no-repeat
      bottom: -12px
      left: 0
      height: 20px
      width: 100%
      background-size: contain

.signup-account-types
  max-width: 965px
  display: flex
  flex-wrap: wrap
  margin: 0 auto

  &__type
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1)
    max-width: 450px
    vertical-align: bottom
    margin: 2%
    display: inline-block
    width: 45%
    background-color: #fff
    transition: .1s ease-out
    will-change: transform,box-shadow
    border-radius: 10px
    @media( max-width: 650px)
      width: 95%
      max-width: 480px
    &:hover
      transform: translateY(-4px)
      box-shadow: 0 0 8px rgba(0,0,0,0.15)
    &:active
      transform: translateY(0px)

    img
      width: 100%
      border-radius: 10px 10px 0 0
      height: 220px
      object-fit: cover

    &-container
      padding: 0 25px 25px
    &-text
      height: 380px
      @media( max-width: $tablet-width)
        height: 550px
      @media( max-width: 650px)
        height: auto

    &-heading
      color: $petrescue-green
      font-size: calcRem(28px)
      line-height: 30px
      font-family: $font-serif

    &-description
      font-size: calcRem(18px)
      line-height: 28px
      letter-spacing: -.014em

    .button
      margin: 0 auto
      display: block
      width: fit-content
