.pethack-2020
  background: white

  h2
    font-size: 30px
    color: #131313
    line-height: 40px
  .credit
    font-size: 14px
    font-style: italic
    line-height: 21px
  .wrapper
    max-width: 845px
    margin-top: 0
    margin-bottom: 0
    box-sizing: border-box
    &--mxl.wrapper
      max-width: 845px
    &--xl.wrapper
      max-width: 885px

  .line-down
    display: block
    margin: 35px auto
    &--extra-m
      margin: 60px auto

  &__button
    font-size: 16px
    padding: 14px 54px
    display: block
    margin: auto
    width: fit-content
    box-shadow: 2px 2px 6px rgba(57, 101, 0, 0.205201)

  section
    padding: 67px 0
    &.section--dark
      background: $almost-white

  &__section-one
    h1, .hero-img
      max-width: 760px
      margin: 31px auto
      display: block
    .hero-img
      max-height: 272px
      height: 100%
      width: 100%
    p
      max-width: 497px
      margin: 1.3em auto
      box-sizing: border-box

  &__countdown-clock
    margin-top: 57px

    & > p
      text-align: center
      max-width: unset
      margin: 0 0 42px 0

    &__timer
      font-weight: bold
      text-align: center
      font-family: $font-serif
      .counter
        color: $petrescue-green
        font-size: 40px
      .label
        color: black
        font-size: 26px
    a.button
      font-size: 16px
      padding: 14px 54px
      display: block
      margin: auto
      width: fit-content
      box-shadow: 2px 2px 6px rgba(57, 101, 0, 0.205201)

  .pethack-2020 &__section-two // prefixed with .pethack-2020 to override specificity
    padding-bottom: 114px

  &__section-three
    max-width: 1188px
    margin: auto

    &__tyson
      margin-top: -135px // this is the section padding plus the negative margin
      p
        text-align: center
      img
        display: block
        width: 90%
        max-width: 332px
        margin-right: auto
        margin-left: auto

    &__google-office
      display: flex
      justify-content: center
      padding: 0 20px

      @media (max-width: $tablet-large-width)
        display: block

    &__google-office__img
      max-width: 625px
      text-align: center
      margin-right: 52px
      img
        width: 100%
      .credit
        margin-top: 13px
      @media (max-width: $tablet-large-width)
        margin: 0 auto

    &__google-office__text
      max-width: 497px
      margin: 0 auto

    &__para
      clear: both
      max-width: 500px
      margin: auto

    &__quote
      display: flex
      justify-content: space-between
      align-items: center
      flex-direction: row-reverse
      color: $text-dark-grey
      max-width: 832px
      margin: 60px auto 0 auto

      @media (max-width: $tablet-small-width)
        display: block
        text-align: center

      .quote
        max-width: 480px
        font-size: 28px
        line-height: 36px
        font-family: font-serif-light
        margin-right: 20px
        @media (max-width: $tablet-small-width)
          margin: 0 auto

    &__quote__img img
      width: 211px
      display: block
      @media (max-width: $tablet-small-width)
        margin: 20px auto

  &__section-five

    h2
      text-align: center
      max-width: 431px
      margin: 49px auto
      &.small-margin
        margin-bottom: 13pm

    &__info
      margin: 49px auto 59px auto
      & > p:first-child, & > p:last-child
        margin-top: 0
        margin-bottom: 0

    &__events
      display: flex
      justify-content: center
      padding: 0 20px
      @media (max-width: $tablet-small-width)
        flex-wrap: wrap

    &__event
      max-width: 467px
      width: 100%
      flex-grow: 1
      padding: 39px 33px
      background: white
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16)
      border-radius: 20px
      line-height: 29px

      @media (max-width: $tablet-small-width)
        margin-bottom: 20px

      &:first-child
        margin-right: 33px
        @media (max-width: $tablet-small-width)
          margin-right: 0

      h4
        color: $petrescue-green
        font-family: $font-sans
        font-size: 18px
        line-height: 29px
        margin: 0

      p
        margin: 29px 0 0 0
        &:first-of-type
          margin-top: 0

    &__registration
      h2
        margin-bottom: 13px
      &__actions
        display: flex
        justify-content: center
        margin: 45px auto
        flex-wrap: wrap
        .button
          margin: 8px

    .calendar
      margin-top: 60px
      ul, li
        list-style: none
        margin: 0
        padding: 0
        font-size: 18px
        line-height: 29px
      .calendar__month h3
        font-size: 18px
        line-height: 29px
        padding: 15px 13px
        margin: 15px 0
        background-color: $almost-white
        font-weight: bold
      .calendar__day
        &, &--past
          display: flex
          padding: 15px 13px
        &--past
          color: $dark-grey
      .calendar__day__date
        width: 100%
        max-width: 140px
        @media(min-width: $tablet-small-width)
          max-width: 220px
      .calendar__day__description
        flex-grow: 1

  &__section-six
    // this is a bit of hack but we keeping
    padding: 0 20px !important

    // selector is like this for specificity
    & &__judges
      max-width: none

      & > p, & > h2
        max-width: 750px
        margin-left: auto
        margin-right: auto

      .judge_list
        max-width: 830px
        display: flex
        flex-wrap: wrap
        justify-content: center
        align-items: center
        list-style: none
        padding: 0
        margin: 28px auto 18px auto
        li
          margin:  7px 6px

      .prizes
        display: flex
        justify-content: center
        margin: 0 auto
        @media(max-width: $tablet-small-width)
          display: block

    &__contact.wrapper
      margin-bottom: 90px

    &__sponsers
      h2
        max-width: 543px
        font-family: font-seirf
        text-align: center
        margin: 0 auto

      &__list
        display: flex
        justify-content: center
        flex-wrap: wrap
        align-items: center
        max-width: 1200px
        margin: 50px auto 0 auto
        img
          margin: 0 52px 90px 52px
          max-width: 80vw


