.preview_block
  max-width: 1200px
  background: white
  border-radius: 3px
  margin: 0 auto
  padding: 20px

  & + &
    margin-top: 20px

  &__title
    margin: 0 0 20px 0

.preview_block-inner
  width: 100%
  height: 50px
  background: #ccc
  margin-bottom: 10px
  border-radius: 5px

// .preview_block__content
