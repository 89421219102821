@import "sass/lib/variables"
  
.c-grid
  display: grid
  --grid-template: none
  grid-template: var(--grid-template)
 
[class*="c-grid-cols-"]
  display: grid
  @each $size, $value in $gap-sizes
    &.gap-#{$size}
      grid-gap: $value
  
@for $cols from 1 through 10 
  .c-grid-cols-#{$cols}
    grid-template-columns: 1fr
    @if $cols >=2 
      @media (min-width: $tablet-small-width)
        grid-template-columns: repeat(2, 1fr)
      @media (min-width: $tablet-width)
        grid-template-columns: repeat(#{$cols}, 1fr)
    
    @if $cols >=4
      grid-template-columns: repeat(2, 1fr)
      @media (min-width: $tablet-width)
        grid-template-columns: repeat(3, 1fr)
      @media (min-width: $laptop-width)
        grid-template-columns: repeat(#{$cols}, 1fr)
    @if $cols >= 6
      grid-template-columns: repeat(6, 1fr)
      @media (min-width: $tablet-width)
        grid-template-columns: repeat($cols, 1fr)
