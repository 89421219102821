@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-admin-table-th
  font-family: $font-sans
  font-weight: 700
  font-size: calcRem(16)
  text-align: left
  // this should match app/components/table/td/component.sass
  padding: calcRem(5) calcRem(10)
  line-height: 1
  width: var(--width-percentage, auto)
