.rehomed-listings__header
  background-color: #fff
  text-align: center
  padding: 40px

  h3, p
    max-width: 950px
    margin: 10px auto
  h3
    margin-top: 30px

.rehomed-listings__pet-blocks
  max-width: 1110px
  margin: 0 auto
  @media (max-width: $tablet-width)
    flex-direction: column

  .rehomed-listings__pet-block
    position: relative
    overflow: hidden
    border-radius: 20px
    margin: 5px

    .rehomed-listings__pet-block--text
      padding: 50px 7px 12px 7px
      color: white
      text-align: center
      position: absolute
      bottom: 0
      width: 100%
      background-image: url('~images/just-adopted/green-wave.svg')
      background-repeat: no-repeat
      background-position: center bottom

    .rehomed-listings__pet-block--text.middle
      background-image: url('~images/just-adopted/green-wave-2.svg')
