@import "sass/lib/variables"
@import "sass/lib/typography"

.c-our-team-card
  border-radius: 25px
  margin-bottom: 60px
  width: 47%
  @media(max-width: $mobile-width)
    width: 80%
  &-image
    border-radius: 25px 25px 0 0
    overflow: hidden
    display: block
    width: 100%
  &-text
    text-align: center
    padding: 15px 15px 30px 15px
    border-radius: 0 0 25px 25px
    background-color: $almost-white
    position: relative
    &-name
      font-size: calcRem(40px)
      margin: 0
      color: $text-black
    &-title
      font-size: calcRem(18px)
      color: #3E3E3E
    &-dropdown
      text-align: left
      padding: 0 calcRem(6)
      display: none
    &-arrow
      border-radius: 50%
      background-color: #fff
      position: absolute
      bottom: -16px
      height: 47px
      width: 47px
      left: 0
      right: 0
      margin-left: auto
      margin-right: auto
      box-shadow: 0px 3px 11px -1px rgba(166,166,166,1)
      cursor: pointer
      transition: all 0.2s ease-out
      &:hover
        transform: translateY(-4px)

      .fa-chevron-down, .fa-chevron-up
        font-size: calcRem(25px)
        margin-top: 10px
