@import "sass/lib/typography"

.c-enquiry-btn
  display: block
  padding: 17px
  max-width: 422px
  width: 100%
  margin: 20px auto
  line-height: 1.3
  font-size: calcRem(20px)
