.payments__new
  &__wrapper
    +clearfix
    margin: auto

  &--header
    display: flex
    margin-bottom: 30px
    align-items: flex-start
    align-self: flex-start
    justify-content: center
    max-width: 1170px
    margin-left: auto
    margin-right: auto
    @media (max-width: $tablet-width)
      flex-direction: column

    &_img
      width: 50%
      @media (max-width: $tablet-width)
        margin: 15px auto
      @media (max-width: $mobile-width)
        width: 100%

      > img
        width: 100%

      &_credit
        font-size: calcRem(11px)
        padding: 10px 20px
        color: $text-grey
        display: block

    &_text
      padding: 40px 40px 0
      width: 50%
      @media (max-width: $tablet-width)
        width: 100%

      h2, h1
        text-align: left
        margin-top: 0
        @media (max-width: $tablet-width)
          font-size: 30px
        &.text-center
          text-align: center

      &__disclaimer
        font-style: italic
        font-size: calcRem(13px)
        color: $text-grey
        display: block
        line-height: 1.5

  &--error
    padding: 1em
    margin: 1em
    text-align: center
    color: $petrescue-red
    border: 1px dashed $petrescue-red
    font-weight: bold

  // below is a bit of a hack. We need to convert all inputs to components
  // and have them decide their own styles.
  &--amount-input, .c-field-container.amount .c-field-container__input
    display: flex
    align-items: center
    margin-bottom: 17px
    &:before
      display: inline-block
      content: "$"
      margin-right: 5px
      font-weight: 600
    & > input[type="number"]
      flex-grow: 1
      margin-bottom: 0 !important // hacky but temp until this becomes a component
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

  &--payment_widget_container
    position: relative
    max-width: 700px
    width: 100%
    color: #FFF
    margin: 0 auto 70px
    padding: 20px

    .tax_deductible
      margin: 0 15px 10px
      font-size: 13px
      text-align: center
      font-style: italic
    hr
      margin: 0 auto
      width: 90%

  &--policy_text
    max-width: 900px
    margin-left: auto
    margin-right: auto
    text-align: center
    font-size: 14px
    padding-right: 20px
    padding-left: 20px
    line-height: 24px
    font-style: italic

  &--widget
    padding: 10px
    font-size: 14px
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
    justify-content: space-around
    flex-flow: row wrap
    display: flex

    &--label
      font-weight: bold
      margin: 0
      font-size: 14px
      width: 100%

    label
      margin-bottom: 5px
      font-weight: 300
      font-weight: bold
      color: #fff
      display: inline-block

  &--widget_payment
    flex-grow: 1
    flex-basis: 100%

  .payment-form-container
    width: 90%

  .payment-form-container, .hosted-fields, .details
    justify-content: space-between
    flex-flow: row wrap
    display: flex

  .hosted-field-container
    margin-bottom: 15px

  .hosted-field-container, .field-container
    width: 100%

    &.card_cvc, &.card_expiry, &.first_name, &.last_name
      width: 48%

    .email-suggestion
      margin: 5px 0 0 0

    .intl-tel-input
      width: 100%
      margin-bottom: 15px
      color: #333

    &.error
      .intl-tel-input
        margin-bottom: 5px

    .payment--field-error
      height: 20px
      line-height: 20px
      color: red
      display: block
      margin: 0 0 15px 0

    .pin-form-field, .form-field
      height: 34px

      &:disabled
        background-color: #f9f9f9
        color: #848484

    .details
      width: 100%

      textarea
        min-height: 75px

      .form-field
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif

        &:focus
          outline: none
          border: 1px solid #4D90FE

        &::placeholder
          color: #999

        &::-moz-placeholder
          color: #999
          opacity: 1

        &--invalid:not(:focus)
          color: red
          border: 1px solid red

    &_payment
      width: 100%
      margin-top: 15px

  #payment_message
    // sets a max height so that if there is an error, it is still visable in the screen
    max-height: 400px

  .payments__new--submit
    width: 100%
    border-radius: 10px
    margin-top: 15px

    &:disabled
      opacity: 0.5
      color: #848490

  .payments__new--paypal
    width: 100%
    margin-top: 15px

  &--footer
    padding: 0 0 120px 0

    &--about_donation
      display: flex
      margin: auto

      @media (max-width: $tablet-width)
        & > img
          flex-direction: column
          align-items: center

    &--about_donation_text
      margin-left: 60px
      flex-grow: 1
      @media (max-width: $tablet-width)
        margin-top: 35px
        margin-left: 0
    ul
      list-style: disc
      li
        margin-bottom: 10px
        margin-left: 18px
        font-size: 18px
        line-height: 30px

  .icon_select
    list-style-type: none

  .checkout-overlay
    background: rgba(0,0,0, 0.8) !important
    a
      color: #fff !important
      font-weight: bold

  &--step
    margin: 0 auto
    max-width: 760px
    padding: 0 15px

  &--step_1
    .preview-image
      img
        margin: 0 auto
        width: 100%
        display: block
      &--note
        display: block
        font-size: calc(14px)
        color: $dark-grey
        margin-bottom: 11px
        text-align: center
        font-style: italic

    .c-icon-select__control
      @media (max-width: $mobile-width)
        width: 100px
        height: 100px

  #payment_gift_decoration_id_or_keyword_input
    margin-top: 25px
    .choices-group
      justify-content: center
      .choice
        margin: 10px 25px
        width: unset
        @media (max-width: $mobile-width)
          width: 27%
          min-width: unset
          margin: 10px

        .c-icon-select__control, .c-icon-select__input__el
          @media (max-width: $mobile-width)
            width: 100px
            height: 100px

  &--gifting_form
    margin: 35px auto 0 auto
    padding: 0 25px
    max-width: 576px

// Styles for fees pages
.payments__new--fees
  .payments__new--header_text
    width: 100%
  
  .payments__new--quote
    font-family: $font-serif
    position: relative

    &-heading 
      font-family: $font-serif
      margin: 0
      line-height: 1.4

    &-body
      line-height: 1.7
    
    &-name
      margin: 0 !important

    &:after, &:before
      content: '”'
      display: block
      position: absolute
      width: 20px
      height: 20px
      right: 0px
      bottom: 50px
      font-size: 34px
      @media (max-width: $tablet-width)
        bottom: 19px
    
    &:before
      content: '“'
      top: -5px
      bottom: unset
      right: unset
      left: -18px
      @media (max-width: $tablet-width)
        top: -10px
        left: -10px

// this is a hack to override the global form p tag selector that makes all paragraphs in forms
// 13px font size
.payments__new .payments__new--payment_form .content p:not(.component)
  // font-size: 1rem
  &.smaller
    font-size: calcRem(14)
  &.smallest
    font-size: calcRem(12)
  @media (max-width: $mobile-width)
    font-size: 1rem
