@import "sass/lib/variables"
@import "sass/lib/typography"

.c-gauge-wrapper
  position: relative
  color: #878787
  width: 100%
  font-size: 14px

  .confetti-canvas
    position: fixed
    top: 0
    left: 0

.c-gauge
  position: relative
  border-radius: 50%/100% 100% 0 0
  background-color: var(--color, $petrescue-green)
  overflow: hidden
  width: 100%

  &:before
    content: ""
    display: block
    padding-top: 50%

  .chart
    overflow: hidden

  &-mask
    position: absolute
    left: 20%
    right: 20%
    bottom: 0
    top: 40%
    background-color: $body-background
    border-radius: 50%/100% 100% 0 0

  &-percentage,
  &-pointer
    position: absolute
    transition-duration: 1.5s
    transition-property: transform
    transition-timing-function: ease-out
    transition-timing-function: cubic-bezier(0, 0, 0, 1)

  &-percentage
    top: -1px
    left: -1px
    bottom: 0
    right: -1px
    transform: rotate(var(--love-metere-rotation)) 
    transform-origin: bottom center
    background-color: #E7E7E7
  &-pointer
    height: 32px
    width: 210px
    bottom: -15px
    right: calc( 50% - 16px )
    transform-origin: calc( 100% - 15.5px) calc( 100% - 15px)
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 176 27'%3E%3Cpath d='M149.603 12.795c.16-1.835.713-3.664 1.695-5.364 3.59-6.217 11.54-8.348 17.758-4.758 6.218 3.59 8.348 11.54 4.759 17.758-3.59 6.218-11.541 8.349-17.759 4.759a12.966 12.966 0 0 1-6.181-8.395H2a2 2 0 1 1 0-4h147.603Z' fill='%233E3E3E'/%3E%3C/svg%3E")
    background-size: contain
    background-repeat: no-repeat
    background-position: right
    transform: scale(0.7) rotate(var(--love-metere-rotation)) 
    @media (min-width: $mobile-width) 
      transform: rotate(var(--love-metere-rotation)) 

  &-title
    font-weight: bold
    text-align: center
    margin-bottom: 15px

  &-min
    position: absolute
    bottom: -37px
    left: 4px

  &-max
    position: absolute
    bottom: -37px
    right: 4px
