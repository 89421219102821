@import "sass/lib/variables"

.c-adworks-landing-page-listings-container
  background-color: #F2F2F2
  padding: 40px 20px

  .cards-listings-preview
    max-width: 250px
    margin: 8px
    @media (max-width: $mobile-width)
      max-width: 100%


