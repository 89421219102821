@import "sass/lib/variables"
@import "sass/lib/typography"

.c-members-alert-banner-wrapper
  z-index: $member-alert-banner-1

.c-members-alert-banner
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1)
  border-radius: 20px
  padding: 15px
  background-color: $white
  border: solid 1px $very-light-grey
  position: absolute
  width: 100%
  transition: ease all 0.3s
  top: 0
  &__inner
    transition: ease all 0.3s 
    opacity: 0
  &:first-child
    position: relative
    z-index: 3
    bottom: 0
    scale: 1
    .c-members-alert-banner__inner
      opacity: 1
  &:nth-child(2)
    bottom: -25px
    scale: 0.97
    z-index: 2
    background-color: darken($white, 5%)
  &:nth-child(3)
    bottom: -35px
    scale: 0.94
    background-color: darken($white, 10%)
  // All banners after the 3rd 
  &:nth-child(n+4)
    bottom: -35px
    scale: 0.94
    opacity: 0
  &__close
    cursor: pointer
    min-width: 25px
    width: 25px
    height: 25px
    background: $light-grey url('~images/icons/close-button.svg') no-repeat 50%
    transition: ease all 0.2s
    border-radius: 50%
    border: none
    &:hover
      background-color: darken($light-grey, 25%)
      transform: scale(1.1)
  &__inner
    display: flex
    flex-direction: row
    gap: 15px
    position: relative
  &__title
    color: $text-black
    font-weight: medium
    text-decoration: none
    display: flex
    justify-content: space-between
    &:hover 
      background: transparent
      color: $text-black
      text-decoration: underline
  &__icon
    color: $petrescue-red
    font-size: calcRem(25px)
