@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-hosted-field
  &__container
    display: block
    height: 43px
    margin-top: 8px

  &__error
    color: $petrescue-red
    margin: 8px 0 0 0
    font-size: calcRem(14)
    font-weight: bold
    line-height: 1
