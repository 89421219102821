@import "sass/lib/variables"
@import "sass/lib/typography"

.c-blank-card
  background-color: white
  border-radius: 20px
  padding: 30px
  box-shadow: 0 2px 2px 0 #ddd

  // Kinds
  &--admin
    background-color: $admin-background-color
    box-shadow: none
    border: solid 1px #84b9e8

  // Sizes
  &--sml
    border-radius: 10px
    padding: 15px
