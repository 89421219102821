@import "sass/lib/variables"

.c-adworks-landing-page-card
  border-radius: 20px
  overflow: hidden
  margin: 14px
  text-decoration: none
  height: 354px
  position: relative
  max-width: 350px
  width: 100%
  &:hover
    transform: translateY(-4px)
  @media (max-width: $mobile-width)
    margin: 14px 0
    height: 300px
    background-size: cover

  &-text-container
    background-color: $petrescue-green
    background: url('~images/adworks-landing-page/card-backgroud.svg')
    color: white
    text-align: center
    padding: 40px 25px 25px
    position: absolute
    bottom: 0
    width: 100%

  &-icon
    position: absolute
    left: 20px
    top: 0

  &-heading
    margin: 0
    font-size: 24px
    font-family: $font-serif

  &-text
    font-size: 14px
    margin: 0
    text-align: center !important
