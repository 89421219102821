@import "sass/lib/variables"

.c-quick_search_block
  background-color: #fff
  padding: 10px 0
  @media (max-width: 850px)
    background-size: contain

  &--container
    display: flex
    flex-wrap: wrap
    @media (max-width: $mobile-width)
      flex-direction: column-reverse

  &--form
    width: 35%
    font-size: 1.5rem
    @media (max-width: $tablet-width)
      width: 50%
    @media (max-width: $mobile-width)
      width: 100%

    &--heading
      color: $text-black
      font-size: 40px

    &--label
      font-weight: bold
      margin: 15px 0 4px 0
      display: block
      font-size: calcRem(18)

    &--links
      font-weight: bold
      @media (max-width: $mobile-width)
        font-size: initial !important
        margin: 1.3em 0 !important


    .button
      width: 100%

  &--feature
    width: 58%
    background: url('~images/homepage/dotted-line.svg') no-repeat
    margin-left: 7%
    @media (max-width: $tablet-width)
      margin-left: 0
      width: 50%
      background: none
    @media (max-width: $mobile-width)
      width: 100%
      background: url('~images/homepage/dotted-line-mobile.svg') no-repeat
      padding-bottom: 110px
      background-position: center bottom
      margin: 0

    &--img
      width: 65%
      float: right
      @media (max-width: $tablet-width)
        width: 90%
      @media (max-width: $mobile-width)
        width: 100%
