@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"
@import "sass/lib/mixins"

.c-tag
  background-color: $very-light-grey
  border-radius: 4px
  display: inline-block

  &__inner
    padding: Min(20px, 0.38em) Min(20px, 0.47em)
    display: inline-block
    line-height: 1
    text-decoration: none

  &--active
    .c-tag__inner
      @include font-awesome("", 16)
      &:after
        display: inline-block
        transform: translateY(-2px) // centers the icon in the div
        margin-left: 2px

  &--dashed
    .c-tag__inner
      border: 1px dashed #454545
      background-color: #fff
      border-radius: 6px
      text-decoration: underline
