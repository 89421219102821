.c-promo-sidebar--prtv
  &__hills
    display: block
    min-height: 40px
    margin-top: -50px

  &__cloud
    position: absolute
    width: 80px
    top: 30px
    left: 60%
    // TODO: animate
