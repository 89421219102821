@import "sass/lib/variables"

.c-line-down
  background-repeat: no-repeat
  background-position: center
  background-size: contain

  // TODO: remove this - the parent component should manage margins
  margin-left: auto
  margin-right: auto

  &--curly
    width: 63px
    height: 112px
    background-image: url("~images/global/dotted-line-down.svg")

  &--straight
    width: 36px
    height: 98px
    background-image: url("~images/global/dotted-line-down-2.svg")
