@import "sass/lib/variables"
@import "sass/lib/typography"

.c-article-card
  background-color: #fff
  transition: .2s ease-in-out
  will-change: transform,box-shadow
  height: 100%
  margin: 0 auto
  &:hover
    transform: translateY(-4px)
    box-shadow: 0 0 8px rgba(0,0,0,0.15)
  &:active
    transform: translateY(0px)
  a, p 
    text-decoration: none

  &__img
    width: 100%
    object-fit: cover

  &__footer
    margin-top: auto

  // temp
  &__icons
    justify-content: center
    flex-direction: row
    margin-bottom: 1em 

  &__icon
    width: 25px
    height: 25px
    display: block
    background-repeat: no-repeat
    background-size: contain
    background-color: #D9D9D9
    border-radius: 100%
    background-size: 50%
    background-position: center
    margin: 2px

  .button
    margin: 0 auto
    display: block
    width: fit-content
