.button
  padding: 8px 30px
  background: none
  color: $text-white
  border-radius: 50px
  background-color: $petrescue-green
  border: 1px solid $petrescue-green
  @include text-selection($text-selction-light)
  text-decoration: none
  display: inline-block
  margin-bottom: 10px
  text-align: center
  font-weight: bold
  font-size: 19px
  cursor: pointer
  &:hover
    background-color: darken($petrescue-green, 10%)
  @media (max-width: $mobile-width)
    width: 100%

// This is a bit hacky but removing the margin assignment on the button above would
// be a lot of work so this will have to do for now
.button--no-margin
  margin-bottom: 0

.button-reset
  background: none
  color: inherit
  border: none
  padding: 0
  font: inherit
  cursor: pointer
  outline: inherit

.button--stripped
  padding: 10px 8px
  display: block
  font-size: small
  background: none
  border: none
  text-decoration: underline
  @media (max-width: $mobile-width)
    padding: 8px
    display: block

.button.disabled, .donate--button.disabled
  @include text-selection($text-selection-green)
  background-color: #e6e6e6
  border-color: #e6e6e6
  color: darken(#e6e6e6, 40%)
  cursor: default
  &:hover
    background-color: #e6e6e6
    border-color: #e6e6e6

.button--grey
  @extend .button
  background-color: #333
  border-color: #333
  &:hover
    background-color: darken(#333, 10%)

.button--white
  @extend .button
  @include text-selection($text-selection-green)
  background-color: #fff
  border: 3px solid #333
  color: #333
  &:hover
    background-color: darken(#fff, 10%)

.button--red
  @extend .button
  @include text-selection($text-selction-dark)
  background-color: $error-red
  border-color: $error-red
  &:hover
    background-color: darken($error-red, 10%)

.admin-button
  padding: 5px 10px
  background: none
  color: $text-white
  background-color: $petrescue-green
  border: 1px solid $petrescue-green
  border-radius: 3px
  text-decoration: none
  display: inline-block
  text-align: center
  font-weight: bold
  font-size: 12px
  cursor: pointer
  &:hover
    background-color: darken($petrescue-green, 10%)

.admin-button--red
  @extend .admin-button
  background-color: $error-red
  border-color: $error-red
  &:hover
    background-color: darken($error-red, 10%)

.admin-button--green
  @extend .admin-button
  background-color: $petrescue-green
  border-color: $petrescue-green
  &:hover
    background-color: darken($petrescue-green, 10%)

.article--button
  padding: 8px 20px
  background-color: darken($almost-white, 3%)
  color: $very-dark-grey
  border-radius: 7px
  border: none
  box-shadow: 0 2px 0 0px $dark-grey
  text-decoration: none
  display: inline-block
  margin-bottom: 10px
  font-weight: bold
  font-size: 19px
  text-align: center
  transition: 0.1s ease-out
  &:hover
    box-shadow: 0 4px 0 0px $dark-grey
    transform: translateY(-2px)
  @media (max-width: $mobile-width)
    width: 100%

.article--button--strong
  @extend .article--button
  background-color: $very-dark-grey
  color: $text-white
  box-shadow: 0 2px 0 0px $petrescue-green
  &:hover
    box-shadow: 0 4px 0 0px $petrescue-green

// TODO: delete this donation button code once we have replaced all donation buttons with component alternatives
.donate--button
  @extend .article--button
  background-color: $petrescue-yellow
  border-color: $petrescue-yellow
  color: $very-dark-grey
  box-shadow: 0 2px 0 0px darken($petrescue-yellow, 15%)
  &:hover
    box-shadow: 0 4px 0 0px darken($petrescue-yellow, 15%)

.button.no-hover
  &:hover
    background-color: $petrescue-green !important

.button--social
  display: flex
  justify-content: center
  align-items: center
  padding: 14px 10px
  text-decoration: none
  color: white !important
  font-weight: bold
  border-radius: 30px

  .icon
    display: block
    width: 22px
    height: 22px
    margin-right: 16px
    background-size: contain
    background-repeat: no-repeat
    background-position: center

  &.facebook
    background-color: $facebook-blue
    &:hover
      background-color: darken($facebook-blue, 10%)
    .icon
      background-image: url("~images/icons/facebook-white.svg")
  &.twitter
    background-color: $twitter-blue
    &:hover
      background-color: darken($twitter-blue, 10%)
    .icon
      background-image: url("~images/icons/twitter-white.svg")
  &.google
    background-color: $google-red
    &:hover
      background-color: darken($google-red, 10%)
    .icon
      background-image: url("~images/icons/google-white.svg")

.badged-button
  display: block
  background-color: white
  text-align: center
  border-radius: 10px
  box-shadow: 1px 1px 2px rgba(132, 132, 132, 0.20)
  padding: 24px 19px 18px 19px
  position: relative
  font-size: calcRem(16px)
  font-weight: bold
  text-decoration: none
  position: relative
  transition: 0.1s ease-out
  will-change: transform, box-shadow

  &::before
    content: ""
    background: center / contain no-repeat url("~images/global/logo-glyph.svg")
    width: 57px
    height: 57px
    display: block
    position: absolute
    top: -39px
    left: 50%
    transform: translateX(-50%)

  &:hover
    transform: translateY(-4px)
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15)
  &:active
    transform: translateY(0px)
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15)

.update-button, .reset-button
  display: flex
  justify-content: space-between
  align-items: center
  margin: 0
  border-radius: 11px
  border: none
  text-align: left
  text-decoration: none
  padding: 11px 20px
  font-size: calcRem(16px)
  font-weight: bold
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.11)
  transition: 0.1s ease-out all
  cursor: pointer
  &:first-child
    margin-right: 5px
  &:hover
    background-color: white
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15)

.update-button
  background-color: white
  color: $text-black
  &::after
    content: ""
    background: center / contain no-repeat url("~images/icons/arrow-right.svg")
    width: 15px
    height: 10px
    opacity: 0.3
    margin-left: 15px
  &:hover::after
    opacity: 1
  &:hover
    padding-left: 25px

.reset-button
  background-color: $very-dark-grey
  color: white
  &:hover
    color: white
    background-color: $very-dark-grey
    padding: 11px 25px

.icon-only-button
  display: inline-block
  border: none
  margin: 0
  padding: 0
  background-color: transparent
  cursor: pointer

.button-d-block
  display: block
  width: max-content
