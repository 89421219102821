.c-listing-video-thumbnail
  position: relative
  padding: 0 20px 10px 0
  cursor: pointer
  transition: .1s ease-out
  &:hover
    transform: translateY(-4px)
    .overlay
      background: rgba(0,0,0,.2) url('~images/icons/video-play-hover.svg') no-repeat 50%
      background-size: 50px
  &-img
    width: 100%
    border-radius: 10px

.overlay
  position: absolute
  pointer-events: none
  width: calc(100% - 20px)
  height: calc(100% - 13px)
  background: rgba(0,0,0,.2) url('~images/icons/video-play.svg') no-repeat 50%
  background-size: 50px
  border-radius: 10px
