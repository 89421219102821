body.admin
  // Sticky Posts, Members Library
  #members-library
    .wrapper
      display: flex
      @media (max-width: $mobile-width)
        flex-direction: column
        -webkit-flex-direction: column
        -ms-flex-direction: column
        -moz-flex-direction: column
        padding: 0
        margin: 0
      .important_info
        width: 32%
        @media (max-width: $mobile-width)
          width: 100%
          a
            font-size: 13px
        h3
          margin-top: 0
          margin-bottom: 10px
          color: $petrescue-green
        ul
          li
            margin-bottom: 15px
      .stats
        .stats_container
          text-align: center
          span
            font-size: 40px
            color: $petrescue-green
            display: block
            font-weight: bold
          .groups_made_listings
            border-top: 1px #ddd solid

      .blog_post_collection
        width: 65%
        margin-right: 2%
        @media (max-width: $mobile-width)
          width: 100%
          margin-bottom: 15px
        h3
          margin-top: 0
          margin-bottom: 10px
        .article-excerpt
          overflow: hidden
          margin-bottom: 15px

        article
          &.featured
            margin-bottom: 15px
            position: relative
            box-shadow: 0 1px 0 0px rgba(0,0,0,0.1)
            padding: 20px
            border-radius: 10px
            background-color: #fff
            h3
              font-size: 28px
              line-height: 30px
              a
                color: $petrescue-green
                text-decoration: none
                &:hover
                  text-decoration: underline
                  color: $text-black
            img.featured-article-image
              width: 100%
              max-height: 265px
              object-fit: cover
            .button
              padding: 5px 30px
          .actions
            margin-top: auto
            display: flex
            @media (max-width: $mobile-width)
              flex-direction: column-reverse
              -webkit-flex-direction: column-reverse
              -ms-flex-direction: column-reverse
              -moz-flex-direction: column-reverse
              .button
                margin: 0
              p
                font-size: 100%
          .date_published
            font-size: 14.5px
            position: absolute
            bottom: 10px
            right: 25px
            @media (max-width: $mobile-width)
              width: 100%
              font-size: 13px
              position: inherit
        &--see_more
          margin: auto
          width: fit-content
          display: block
