@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-gift-card-inline-banner
  max-width: calcRem(605)
  padding: calcRem(25) calcRem(60)
  background-color: $very-dark-grey
  color: white
  text-align: center
  box-sizing: border-box
  border-radius: calcRem(8)

  @media (min-width: $tablet-small-width)
    padding: calcRem(35) calcRem(60)

  &__icon
    width: calcRem(60)

  &__content
    padding: 0
    @media (min-width: $tablet-small-width)
      padding: 0 calcRem(50)

    & > p
      font-size: calcRem(25)
      font-family: $font-serif
      margin: 0
      line-height: 1.3

    & > p + p
      margin-top: calcRem(20)

  // doubl "&" for specificity
  & &__cta
    margin-top: calcRem(26)
    min-width: 100%
    text-transform: uppercase
    font-size: calcRem(14)

