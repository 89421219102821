@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-lozenge
  --lozenge-text-color: white
  --lozenge-primary-color: #{$petrescue-green}

  display: inline-block
  line-height: 1
  white-space: nowrap
  vertical-align: middle
  text-transform: uppercase
  border-radius: calcRem(30px)
  padding: calcRem(4) calcRem(8)
  font-size: 0.75rem
  font-weight: 700
  color: var(--lozenge-text-color)
  background-color: var(--lozenge-primary-color)

  &--green
    --lozenge-primary-color: #{$petrescue-green}

  &--grey
    --lozenge-primary-color: #{$very-dark-grey}

  &--lightgrey
    --lozenge-primary-color: #{$light-grey}
    --lozenge-text-color: #{$text-black}

  &--yellow
    --lozenge-primary-color: #{$petrescue-yellow}
    --lozenge-text-color: #{$text-black}
