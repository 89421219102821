@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-accordion
  .c-accordion-title
    background-color: #F7F7F7
    padding: 10px
    margin-bottom: 8px
    cursor: pointer
    &:hover
      background-color: #e8e8e8

  .c-accordion-title__inner
    max-width: 820px
    margin: auto
    position: relative
    display: flex
    justify-content: space-between
    align-items: center
    &--stretch
      max-width: none

  .c-accordion-title__heading
    font-size: calcRem(26px)
    font-family: $font-serif
    line-height: 44px
    color: $text-dark-grey
    margin: 0

  .c-accordion-chevron
    position: absolute
    right: 0px
    background-color: white
    padding: 6px 7px
    border-radius: 50%
    cursor: pointer
    width: 33px
    color: white
    font-size: 20px
    border: none
  .c-accordion-chevron::before
    color: #131313
  .c-accordion-body
    max-width: 820px
    margin: 0 auto 0
    transition: height 0.35s ease-in-out
    overflow-x: hidden
    overflow-y: hidden
    &--stretch
      max-width: none
    
  .c-accordion-body-inside, .c-accordion-body-inside p, .c-accordion-body-inside ul
    font-size: calcRem(16px)
    line-height: 23px

  &.active
    .c-accordion-title
      background-color: #e8e8e8

  &:not(.active)
    .c-accordion-body
      display: none

  @media(max-width: 480px)
    .c-accordion-body-inside, .c-accordion-body-inside p
      margin-bottom: 35px
      padding: 0 15px
      font-size: calcRem(12px)
