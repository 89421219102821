@import "sass/lib/typography"
@import "sass/lib/variables"

.c-listing-photo-link
  background-color: #F6F6F6
  border-radius: 6px
  padding: 30px
  margin-bottom: 35px
  width: 48%
  text-align: center
  @media(max-width: $tablet-width)
    width: 100%

  &-heading
    font-size: calcRem(30px)
    font-family: $font-sans
    line-height: 30px
    margin: 0

  &-text
    font-size: calcRem(20px)

  &-empty
    color: $petrescue-green
    font-weight: bold
    height: 66px
    margin-top: 40px

  .button
    display: block
    margin: 0 auto
    width: 260px

  &__thumbnails
    margin: 40px 0 30px
    display: flex
    flex-direction: row

  &__thumbnail
    position: relative
    margin: 1%
    &-img
      width: 100%
      display: block
    .overlay
      width: 100%
      height: 100%
      border-radius: 0
    &-more
      color: white
      font-size: 20px
      position: absolute
      background-color: rgba(0,0,0,0.35)
      width: 100%
      height: 100%
      padding: 40%
