.enquiry-form .wrapper
  max-width: 700px
  .img-container
    margin: 0 auto
    width: 55%
    position: relative
    @media (max-width: $mobile-width)
      width: 100%
    img
      border-radius: 50%
    .right-img
      width: 200px
      position: absolute
      right: 1px
    .default-img
      width: 200px
      height: 200px
      background-color: #D8D8D8
      border-radius: 50%
      text-align: center
      padding: 25px 0
      i
        color: #fff
        font-size: 130px
        margin-top: 30px

  .listing
    &--info
      border: 2px dashed
      display: flex
      flex-direction: column
      justify-content: space-between
      margin: 1em 0
      padding: 1.6em
      @media (min-width: $mobile-width)
        flex-direction: row

      &__personality
        margin-top: 1em
        word-break: break-word
        @media (min-width: $mobile-width)
          margin-top: 0em

      &__left
        img
          max-width: 200px
          max-height: 200px

      &__right
        @media (min-width: $mobile-width)
          padding-left: 1.6em

        .checklist
          margin-top: 1em

          &--heading
            margin-top: 1em

          &--item
            display: flex

          &--icon
            padding-right: 0.4em

            .fa-check
              color: $petrescue-green
            .fa-remove
              color: red

  #listings_enquiry_message_input
    textarea
      min-height: 280px

  .actions
    width: 100%
    > ol 
      display: flex
      flex-wrap: wrap
      justify-content: center
      grid-row-gap: 10px
      flex-direction: column
      align-items: center
      width: 100%
      font-size: 15px
    .button
      width: 400px
      font-size: 21px
      padding: 10px
      @media (max-width: $mobile-width)
        width: 100%
    .terms
      margin: 10px auto
      max-width: 400px
      text-align: center

  .group_policy_level
    padding: 20px
    border: 1px dashed
    margin: 40px
    font-weight: normal
    font-size: 1em

    ol
      list-style: disc
      padding: 0 20px
      flex-direction: column
      li
        margin: 4px

    ol, p
      font-size: 15px
      font-style: italic

.enquiry-form a
  font-weight: bold
