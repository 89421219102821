@import "sass/lib/variables"
@import "sass/lib/typography"

.c-our-team-subheading > p
  max-width: 950px
  padding: 0 30px
  margin: 0 auto
  font-size: calcRem(20px)
  text-align: center
  color: $text-dark-grey
  @media(max-width: $mobile-width)
    font-size: calcRem(18px)
