.gifting
  .payments__new--header_img
    width: 80%
    // mobile first for this one
    @media (min-width: $tablet-width)
      width: 50%

.payments__thank_you
  // TODO: refactor into a component markup to avoid cascade issues
  .thankyou-img.large_image
    width: auto
    // mobile first for this one
    @media (min-width: $tablet-width)
      width: 30%
