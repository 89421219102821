.advent-calendar
  &--container
    padding: 0 30px 
    @media (min-width: $laptop-width)
      padding: 0 15px 

  &--grid
    @media (min-width: $mobile-width)
      grid-gap: 100px !important
      grid-template-columns: repeat(3, 1fr) !important
    @media (min-width: $laptop-width)
      grid-template-columns: repeat(4, 1fr) !important
