.registration
  .wrapper
    max-width: 940px
    @media (max-width: $mobile-width)
      margin: 20px auto

.login
  &-heading
    max-width: 450px
    margin: 0 auto

    &-signup
      font-size: calcRem(14px)
      font-style: italic
      color: $text-grey

  &__form-wrapper
    display: flex
    justify-content: space-evenly
    align-items: center
    @media( max-width: $mobile-width)
      flex-direction: column

    .login-img
      max-width: 305px
      width: 45%
      @media( max-width: $mobile-width)
        width: 100%
    .login-form
      max-width: 340px

  &-forgot-password
    text-align: center
    color: $petrescue-green
    display: block
    font-style: italic
    font-size: calcRem(14px)

  &-signup
    text-align: center
    &:before
      display: block
      content: " "
      height: 100px
      width: 46px
      margin: 30px auto
      background: transparent url("~images/signup/dotted-line-2.svg") no-repeat
    &-no-acount
      font-size: calcRem(14px)
    &-cta
      font-weight: bold
      @media (max-width: $mobile-width)
        text-align: center

.login-page-break, .signup-page-break
  background: transparent url("~images/signup/dotted-line.svg") repeat-x 50% 50%
  text-align: center
  margin: 70px 0 40px
  .page-break-text
    padding: 8px
    background-color: $body-background

.registration__form-wrapper
  width: 100%
  max-width: 400px
  margin: 0 auto

.registration__signup-options
  margin: 0 auto
  max-width: 280px
  .button--social
    width: 100%
    margin: 0 0 10px
    border: none
    cursor: pointer

.signup-page-break
  margin: 30px 0

.registration-form
  .input textarea
    height: 150px
    @media (max-width: $mobile-width)
      height: 120px !important
  .terms-of-use
    padding-bottom: 20px
    p
      margin: 0
      text-align: center
  .actions ol 
    display: flex
    flex-wrap: wrap
    justify-content: center
  .user-links
    text-align: center
    margin-bottom: 10px
  .registration__fieldset
    > ol
      flex-direction: column
      .input:not(.half):not(.w-third)
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        flex-direction: column
