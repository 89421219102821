@import "sass/lib/variables"
@import "./overrides"

$select-size: .4em 2em .4em .6em

.c-select
  display: block
  font-size: 1em
  font-family: $font-sans
  font-weight: 600
  color: $text-black
  line-height: 1.3
  padding: $select-size
  max-width: 100% // useful when width is set to anything other than 100%
  box-sizing: border-box
  border: 2px solid $dark-grey
  box-shadow: 0 0 2px rgba(0,0,0,.2)
  border-radius: $form-input-radius
  appearance: none
  background-color: #fff
  // note: bg image below an svg data uri for the arrow icon
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 293 165' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M287 5.4A17.6 17.6 0 00274 0H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 000 18.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4 5 0 9.2-1.8 12.8-5.4L287 31c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8h.1z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E%0A")
  background-repeat: no-repeat, repeat
  // arrow icon position (1em from the right, 50% vertical)
  background-position: right .7em top 50%
  // icon size
  background-size: .65em auto

  // Hide arrow icon in IE browsers
  &:-ms-expand
    display: none

  @media (hover: hover) and (pointer: fine)
    &:hover
      border-color: $text-black

  &:focus
    border-color: $text-black
    // It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7)
    box-shadow: 0 0 0 3px -moz-mac-focusring
    color: #222
    outline: none

  // when the select box supports multiple options
  &[multiple]
    background-image: none
    padding: 0.4em 0
    width: auto
    $optgroup-margin: .6em
    optgroup
      padding: .3em 0
      margin: 0 $optgroup-margin
    optgroup > option
      margin: 0 -#{$optgroup-margin}
    option
      padding: $select-size

  // Set options to normal weight
  option.c-select__option
    font-weight: normal

  option.c-select__placeholder
    color: $dark-grey

  // invalid is used for the palceholder
  &:invalid
    color: $dark-grey

  // Support for rtl text, explicit support for Arabic and Hebrew
  *[dir="rtl"] &, :root:lang(ar) &, :root:lang(iw) &
    background-position: left .7em top 50%, 0 0
    padding: .6em .8em .5em 1.4em

  // Disabled styles *
  &:disabled, &[aria-disabled=true]
    color: graytext
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E')

  &:disabled:hover, &[aria-disabled=true]
    border-color: $dark-grey

  &--with-placeholder
    color: $dark-grey

  &--error
    // color: $petrescue-red
    border-color: $petrescue-red
