@import "sass/lib/variables"
@import "sass/lib/typography"

.c-our-team-footnote > p
  font-size: calcRem(16px)
  line-height: 26px
  max-width: 950px
  margin: 0 auto 90px auto
  color: $text-dark-grey
  text-align: center
  padding: 0 20px
