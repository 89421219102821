.api-v1-docs, .api-v2-docs
  td, th
    span.example
      font-weight: bold
    span.required
      display: block
      font-size: 80%
      font-weight: normal
    &:first-child
      width: 33%
    ul
      margin-top: 0
      list-style-type: disc
      list-style-position: inside

      li
        display: list-item !important
  td.required:after
    content: '\2714\0020'
