.conversations--show
  &.preview
    .wrapper
      margin: 0

      .conversation-container
        margin: 0
        padding: 0.5em
        width: 100%
        float: none

        .conversation-window
          max-height: none
