@import "sass/lib/variables"
@import "sass/lib/typography"

.c-h2h-header
  &-background
    background: url('~images/h2h/background-wave.svg') no-repeat
    background-size: cover
    height: 105px
    width: 100%
  &-wrapper
    background-color: #F6F6F6
    margin: 0 auto
    padding: 30px 20px
    display: flex
    align-items: center
    justify-content: center
    @media( max-width: $tablet-width)
      flex-direction: column
  &-icon
    width: 30px
    height: 30px
    vertical-align: middle
    margin-left: 8px
  &-poweredBy
    display: inline-block
    margin-left: 57px
    font-weight: bold
    font-size: calcRem(12px)
    position: relative
    @media( max-width: $tablet-width)
      margin-top: 65px
      margin-left: 0
  &-squiggle
    position: absolute
    top: -36px
    left: -36px
    @media( max-width: $tablet-width)
      top: -43px
      left: 18px
  &-text
    display: inline-block
    color: #5B5B5B
    font-size: calcRem(16px)
    margin-left: 45px
    font-weight: 300
    @media( max-width: $tablet-width)
      margin-left: 0
      text-align: center
