@import "sass/lib/typography"
@import "sass/lib/variables"

.c-listing-photo-uploader
  max-width: 800px
  margin: 0 auto
  .column-form__fieldset
    padding: 40px 80px

  legend
    margin-bottom: 0

  &-text
    font-size: 16px
    &:first-child
      margin-top: 0

  &__list
    display: flex
    flex-wrap: wrap
    overflow: hidden
    justify-content: center !important
    align-items: center
  &__thumbnail
    padding: 10px 15px
    margin: 5px 0
    position: relative

    @media (max-width: $tablet-width)
      width: 25%
    @media (max-width: $mobile-width)
      width: 50%
      padding: 10px

    &-placeholder
      width: 200px
      background-color: #fff
      background-image: url("~images/icons/upload.svg")
      background-size: 50%
      background-repeat: no-repeat
      background-position: center
      cursor: pointer
      margin-bottom: 36px
      max-width: 100%
      &:before
        content: ""
        display: block
        padding-top: 100%

    &-img
      display: block
      width: 100%
      cursor: move

    .action
      text-decoration: none
      margin: 8px 0
      font-weight: bold
      display: inline-block
      @media (max-width: $tablet-width)
        font-size: 22px
    .remove-photo
      float: right
    .edit-photo
      color: $petrescue-green

    .overlay
      height: calc(100% - 54px)
      border-radius: 0
      width: calc(100% - 30px)
      @media (max-width: 800px)
        height: calc(100% - 61px)
      @media (max-width: $mobile-width)
        width: calc(100% - 20px)

  #sortablePhotos .c-listing-photo-uploader__thumbnail:first-of-type
    background-color: #E8E8E8
    &:before
      content: 'FEATURED PHOTO'
      text-align: center
      font-weight: bold
      font-family: $font-sans
      display: block
      margin-bottom: 8px
      font-size: 14px

  .button
    margin: 10px auto 10px auto
    display: block

  &__limit
    text-align: center
    font-size: calcRem(13px)

  .c-pill-button.back
    margin-right: 10px
    margin-bottom: 0
    @media (max-width: $mobile-width)
      margin: 15px auto 0
      display: block

      &:first-child
        margin-top: 0

