.share_buttons
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  flex-direction: column
  position: fixed
  top: 50%
  left: 0
  @media (max-width: $tablet-width)
    position: relative
    flex-direction: row
    top: 0
    max-width: 220px
    margin: 25px auto 20px

.share_buttons__button
  cursor: pointer
  height: 40px
  width: 40px
  color: white
  text-decoration: none
  display: flex
  justify-content: center
  background-color: #494949
  align-items: center
  border: none
  border-radius: 100%
  &:hover
    background-color: darken(#494949, 10%)
    color: white

  i
    font-size: 19px

.print
  display: none
  @media (max-width: $tablet-width)
    display: flex
    width: 100%
    margin-top: 10px
