@import "sass/lib/variables"

.c-notifications-dropdown
  position: absolute
  right: 0
  top: 72px
  z-index: $main-nav-dropdown-index
  display: none
  width: 310px
  width: 360px
  @media (max-width: $mobile-width)
    width: 100%
    margin: 0
    left: 0

  &__content
    margin: 0
    box-shadow: 0 0 8px rgba(0,0,0,0.4)
    border: 1px solid #ddd
    position: relative
    padding: 0
    background-color: white

    @media (max-width: $mobile-width)
      border-radius: 0
      box-shadow: none

    .c-notifications-dropdown-close
      float: right
      width: 20px
      height: 20px
      margin: 10px
      background: $petrescue-green url('~images/icons/close-button.svg') no-repeat 50%
      transition: all linear 50ms
      border-radius: 30px
      border: none
      &:hover
        background-color: darken($petrescue-green, 15%)
      @media (max-width: $mobile-width)
        width: 35px
        height: 35px
        margin: 2px !important

  .c-notification_heading
    margin: 8px
    @media (max-width: $mobile-width)
      font-size: 1.2em
  .c-mark_as_read
    position: absolute
    top: 13px
    right: 40px
    font-size: 12px
    text-decoration: underline
    cursor: pointer
    @media (max-width: $mobile-width)
      right: 60px
      top: 8px
      font-size: 15px
  .c-notification_list
    margin: 5px 0
    overflow: scroll
    max-height: 420px
    @media (max-width: $mobile-width)
      height: auto
      max-height: 85vh

    .c-notification_item
      border-top: 1px solid #ddd
      padding: 15px 12px
      font-size: .9em
      position: relative
      &.active
        background-color: #d5edb6

      a
        text-transform: none
        padding: 0
        line-height: 1em

      a.button
        border-radius: 50px
        margin-top: 15px
        padding: 8px
        width: 140px
        clear: both
        margin-bottom: 0
        @media (max-width: $mobile-width)
          margin: 20px 0 0 0

      .notification_body
        margin: 0
        a
          background: none
          display: inline-block
          color: #333
          font-size: inherit
          font-weight: inherit
          text-decoration: underline

      .notification_note
        margin-top: 10px
        font-size: 0.9em
        color: $dark-grey

      .c-notifications-dropdown-date
        font-size: .8em
        color: #5d5d5d
        position: absolute
        right: 12px
        bottom: 15px
