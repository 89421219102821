
/**
  We have two animations running so santa can fly on a curve
 **/

$santa-width: 154px
$santa-height: 73px

@keyframes santa-y
  0%
    transform: translateY(-10vh)
  100%
    transform: translateY(-100vh)

@keyframes santa-x
  0%
    transform: translateX(-$santa-width)
    opacity: 0
  5%
    opacity: 1
  95%
    opacity: 1
  100%
    transform: translateX(100vw) rotate(-30deg)
    opacity: 0

.christmas-spirit
  position: fixed
  z-index: $santa-index
  bottom: 0
  left: 0
  animation: 3s ease-in 1 forwards santa-y

  &::before
    display: block
    content: ""
    width: $santa-width
    height: $santa-height
    background-image: url(~images/global/santa-and-sleigh.svg)
    background-size: contain
    background-repeat: no-repeat
    opacity: 0
    animation: 3s linear 1 forwards santa-x

