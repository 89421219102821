form.user .user-links
  padding: 0
  margin-top: 10px
  li
    text-align: center
    width: 100%
  p
    display: inline-block
    margin-bottom: 0

.user_settings .edit-account
  .image
    margin: 10px 0


//adopter profile
.user-profile
  color: #555555
  display: flex
  justify-content: space-evenly
  -webkit-justify-content: space-evenly
  -moz-justify-content: space-evenly
  -ms-justify-content: space-evenly
  @media (max-width: 950px)
    flex-direction: column
    -webkit-flex-direction: column
    -moz-flex-direction: column
    -ms-flex-direction: column
  .right-hand
    width: 70%
    @media (max-width: 950px)
      width: 100%
  .left-hand
    width: 30%
    @media (max-width: 950px)
      width: 50%
      margin: 0 auto
    @media (max-width: $mobile-width)
      width: 100%
    .image_silder
      img
        width: 95%
  .user_name
    color: $petrescue-green
    margin: 0
  .contact_info
    margin-top: 0
    li
      font-weight: bold
      .fa-circle
        color: $petrescue-green
        font-size: 10px
        vertical-align: top
        margin: 5px 10px

  .sub_header
    margin-bottom: 3px
    color: $petrescue-green
    font-size:  1.5em

  .family
    display: inline-block
    margin: 0 10px

  .user_pet
    text-align: center
    display: inline-block
    margin: 15px
    width: 220px
    vertical-align: top
    img
      width: 120px
      height: 120px
      object-fit: cover
      border-radius: 100%
    .pet_name
      color: $petrescue-green
      font-size: 1.2em
      overflow-wrap: break-word
    .pet_age
      margin-bottom: 10px
    h4
      margin: 0
      text-transform: capitalize
    p
      margin: 0 0 5px 0
      @media (max-width: $mobile-width)
        text-align: center

.users-show
  .edit-button
    width: 300px
    margin: 0 auto
    margin-top: 40px
    display: block
