@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-outline-button
  --button-text-color: white
  --button-primary-color: white
  --button-accent-color: #{$petrescue-green}
  --button-focus-color: #{$very-dark-grey}

  display: inline-flex
  justify-content: center
  align-items: center

  text-align: center
  text-transform: uppercase
  padding: calcRem(13) calcRem(17)
  color: var(--button-text-color)
  font-family: $font-sans
  font-weight: bold
  font-size: calcRem(13)
  cursor: pointer
  border-radius: calcRem(9999999)
  transition: 0.1s ease-out

  // reset styles
  border: 2px solid var(--button-primary-color)
  text-decoration: none

  &:hover, &:focus
    background-color: var(--button-primary-color)
    color: var(--button-accent-color)

