.payments__new
  .tax_appeal_2022
    .payments__new--header_text
      @media (min-width: $tablet-small-width)
        align-self: center
  // remove space between image and text for mobile
  .tt-img 
    margin-bottom: 0 !important


.payments__thank_you
  .tax_appeal_2022
    h1, p
      max-width: 710px
      margin-left: auto
      margin-right: auto
    .large_image
      border-radius: 0
      @media(max-width: $mobile-width)
        width: 75%
    .img_credit
      font-size: calcRem(12px)
      font-style: italic

.c-campaign-banner.tax_appeal_2022
  .c-container-standard
    max-width: map-get($container-sizes, "thinner")
    .c-campaign-banner_inner
      justify-content: space-between
      .c-campaign-banner__text
        position: relative
        &:after 
          height: 30px
          margin-left: 10px
          position: absolute
          width: 90px
          background-repeat: no-repeat
          background-image: url('~images/global/dotted-line-white.svg')
          @media (min-width: $tablet-width)
            content: ''
