@import "sass/lib/variables"

.c-christmas_2022
  .bauble-confetti
    position: absolute
    left: -50px
    display: none
    @media (min-width: $tablet-width)
      display: block
  .lights-bauble
    transform: translateY(-5px)
    max-width: 17rem
    width: 100%
    height: max-content
    
    .lights
      path
        animation-fill-mode: both
        animation-iteration-count: infinite
        animation-duration: 2s
        animation-name: light-flash
        animation-timing-function: ease-out
        &:nth-child(odd) 
          animation-duration: 1.8s
        &:nth-child(2n+1)
          animation-duration: 0.8s
        &:nth-child(4n+2) 
          animation-duration: 1.2s
        &:nth-child(3n+1) 
          animation-duration: 1.5s



  @keyframes light-flash
    0%
      opacity: 1
    100%
      opacity: 0.3
