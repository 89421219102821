@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-conversations-message-input
  position: fixed
  bottom: 0
  left: 0
  background: $white
  padding: 10px
  z-index: 10
  width: 100%
  @media (min-width: $tablet-small-width)
    position: relative
  // Override our default text area styles to match design
  textarea
    width: 100%
    line-height: 1.5
    resize: none
    border-radius: 20px
    padding: 15px
    max-height: 40vh
    @media (min-width: $tablet-small-width)
      min-height: 120px

  // These styles are initially copied from the buttons/pill component. Have chosen not to extend
  // from it directly given the styles deviate enough that any change in that component would
  // likelly negatively effect this one.
  .c-add-attachement-btn
    --button-primary-color: #F2F2F2
    --button-primary-color-on-hover: #{darken(#F2F2F2, 10%)}
    --button-text-color: $text-black

    display: inline-flex
    align-items: center
    overflow: hidden
    cursor: pointer

    // Values are set statically to allow for an "expand on hover" effect which shows the text
    max-width: 26px
    padding: 0 10px 0 7px
    height: 26px
    position: absolute
    bottom: 10px
    right: 10px

    font-size: calcRem(16)
    white-space: nowrap
    border-radius: 9999em
    transition: 0.2s ease-out
    // reset styles
    border: none
    text-decoration: none

    color: var(--button-text-color)
    background-color: var(--button-primary-color)
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25)

    @media (max-width: $tablet-small-width)
      position: static
      max-width: 200px // roughly the size

    @media (hover: hover) and (pointer: fine)
      &:hover, &:focus
        background-color: var(--button-primary-color-on-hover)
        max-width: 200px // roughly the size

    &__info
      display: inline-block
      white-space: nowrap
      margin-left: 8px
      font-size: calcRem(14)

  // This is a bit of a hack but in order to change the location of the attachment button
  // depending on screen size, we have to duplicate this wrapper
  &__actions-box
    display: flex
    align-items: center

    &.--with-attachment
      @media (max-width: $tablet-small-width)
        margin-top: 14px
      button[type="submit"]
        margin-left: auto
        @media (min-width: $tablet-small-width)
          display: none

    &.--with-notice
      @media (min-width: $tablet-small-width)
        margin-top: 14px
      button[type="submit"]
        @media (max-width: $tablet-small-width)
          display: none
  &__notice
    display: none
    @media (min-width: $tablet-small-width)
      display: flex

