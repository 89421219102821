@import "sass/lib/variables"
@import "sass/lib/typography"

.c-form-legacy-disclosure
  p:first-child
    margin-top: 0

  &--trigger
    cursor: pointer
    padding: 0
    display: inline-block
    margin-bottom: 10px
  &[hidden]
    display: none
