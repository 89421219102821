#site-menu-drawer
  position: relative
  transition-property: all
  transition-duration: .5s
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  left: 0
  body.sidebar-open &
    left: $sidebar-width
    @media (max-width: $mobile-width)
      left: 0
    // Hack fix to allow sticky element on listing form
    &.listing-controller
      overlfow: hidden

.sidebar-nav
  width: $sidebar-width
  border-right: 1px solid $sidebar-header-color
  height: 100%
  position: fixed
  left: -($sidebar-width + 1) // +1 for border width
  top: 0
  overflow-x: auto
  -webkit-overflow-scrolling: touch
  background-color: $body-background
  z-index: $sidebar-index
  @media (max-width: $mobile-width)
    width: 100%
    top: -100%
    left: 0

  body.sidebar-open &
    transition-property: all
    transition-duration: .5s
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
    left: 0
    @media (max-width: $mobile-width)
      top: 0

.sidebar-nav__wrapper
  padding-bottom: 50px

.sidebar-nav__close-button
  position: absolute
  top: 10px
  right: 10px
  display: inline-block
  text-indent: -9999px
  width: 30px
  height: 30px
  background: transparent url('~images/icons/close-button.svg') no-repeat 50%
.sidebar-nav__header
  background-color: $sidebar-header-color
  text-align: center
  color: white
  display: flex
  flex-direction: column
  -webkit-flex-direction: column
  -ms-flex-direction: column
  -moz-flex-direction: column
  justify-content: center
  height: 200px
  h3
    margin: 10px 0
    font-weight: 500

.sidebar-nav__avatar--default
  width: 80px
  height: 80px
  margin: 0 auto
  border-radius: 500px
  background: transparent url('~images/avatars/avatar-default.jpg') no-repeat 50% 50%
  background-size: 80px
  object-fit: cover

.sidebar-nav__avatar
  img
    @extend .sidebar-nav__avatar--default
    background: none

.sidebar-nav__registration-links a
  display: inline-block
  color: white
  border: 2px solid white
  text-decoration: none
  padding: 2px 8px
  border-radius: 500px
  width: 95px
  &:hover
    border: 2px solid #e6e6e6
    color: #e6e6e6
  &.signup
    background-color: white
    color: $sidebar-header-color
    &:hover
      background-color: #e6e6e6


.sidebar-nav__main-links
  padding: 20px 0
  h4
    margin: 0 15px
    padding: 20px 0 10px
    position: relative
    font-size: calcRem(18px)
    @media (max-width: $mobile-width)
      padding: 15px 0
  .sidebar-nav__main-links-hover
    font-size: calcRem(14px)
    display: flex
    align-items: center
    &:last-child
      margin-bottom: 10px
    &:hover
      background-color: $sidebar-header-color
      padding-left: 10px
      a
        color: #fff
  .sidebar-nav__category-accordian-main
    max-height: inherit
    .sidebar-nav__main-links-hover
      font-size: calcRem(16px)
      font-weight: 500
  &-img
    width: 35px
    height: 35px
    margin: 8px 9px 8px 0
  a
    display: block
    padding: 12px 0
    text-decoration: none
    width: 100%

.sidebar-nav__category
  .arrow
    display: inline-block
    border-top: 6px solid #000
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    margin-left: 5px
    vertical-align: middle
    position: absolute
    right: 30px
    top: 26px

  &-accordian
    overflow: hidden
    transition: max-height 0.2s ease-out
    border-bottom: 1px solid $sidebar-header-nav-border-color
    margin: 0 15px
    @media (max-width: $mobile-width)
      max-height: 0
  &.admin
    background-color: $admin-background-color
    color: $petrescue-blue
    .sidebar-nav__main-links-hover
      &:hover
        background-color: #bfdbf3
      > a
        color: $petrescue-blue


.sidebar-nav__category .active
  .arrow
    transform: rotate(-180deg)

.sidebar-nav__social-icons
  display: flex
  justify-content: center
  -webkit-justify-content: center
  -moz-justify-content: center
  margin-top: 30px
  $size: 32px
  .icon
    border-bottom: 0
    text-indent: -9999px
    width: $size
    height: $size
    margin-right: 10px
    background-color: transparent
    background-repeat: no-repeat
    background-size: contain
    transition: background 500ms ease-in-out
  &:hover
    background-color: transparent
  .icon.twitter
    background-image: url("~images/global/twitter-icon-grey.svg")
  .icon.instagram
    background-image: url("~images/global/instagram-icon-grey.svg")
  .icon.facebook
    background-image: url("~images/global/facebook-icon-grey.svg")
  .icon.tiktok
    background-image: url("~images/global/tiktok-icon-grey.svg")
  @media (max-width: $mobile-width)
    margin-bottom: 60px
    margin-top: 15px
    .icon
      width: 30px
      height: 30px
      background-size: 30px

.sidebar-nav__donate-link
  padding: 30px 20px 30px
  .donate--button
    text-transform: uppercase
    width: 100%
