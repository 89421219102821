@import "sass/lib/variables"
@import "sass/lib/typography"

.c-menu-item 
  padding-top: 5px
  a 
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 10px 25px 15px
    width: 120px
    height: 100%
    text-align: center
    font-weight: 600
    line-height: normal
    border-radius: 10px 10px 0 0 
    color: $text-white
    text-decoration: none
    font-size: .8em
    @media (min-width: $mobile-width)
      width: 140px
    &:hover,
    &.current
      .c-icon
        background-color: $petrescue-green
    &:not(.current):hover 
      .c-icon
        @media (min-width: $tablet-width)
          transform: translateY(-5px)
    &.current 
      background: $body-background
      color: $text-dark-grey
    .c-icon 
      display: flex
      justify-content: center
      align-items: center
      margin: 0 auto 10px
      width: 50px
      height: 50px
      border-radius: 50%
      background: #4a4a4a
      transition: all ease-in-out 0.2s
      img 
        width: 28px

// Temp: hide icon on mobile
// To be updated with reworked mobile nav
.header--member__navigation.petrescue-admin
  .c-menu-item > a 
    width: 100px
    padding: 10px 15px 15px
    @media (min-width: $mobile-width)
      width: 120px
      padding: 10px 25px 15px
    > .c-icon
      display: none
      @media (min-width: $mobile-width)
        display: flex
    > span:not(.c-icon)
      min-height: 35px
      display: flex
      align-items: center
        
