.adopted_notification, .on_hold_notification
  h2 span
    color: $petrescue-green
  .subheading
    font-size: 1.1em
  .stage
    display: none
  .stage_one
    display: block
  .close-button
    margin: auto
    display: block
    text-align: center
  .button
    margin: 10px auto
    display: block
  &.singular
    .singular
      display: block
    .plural
      display: none
  &.plural
    .singular
      display: none
    .plural
      display: block
  .preview-message-container
    padding: 1em 0 2em 0

    .wrapper
      margin: 0
      padding: 0

    #preview-message-text
      padding: 10px
      background-color: $almost-white

      p
        font-size: 0.9em
        &:first-of-type
          margin-top: 0

    #preview-message-input
      height: 80px
      width: 100%
      padding: 1em
      font-size: 0.9em
      border-radius: 6px
      margin-right: 12%

    &--close
      width: 100%
      text-align: center
      background-color: $light-grey
      cursor: pointer

      &:after
        content: '\f077'
        font-family: FontAwesome

  .adopter_selectbox
    &.error
      color: $error-red

      select
        border: 1px solid $error-red
