.cards-listings-preview
  transition: 0.1s ease-out
  will-change: transform, box-shadow
  border-radius: 10px
  overflow: hidden
  padding: 2px
  margin: 7.5px
  flex-grow: 1
  &:hover
    transform: translateY(-4px)
    box-shadow: 0 0 8px rgba(0,0,0,0.15)
    background-color: rgba(0,0,0,0.07)
  &:active
    transform: translateY(0px)
  @supports (display: grid)
    margin: 0 // the default

  .c-favourite-btn
    top: 10px
    right: 10px
    position: absolute

  &__img-wrapper
    width: 100%
    position: relative

    .c-listing-status-banner
      .status-banner__reason
        font-size: .75rem

    .cards-listings-preview__img
      width: 100%
      height: 100%
      border-radius: 10px 10px 0 0
  .cards-listings-preview__img__bonded-besties
    position: absolute
    bottom: -30px
    right: 0
    width: 100px

  &__content
    margin-top: -4px
    padding: 15px
    text-align: left
    background-color: $white
    border-radius: 0 0 10px 10px
    box-shadow: 0 1px 0 0px rgba(0, 0, 0, 0.1)
    display: block
    text-decoration: none

    &__header
      h3
        margin: 0
        +truncate
        font-size: 30px
        text-decoration: none
        color: $petrescue-green
        font-family: $font-serif
        line-height: normal
        &:hover
          color: $petrescue-green

    &__section
      &__species
        font-weight: bold
        text-transform: capitalize
        font-size: 14px
        line-height: 29px
        display: block
        margin-bottom: 10px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        .fa-heart
          color: $petrescue-green

      &__location,
      &__interstate,
      &__adopted-at,
      &__public_created_at,
      &__bumped_at
        font-size: 14px
        font-weight: normal
        line-height: 27px
        display: block
        +truncate
        height: 27px

        &.admin-view
          padding-top: 0
          padding-bottom: 0

          &.first
            border-top-left-radius: 8px
            border-top-right-radius: 8px

          &.last
            border-bottom-left-radius: 8px
            border-bottom-right-radius: 8px

        i
          width: 15px
        span
          opacity: 0.2

      &__best-feature
        margin: 0 0 15px 0
        font-size: 17px
        line-height: 27px
        display: block
        color: $text-light-grey
        +truncate

      &__description
        margin: 1em 0
        height: 140px
        line-height: 20px
        text-align: left
        font-size: 14px
        overflow: hidden

        @media (max-width: $tablet-width)
          height: 110px
          line-height: 18px

      &__adopted
        font-size: 16px
        font-weight: bold
        text-align: center
        border-radius: 6px
        border: 2px dashed $almost-white
        padding: 10px
        background-color: #FBFBFB
        margin-top: 29px
        margin-bottom: 6px
        .fa-heart
          color: $petrescue-green

      &__adopted-at
        text-align: center
        opacity: 0.5

      &__footer
        .button
          width: 100%
          display: block
