.c-mega-nav__dropdown__list-states
  width: 160px
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.c-mega-nav__dropdown__list-state
  padding: 5px
  text-decoration: none
  background-color: #F1F1F1
  border-radius: 10px
  width: 62px
  display: inline-block
  text-align: center
  margin-bottom: 8px
  &:hover
    font-weight: bold
