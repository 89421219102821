@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

@mixin confetti-1-ratio($width: 118px, $height: 120px)
  $aspect-ratio: $height / $width
  width: $width
  height: $aspect-ratio * $width

@mixin confetti-2-ratio($width: 120px, $height: 150px)
  $aspect-ratio: $height / $width
  width: $width
  height: $aspect-ratio * $width

.c-search-listings-summary
  background-color: #f2f2f2
  padding: 0 30px

  &__inner
    font-size: calcRem(24px)
    font-weight: bold
    position: relative
    padding: 120px 194px 50px
    text-align: center
    line-height: 2
    max-width: 1470px
    margin: 0 auto

    @media (max-width: $tablet-width)
      padding: 120px 124px 70px

    @media (max-width: $mobile-width)
      padding: 10px 0 0 0

    &:before
      content: ""
      @include confetti-2-ratio(120px)
      background: url('~images/search/confetti-2.svg') no-repeat
      background-size: contain
      position: absolute
      bottom: -15px
      left: 5%
      @media (max-width: $tablet-width)
        @include confetti-2-ratio(100px)
      @media (max-width: $mobile-width)
        display: none

    &:after
      content: ""
      @include confetti-1-ratio(118px)
      background: url('~images/search/confetti-1.svg') no-repeat
      background-size: contain
      position: absolute
      top: 35px
      right: 0
      @media (max-width: $tablet-width)
        @include confetti-1-ratio(98px)
      @media (max-width: $mobile-width)
        display: none

    .c-select
      display: inline-block
      font-size: calcRem(20px)
      background-size: .55em auto
      // line height alignments mean that we have to apply a small transform to line up the select boxes correctly
      transform: translateY(-1px)
      margin: 4px 0

    .c-tag
      transform: translateY(-1px)
      margin: 4px 0
      line-height: 1.3
      font-size: 1.25rem
      border: 2px solid $dark-grey
      border-radius: 0.5em
      &:hover
        border-color: $text-black
      &.disabled
        border: none
      &__inner
        line-height: inherit


    // We enforce that the other species selector values are lowercase
    .other_species_select
      text-transform: lowercase
