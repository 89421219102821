/**
    Generates utility padding and margins classes.
 **/

$space-amounts: (0, 5, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 75, 90, 100) // Adjust this to include the pixel amounts you need.

$kinds: ("p": padding, "m": margin) // Leave this variable alone
$directions: (v, h) // Leave this variable alone
$lengths: (null, vw, vh) // Leave this variable alone

@each $space in $space-amounts
  @each $length in $lengths
    $length-amount: $length or "px"

    .m-#{$space}#{$length}
      margin: #{$space}#{$length-amount} 
      @each $side in (top, bottom, left, right)
        --util-margin-#{$side}: #{$space}#{$length-amount}

    .p-#{$space}#{$length}
      padding: #{$space}#{$length-amount}
      @each $side in (top, bottom, left, right)
        --util-padding-#{$side}: #{$space}#{$length-amount}

    .m-v-#{$space}#{$length}
      @each $side in (top, bottom)
        margin-#{$side}: #{$space}#{$length-amount}
        --util-margin-#{$side}: #{$space}#{$length-amount}

    .p-v-#{$space}#{$length}
      @each $side in (top, bottom)
        padding-#{$side}: #{$space}#{$length-amount}
        --util-padding-#{$side}: #{$space}#{$length-amount}

    .m-h-#{$space}#{$length}
      @each $side in (left, right)
        margin-#{$side}: #{$space}#{$length-amount}
        --util-margin-#{$side}: #{$space}#{$length-amount}
      
    .p-h-#{$space}#{$length}
      @each $side in (left, right)
        padding-#{$side}: #{$space}#{$length-amount}
        --util-padding-#{$side}: #{$space}#{$length-amount}

    @each $side in (top, bottom, left, right)
      .m-#{str-slice($side, 0, 1)}-#{$space}#{$length}
        margin-#{$side}: #{$space}#{$length-amount}
        --util-margin-#{$side}: #{$space}#{$length-amount}
      .p-#{str-slice($side, 0, 1)}-#{$space}#{$length}
        padding-#{$side}: #{$space}#{$length-amount}
        --util-padding-#{$side}: #{$space}#{$length-amount}

@each $space in $space-amounts
  .space-between-x-#{$space} > * + *
    margin-left: #{$space}px
    --util-margin-left: #{$space}px

  .space-between-y-#{$space} > * + *
    margin-top: #{$space}px
    --util-margin-top: #{$space}px

  .gap-#{$space}
    gap: #{$space}px

.margin-auto, .m-auto
  margin: auto

.m-l-auto
  margin-left: auto

.m-r-auto
  margin-right: auto

.margin-v-auto, .m-v-auto
  margin-top: auto
  margin-bottom: auto

.margin-h-auto, .m-h-auto
  margin-left: auto
  margin-right: auto
