@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-conversation-message
  display: flex
  align-items: flex-start
  &__details
    font-weight: bold
    &--sent-at
      color: $light-grey
  &__content
    background: $almost-white
    //TODO: border radius variable?
    border-radius: 10px
    padding: calcRem(15)
    line-height: 1.5
    word-break: break-word
