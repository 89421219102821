@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-listing-behind-doors
  &__link:hover
    .c-listing-behind-doors__name-tag
      transform: rotate(var(--name-tag-angle)) scale(0.9) !important

  &__content
    width: 100%
    border: solid $text-black 3px
    background-color: $white
    background-image: url(~images/donate/christmas_2022/christmas-advent-bg.png)
    background-repeat: no-repeat
    background-size: cover
    display: flex
    align-content: center
    justify-content: center
  &__img
    width: 80%
    height: auto
    left: 0
    object-fit: contain
    max-height: 110%
    transform: scale(0.6)
    transform-origin: left bottom
    animation-easing-function: cubic-bezier(.06,.63,0,1)
    animation-iteration-count: 1
    animation-fill-mode: forwards
    animation-delay: calc(var(--door-animation-delay, 2s) + 0.4s)
    animation-duration: 0.5s
    object-position: bottom left

  // Door
  &__door
    width: 50%
    height: 100%
    background: #EC3939
    border: 3px solid $text-black
    box-sizing: border-box
    animation-easing-function: cubic-bezier(.06,.63,0,1)
    animation-iteration-count: 1
    animation-fill-mode: forwards
    animation-delay: var(--door-animation-delay, 2s)
    animation-duration: var(--door-animation-speed, 2s)
    position: absolute
    top: 0
    @each $side in left, right      
      &--#{$side}
        #{$side}: 0

  &__name-tag
    position: absolute
    top: 0
    width: 100%
    max-width: 250px
    aspect-ratio: 8 / 5
    background-size: contain
    background-position: center
    background-image: url('~images/donate/christmas_2022/christmas-name-tag.svg')
    background-repeat: no-repeat
    transform-origin: 50% 0

    animation-iteration-count: 1
    animation-fill-mode: forwards
    animation-easing-function: cubic-bezier(0.34, 1.56, 0.64, 1)
    animation-delay: calc(var(--door-animation-delay, 2s) + 0.4s)
    animation-duration: var(--door-animation-speed, 2s)
    transform: rotate(var(--name-tag-angle))
    &--name
      text-align: center
      margin-top: 33%
      font-size: var(--name-size, 1.3rem)
      width: 100%
      font-weight: 900

  &--open
    .c-listing-behind-doors
      &__name-tag
        animation-name: sign-fall
      &__door
        @each $side in left, right      
          &--#{$side}
            animation-name: open-#{$side}
      
      &__img
        animation-name: pet-slide-in


  @keyframes open-left 
    from 
      transform: rotateY(0deg)
      transform-origin: 0% 50%
    to 
      transform: perspective(800px) rotateY(-105deg)
      transform-origin: 0% 50%
      box-shadow: 10px 0 5px rgba(0,0,0,0.1)

  @keyframes open-right 
    from 
      transform: rotateY(0deg)
      transform-origin: 100% 50%
    to
      transform: perspective(800px) rotateY(105deg)
      transform-origin: 100% 50%
      box-shadow: -10px 0 5px rgba(0,0,0,0.1)

  @keyframes sign-fall
    0%
      transform: rotate(15deg)
      top: 0
    10%
      top: 65%
      transform: rotate(-20deg) scale(0.97)
    15%
      top: 62%
    20%
      top: 65%
      transform: rotate(20deg) scale(0.94)
    30%
      transform: rotate(-15deg) scale(0.91)
    40%
      transform: rotate(15deg) scale(0.88)
    50%
      transform: rotate(-15deg) scale(0.85)
    60%
      transform: rotate(10deg) scale(0.82)
    70%
      transform: rotate(-10deg) scale(0.8)
    80%
      transform: rotate(5deg) scale(0.8)
    90%
      transform: rotate(-5deg) scale(0.8)
    100%
      top: 96%
      transform: scale(0.8) rotate(var(--name-tag-angle))

  @keyframes pet-slide-in 
    0%
      transform: scale(0.6)
    100%
      transform: scale(1.05)


.js-loading
  .c-listing-behind-doors__door--left,
  .c-listing-behind-doors__door--right,
  .c-listing-behind-doors__name-tag,
  .c-listing-behind-doors__img
    animation-play-state: paused !important
