body.admin
  .pr-expandable
    legend
      cursor: pointer

      &:after
        margin: 5px 10px 0 0
        font-family: FontAwesome
        float: right

    &.expanded
      legend
        &:after
          content: "\f068"

    &.collapsed
      legend
        &:after
          content: "\f067"
