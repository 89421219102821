@import "sass/lib/variables"
@import "sass/lib/typography"

.c-footer--external__links__section      
  h3
    color: $footer-text
    padding-bottom: 12px
    margin: 0 8px
    display: inline-block
    position: relative
    &:after 
      content: ''
      background: url('~images/global/squiggle.svg') no-repeat
      width: 140px
      height: 30px
      position: absolute
      bottom: -18px
      left: 0
      background-size: contain
