.external_link_warning
  p.last
    padding-bottom: 1.5em

  .link-preview
    color: $vivid-blue
    word-break: break-word

  .actions
    .button
      width: 100%
