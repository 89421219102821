.donation_check
  &__website
    display: flex
    align-items: center
    justify-content: center
    &:after
      content: ""
      display: inline-block
      background: url(~images/icons/external-link.svg) no-repeat
      background-size: contain
      width: 20px
      height: 20px
      margin-left: 9px
      flex-shrink: 0

