@import "sass/lib/variables"
@import "sass/lib/typography"

.c-tooltip
  display: inline-block
  position: relative

  &-icon
    color: white
    background-color: $petrescue-green
    border-radius: 50%
    padding: 2px 6px
    font-size: calcRem(12px)
    margin-left: 5px

  &-body
    opacity: 0
    margin-left: auto
    margin-right: auto
    left: 0
    right: 0
    margin-top: 15px
    position: absolute
    z-index: 1000
    background-color: $very-dark-grey
    border-radius: 5px
    padding: 10px
    font-weight: 500
    font-size: calcRem(13px)
    width: 250px
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28)
    transition: all 0.25s ease-out
    text-transform: initial
    color: $white

    &-point
      width: 15px
      height: 15px
      transform: rotate(45deg)
      background: $very-dark-grey
      position: absolute
      bottom: -7px
      left: 0
      right: 0
      margin-right: auto
      margin-left: auto

  .c-tooltip-body-show
    opacity: 1
