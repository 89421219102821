.group-statistics
    max-width: 764px
    background: #FFFFFF
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
    border-radius: 10px
    margin: 20px auto
    padding: 30px
    @media (max-width: $tablet-width)
        margin: 20px

    .heading
        font-weight: bold
        text-align: center
        margin: 0
    .sub_heading
        font-size: calcRem(12px)
        margin: 0
        text-align: center
    
    .statistics
        display: flex
        justify-content: space-around
        @media (max-width: $mobile-width)
            flex-direction: column
        .stat
            color: $petrescue-green
            font-weight: bold
            font-size: calcRem(12px)
            text-align: center
            margin: 0 6px
            line-height: calcRem(20px)
            p
                font-size: calcRem(12px)
                margin-bottom: 0
                text-align: center
            span
                font-size: calcRem(22px)