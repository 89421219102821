.c-campaign-banner
  .fathers_day
    .c-container-standard
      max-width: map-get($container-sizes, "thinner")
      .c-campaign-banner_inner
        justify-content: space-between
        .c-campaign-banner__text
          position: relative
          &:after
            height: 30px
            margin-left: 10px
            position: absolute
            width: 90px
            background-repeat: no-repeat
            background-image: url('~images/global/dotted-line-white.svg')
            @media (min-width: $tablet-width)
              content: ''
