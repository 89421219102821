form:not(.custom-style)
  .error-container
    margin: 20px
    padding: 0 20px 20px 40px
    color: $error-red
    border: 1px dashed $error-red
    ul
      list-style: disc
      font-style: italic
      list-style-position: inside
    li
      width: 100%
      margin: 3px
  legend:not(.custom-style, .c-form-legacy-fieldset-block legend, .label)
    width: 100%
    margin-bottom: 15px
    font-size: 1.4em
    font-weight: bold
  .no-legend legend
    font-size: inherit
    font-weight: normal
    margin-bottom: 0
  .choices
    legend
      font-size: inherit
      font-weight: normal
      margin-bottom: 0
  .select2
    margin-bottom: 15px
    &-selection__placeholder
      color: #7D7D7D !important
      font-size: 17px
      font-family: $font-sans
  li.boolean
    margin-bottom: 15px
    @media (max-width: $mobile-width)
      margin-bottom: 10px
  li.string .c-text-input
    width: 100%
  li.text .c-text-area
    width: 100%
  li.select_box .c-select
    width: 100%
    // We override the border colour because not all form elements have the same border style just yet
    // can remove this once text input components have been designed
    border-color: $text-black

  .password-show
    position: absolute
    right: 10px
    top: 38px
    font-weight: bold
    font-size: 14px
    cursor: pointer

  li.input
    width: 100%
    &.no-set-width
      width: auto

    &.w-third, .w-third
      width: 31%
      @media (max-width: $mobile-width)
        width: 100%
    &.half, .half
      width: 48%
      @media (max-width: $mobile-width)
        width: 100%

  .input.boolean label, .input.choice label
    display: flex
    .checker
      margin: 10px 10px 10px 0
  .input.boolean label, .choice label
    font-weight: 500
    @media (max-width: $mobile-width)
      font-size: 14px
  .actions
    margin: 10px auto 20px
    .action
      text-align: center
      input[type="submit"]
        min-width: 250px
        @media (max-width: $mobile-width)
          border-radius: 50px
          width: 100%
      a
        min-width: 250px
        margin-right: 15px
      @media (max-width: $mobile-width)
        width: 100%

  .column-form__fieldset
    width: 100%
    background-color: #F6F6F6
    border-radius: 6px
    padding: 30px
    margin-bottom: 35px

    &--admin
      background-color: $admin-background-color
      color: $admin-text-color
      border: 1px solid darken($admin-background-color, 10%)

    // The "& > li" is because we have some li's mistakenly nested inside divs due to formtastic formatting
    & > fieldset > ol > li, & > li
      list-style: none
      margin: 10px 0
      padding: 0

    .column-form__info-row
      margin-bottom: 20px
      & > p:last-child
        margin-bottom: 0

  .choices
    width: 100%
    .choices-group
      display: flex
      width: 100%
      flex-wrap: wrap
      .choice
        margin: 10px 10px 10px 0
        min-width: 80px
        .radio, .checker
          margin-right: 10px
        @media (max-width: $mobile-width)
          width: 100%
  p
    @media (max-width: $mobile-width)
      font-size: 13px
      margin: 0 0 10px 0
  .actions ol
    justify-content: center

#ui-datepicker-div
  background-color: #fff
  border: 1px solid #D7D9DB
  .ui-widget-header
    border: 0
    background: transparent
    .ui-datepicker-prev, .ui-datepicker-next, .ui-datepicker-next-hover, .ui-datepicker-prev-hover
      top: 6px
    .ui-datepicker-title select
      margin: 1px 1px
      padding: 5px
  .ui-state-default
    background: none
    background-color: #459e00
    border-color: #459e00
  .ui-state-hover
    background: #D7D9DB
    border: 0
    cursor: pointer

.fragments-group
  .fragment select
    width: 140px !important
  .fragment:first-child
    margin-right: 10px
  .select2
    margin-bottom: 15px

select
  -webkit-appearance: none
  border: 1px solid #ddd
  background-color: #fff
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.410765 0.910765C0.736202 0.585328 1.26384 0.585328 1.58928 0.910765L6.00002 5.32151L10.4108 0.910765C10.7362 0.585328 11.2638 0.585328 11.5893 0.910765C11.9147 1.2362 11.9147 1.76384 11.5893 2.08928L6.58928 7.08928C6.26384 7.41471 5.7362 7.41471 5.41076 7.08928L0.410765 2.08928C0.0853278 1.76384 0.0853278 1.2362 0.410765 0.910765Z' fill='%23171717'/%3E%3C/svg%3E%0A")
  background-repeat: no-repeat
  background-position: right .7em top 52%
  background-size: .7em auto

form .image
  .icon
    .photo
      display: inline-block
      a
        width: 100%
        text-align: center
        text-decoration: none
        color: red
    img
      display: block
      max-width: 200px
      max-height: 200px
      object-fit: cover
      margin: 7px
form .hint
  margin-top: 0
  margin-bottom: 25px
  display: block
  width: 100%
  font-style: italic
  font-size: calcRem(15px)
  line-height: 1.5
form .notice
  margin-top: 0
  margin-bottom: 25px
  width: 100%
  color: $admin-text-color
  font-style: italic
  font-size: calcRem(15px)
  line-height: calcRem(24px)

  a, &:hover, &:active, &::selection
    color: $admin-text-color
    background: transparent

form .hint-above
  @extend .hint
  margin: 5px 0 7px 0

.error
  color: $error-red !important
  p.inline-errors
    margin: 0 0 10px 0
    font-weight: bold
  &.text, &.stringish, &.boolean, &.select
    input, select, textarea
      // TODO: remove !important
      border: 1px solid $error-red !important
.error.text, .error.select, .error.string, .error.stringish, .error .label
  label
    color: $error-red
    position: relative

.justify-label-left label
  justify-content: left !important

.errors
  li
    margin: 10px 0 0 0
    font-weight: bold

.scroll-bar
  ::-webkit-scrollbar
    -webkit-appearance: none
    width: 7px
  ::-webkit-scrollbar-thumb
    border-radius: 4px
    background-color: rgba(0,0,0,.5)
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)

.code-of-ethics-box
  height: 250px
  overflow: scroll
  margin: 0 0 10px 0
  border: 1px solid $form-input-border-color
  border-radius: 3px
  padding: 0 15px
  background-color: #fff
  ul
    padding: 0 20px !important
    list-style: disc
    li
      margin: 3px !important

/**
  There is an error in node SASS which will strip ":not" selectors so we have to "flatten" the sass and do the below in order
  make sure this legacy code doesn't touch components.

  We can fix this when https://github.com/sass/node-sass/issues/2685 merges and we upgrade OR when all form elements get refactored
  into components
 **/

form:not(.custom-style) ol:not(.country-list, .component), form:not(.custom-style) ul:not(.country-list, .component)
  margin: 0
  padding: 0

ol:not(.country-list, .component) li, ul:not(.country-list, .component) li
  position: relative

form:not(.custom-style) .error input#{$negate-components-selector}
  margin-bottom: 5px !important

form:not(.custom-style) textarea#{$negate-components-selector}
  resize: none !important
  height: 150px
  overflow: scroll !important
  -webkit-overflow-scrolling: touch
  @media (max-width: $mobile-width)
    height: 120px !important

form:not(.custom-style) input[type=text]
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

form:not(.custom-style) input[type=checkbox]#{$negate-components-selector}
  margin-bottom: 0

form:not(.custom-style) select#{$negate-components-selector}
  padding: 7px 30px 7px 9px

form:not(.custom-style) input:disabled#{$negate-components-selector}, form:not(.custom-style) select:disabled#{$negate-components-selector}, form:not(.custom-style) textarea:disabled#{$negate-components-selector}, form:not(.custom-style) button:disabled#{$negate-components-selector}
  background-color: #f9f9f9
  border: 0
  color: #848484
  @media (max-width: $mobile-width)
    color: inherit

form:not(.custom-style) .input:not(.boolean) label#{$negate-components-selector}
  display: block
  margin-bottom: 3px
  display: flex
  align-items: center
  width: 100%

form:not(.custom-style) label#{$negate-components-selector}
  font-weight: bold

form:not(.custom-style) input:not([type=submit])#{$negate-components-selector}, form:not(.custom-style) select#{$negate-components-selector}, form:not(.custom-style) textarea#{$negate-components-selector}
  width: 100%
  border: 2px solid $form-input-border-color
  border-radius: 3px
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4)
  padding: 10px
  color: #333333
  margin-bottom: 15px
  font-size: 17px
  background-color: #fff
  @media (max-width: $mobile-width)
    margin-bottom: 10px
  &.input-lg
    font-size: calcRem(24px)

  &.w-third
    width: 31%
    @media (max-width: $mobile-width)
      width: 100%
  &.half
    width: 48%
    @media (max-width: $mobile-width)
      width: 100%

li.password.input > input[type="password"]
  padding-right: 55px !important
