.background-container
  background-color: $section-background-color
  padding: 20px 0
  overflow: auto

.wrapper
  max-width: 1470px
  padding: 0 30px
  margin: 40px auto
  color: $text-black

  @media (max-width: $mobile-width)
    padding: 0 25px
    margin: 20px auto
    p
      margin-left: 0
      margin-right: 0
      text-align: left

.wrapper-no-margins
  margin-top: 0
  margin-bottom: 0
  overflow: hidden

.wrapper-full-screen
  max-width: none

.wrapper-narrow
  max-width: 850px

#site-wrapper
  overflow-x: hidden

// hack fix for sticky bar
.listings-controller
  #site-wrapper
    overflow: initial
  

fieldset
  border: none
  margin: 0
  padding: 0

.title
  padding: 0 20px

  &.with-white-bg
    background-color: white
    margin: 0
    padding: 0.67em 20px

  @media (max-width: $mobile-width)
    font-size: 30px

[x-cloak]
  display: none !important
