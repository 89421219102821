@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"
  
.christmas-2022-__pet-hero-wrapper
  position: relative
  max-width: 520px
  margin: 0 auto 146px auto
  background: no-repeat center 0/contain url('~images/global/blob-5.svg')
  padding: 20px

  @media (max-width: $tablet-width)
    margin-bottom: 80px


  .christmas-2022__christmas-confetti
    --ratio: 1.873 
    --scale: 1

    --width: calc(87px * var(--scale))
    --height: calc(var(--width) * var(--ratio))

    position: absolute
    top: 0
    right: 20px
    width: var(--width)
    height: var(--height)
    background: no-repeat center 0/contain url('~images/donate/christmas_2022/christmas-confetti.svg')
    z-index: 3

    @media (max-width: $tablet-width)
      --scale: 0.7
    @media (max-width: $mobile-width)
      --scale: 0.6

  .christmas-2022__pet-img  
    position: relative
    margin: 0 auto
    max-width: 85%
    
    & > img
      z-index: 1
      max-width: 100%

    .christmas-2022__christmas-lights
      --bauble-size: 100px
      --bauble-right: 0
      --lights-right: 70px

      $bauble: no-repeat var(--bauble-right) 25px/var(--bauble-size) var(--bauble-url, url('~images/donate/christmas_2022/cat-bauble.svg'))
      $lights: no-repeat var(--lights-right) 0 url('~images/donate/christmas_2022/christmas-lights.svg')

      position: absolute
      top: calc(100% - 20px)
      left: calc(50% + 20px)
      transform: translateX(-50%)
      
      height: 146px
      width: 100%
      background: $bauble, $lights
      z-index: 2

      @media (max-width: $tablet-width)
        --bauble-size: 55px
        --bauble-right: 0px
        --lights-right: 40px