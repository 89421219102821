@import "sass/lib/variables"

$size: 115px

.c-icon-select
  display: inline-grid
  grid-template-rows: min-content auto
  grid-gap: 0.5em
  font-size: 1em
  color: black

  &--disabled
    color: $light-grey

  &--error
    color: $petrescue-red
    .c-icon-select__input__el
      & + .c-icon-select__control
        border-color: $petrescue-red
      &:checked + .c-icon-select__control
        border-color: darken($petrescue-red, 15%)

  &__input
    display: grid
    grid-template-areas: "icon-select"
    justify-content: center

    &__el, .c-icon-select__control
      grid-area: icon-select

    // The actual input element
    &__el
      cursor: pointer
      opacity: 0
      width: $size
      height: $size
      &:focus + .c-icon-select__control
        box-shadow: 0 0 0px 3px white, 0 0 0px 4px $petrescue-blue
      &:checked + .c-icon-select__control
        border-color: $dark-grey
      &:disabled + .c-icon-select__control
        opacity: 0.7
      @media (hover: hover) and (pointer: fine)
        &:hover + .c-icon-select__control
          background-color: lighten($very-light-grey, 8%)

  &__control
    cursor: pointer
    display: inline-grid
    align-items: center
    justify-items: center
    width: $size
    height: $size
    border-radius: 16px
    border: 2px solid transparent
    background-color: lighten($almost-white, 5%)
    // background is controlled by css vars
    background-image: var(--icon-select-icon)
    background-size: 80% auto
    background-position: center
    background-repeat: no-repeat
    backface-visibility: hidden

  &__label
    display: flex
    justify-content: center
