.cards-users-adopter-profile
  .row
    width: 100%
    display: flex

    .right
      padding-left: 1em
      flex-grow: 1
  &--avatar-img
    img
      border-radius: 50%
      width: 140px
      @media (max-width: $tablet-width)
        width: 95px
      @media (max-width: $mobile-width)
        width: 75px

  &--contact-name
    margin: 0.83em 0 0 0
    font-size: 1.5em
    font-weight: bold
    @media (max-width: $mobile-width)
      margin: 0
  &--email
    word-break: break-word
    @media (max-width: $mobile-width)
      margin-bottom: 10px
  &--address, &--family-list-prefix, &--family-list
    font-size: 0.9em
  &--family-list-prefix
    margin-top: 0.5em
    font-weight: bold
  &--family-list
    margin: 0

    .adults
      &:before
        content: '\f007'
        font-family: FontAwesome

    .child
      &:before
        content: '\f1ae'
        font-family: FontAwesome
    .pet
      &:before
        content: '\f1b0'
        font-family: FontAwesome

  &--view-profile
    @media (max-width: $mobile-width)
      margin: 0
