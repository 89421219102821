// This class has been removed from adopter_profile/edit.html.haml
// And may no longer be required.
.edit-account
  .pet_list, .child_list
    justify-content: left
    margin-bottom: 15px
    .pet, .child
      width: 140px
      position: relative
      margin: 5px 5px 5px 0
    .pet
      text-align: center
      display: inline-block
    .pet_img
      width: 100%
      height: 140px
      object-fit: cover
      border-radius: 100%
    .edit_modal, .add_pet_photo, .delete_pet
      font-size: calcRem(13px)
      margin-right: 6px
      margin-bottom: 4px
      display: inline-block
      @media (max-width: $mobile-width)
        margin-bottom: 8px
        display: block
    .pet_name
      position: absolute
      bottom: 53px
      background-color: $petrescue-green
      color: white
      font-weight: bold
      left: 0
      right: 0
      margin-bottom: 0
      overflow-wrap: break-word
      padding: 1px 3px 3px 3px
      @media (max-width: $mobile-width)
        bottom: 83px

  .child
    background-color: $petrescue-green
    color: #fff
    font-weight: bold
    width: 135px
    padding: 5px 8px
    border-radius: 3px
    position: relative
    margin: 5px 5px 0 0

    .delete_child
      position: absolute
      right: 5px
      top: 5px
      cursor: pointer
      color: #fff
      font-size: calcRem(15px)

  .open-pet-modal, .open-child-modal
    font-weight: normal !important
    margin: 0 0 20px 0

  .action
    margin-bottom: 10px
    input[type="submit"], button[type="submit"]
      min-width: 300px
      margin: 0 auto
      margin-top: 10px
      @media (max-width: $mobile-width)
        width: 100%

  .cancel-button
    color: $error-red
    width: 100%
    text-align: center
    margin-top: 15px

// TODO: add proper pet component and get rid of this 
.c-form-legacy-field-block
  .pet
    width: 140px
    position: relative
    margin: 5px 5px 5px 0
  .pet
    text-align: center
    display: inline-block
  .pet_img
    width: 100%
    height: 140px
    object-fit: cover
    border-radius: 100%
  .edit_modal, .add_pet_photo, .delete_pet
    font-size: calcRem(13px)
    margin-right: 6px
    margin-bottom: 4px
    display: inline-block
    @media (max-width: $mobile-width)
      margin-bottom: 8px
      display: block
  .pet_name
    position: absolute
    bottom: 53px
    background-color: $petrescue-green
    color: white
    font-weight: bold
    left: 0
    right: 0
    margin-bottom: 0
    overflow-wrap: break-word
    padding: 1px 3px 3px 3px
    @media (max-width: $mobile-width)
      bottom: 83px
