@use "sass:math"
@import "sass/lib/utilities"

@mixin truncate
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

@mixin text-selection($color: $text-selection-green)
  &::selection
    background-color: $color

@function calcRem($size)
  $remSize: math.div(strip-unit($size), 16)
  @return #{$remSize}rem

@function calcEm($size, $base)
  $remSize: math.div(strip-unit($size), 16)
  @return #{$remSize}em
