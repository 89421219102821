.admin-table
  .column_name
    flex-flow: row
    justify-content: space-around

  ul
    margin: 0

    li
      display: flex
      flex-flow: column
      border-bottom: 1px solid #eee
      padding: 5px 0
      @media (max-width: $mobile-width)
        padding: 15px 0

      .overview
        display: flex
        justify-content: space-between
        align-items: center
        @media (max-width: $mobile-width)
          flex-direction: column

          span
            margin-bottom: 10px

        .status_button
          background-color: #eee
          font-weight: bold
          min-width: 100px
          text-align: center
          padding: 5px
          text-transform: uppercase

        .actions
          min-width: 145px
          @media (max-width: $mobile-width)
            width: 100%

          button
            margin: 0 !important
            min-width: 145px

      .details
        background-color: #f6f6f6
        margin-bottom: 10px
        padding: 5px
        margin: 5px 0
        .reason
          margin: 5px 0 15px 5px
        td
          word-break: break-word
          min-width: 100px
        .key
          word-break: inherit

