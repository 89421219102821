$border: #cccbca
$activeborder: darken($border,5%)

/**
  Todo:
  This file implements styles which override "uniform" - a library that we have previously used to create custom input styles with JS. Since
  we are replacing the checkboxes and radios with native versions via components, we will be able remove all of this once that hads been
  completed. For now thought, we have to add hacks like appending #{$negate-components-selector} so that we can maintain the correct style cascade.
 **/
.checker:not(.no-uniform)#{$negate-components-selector}, li.choice:not(.no-uniform)#{$negate-components-selector} .radio:not(.no-uniform)#{$negate-components-selector}
  span
    display: block
  span input
    margin-bottom: 0 !important
    display: block
    height: 24px !important
    width: 24px !important
    padding: 0 !important
    -webkit-appearance: none
    position: relative
    border: 1px solid $dark-grey
    background-color: #fff
    border-radius: 50%
  span [type="checkbox"]:checked:after
    content: "\f00c"
    font-family: FontAwesome
    position: absolute
    top: 0
    right: 0
    font-size: 20px
  span [type="radio"]:checked
    border: 5px solid #fff
    background-color: $dark-grey
    outline: none

.radio:not(.no-uniform) .checked
  border: 1px solid $dark-grey
  border-radius: 50%

.checker.hover:not(.no-uniform)
  border-color: $activeborder
  input
    cursor: pointer

.checker.disabled:not(.no-uniform)
  opacity: 0.75
