@import "sass/lib/variables"

.c-line-across
  background-repeat: no-repeat
  background-position: center
  background-size: contain

  &--curly
    width: 129px
    height: 32px
    background-image: url("~images/global/dotted-line-across-curly.svg")

  &--straight
    width: 129px
    height: 32px
    background-image: url("~images/global/dotted-line-across-curly.svg")

  &--heart
    width: 116px
    height: 32px
    background-image: url("~images/global/dotted-line-across-heart.svg")
