@import "sass/lib/variables"

.c-text-input
  display: block
  font-size: 1em
  font-family: $font-sans
  font-weight: 500
  color: $text-black
  line-height: 1.15
  padding: $form-input-padding
  max-width: 100% // useful when width is set to anything other than 100%
  box-sizing: border-box
  margin: 0
  border: 2px solid $very-dark-grey
  border-radius: $form-input-radius
  appearance: none
  background-color: #fff

   // Disabled styles *
  &:disabled, &[aria-disabled=true]
    color: graytext
    border-color: $light-grey

  &:invalid
    color: $dark-grey

  @media (hover: hover) and (pointer: fine)
    &:hover
      border-color: $text-black
    &:disabled:hover
      border-color: $light-grey

  &:focus
    border-color: $text-black
    // It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7)
    box-shadow: 0 0 0 3px -moz-mac-focusring
    color: #222
    outline: none

  &--error
    border-color: $petrescue-red
