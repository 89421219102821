@import "sass/lib/variables"
@import "sass/lib/typography"

.c-donation-amount
  &__wrapper
    position: relative
    height: 100%
  
  &__radio-input
    position: absolute
    opacity: 0

  &__radio-input:focus + &__selection
    border: 2px dashed $text-black

  &__radio-input:checked + &__selection
    background: $petrescue-green
    color: $white
    &:hover 
      border-color: transparent

  &__selection
    height: 100%
    border: 2px solid transparent
    cursor: pointer
    display: grid
    align-items: center

    @media (min-width: $mobile-small-width)
      grid-template-columns: min(100px) auto

    &:hover
      border: 2px dashed $text-black
      box-shadow: none

    &--with-input
      position: relative
      &:before
        @media (min-width: $mobile-width)
          content: ''
          background-image: url('~images/global/dotted-line-down-curly.svg')
          background-repeat: no-repeat
          background-size: contain
          background-position: center
          width: 86px
          height: 86px
          display: block
          position: absolute
          top: -10px
          left: -85px
          transform: rotate(-15deg)


    &--selection-input
      // Note: unfortunately !important is required to override default
      // Should probably clean up that
      margin-bottom: 0 !important
      padding-left: 20px !important
      border-color: $text-light-grey !important
      box-shadow: none !important

  .custom_amount--input-wrapper
    position: relative
    display: flex
    align-items: center
    &:before 
      content: '$'
      transform: translateY(1px)
      font-family: $font-serif
      font-size: calcRem(24)
      font-weight: bold
      position: absolute
      left: 7px
      color: $text-black

  &__value 
    font-size: calcRem(32)
    font-family: $font-serif
    font-weight: bold
  &--with-input
    &__heading
      margin-bottom: 10px
