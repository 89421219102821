body.charter-index
  overflow: hidden

  .petrescue_charter
    iframe
      border: none
      width: 100%
      height: calc(100vh - 106px)
  #footer
    display: none
