@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-donations-hero-section
    $one-column-width: $tablet-large-width

    display: flex
    justify-content: center
    align-items: center

    @media (max-width: $one-column-width)
      flex-direction: column
      text-align: center

    &__hero-img
      flex: 1 1 100%
      @media (max-width: $tablet-small-width)
        max-width: 70% 
      @media (max-width: $one-column-width)
        max-width: 60% 

    &__hero-content
      margin-left: 40px
      @media (max-width: $one-column-width)
        margin-left: 0

    &__heading
      &--above
        display: none
        @media (max-width: $one-column-width)
          display: block
        & > h1 // overriding the defaults
          text-align: center
      
      &--below
        display: block
        @media (max-width: $one-column-width)
          display: none
        & > h1 // overriding the defaults
          text-align: left