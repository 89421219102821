@import "sass/lib/variables"
@import "sass/lib/typography"

.c-counter
  font-family: $font-serif
  width: fit-content

  &-digit
    background-color: #E7E7E7
    border-radius: 4px
    margin: 8px
    padding: 8px 16px
  &-comma
    color: #B1B1B1

