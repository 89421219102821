@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"
  
.c-dog-trainer-promo-banner
  background: no-repeat bottom left url('~images/global/wavy-hill-background.svg') #68B845
  @media (min-width: $tablet-width)
    background-size: 100%

  @media (max-width: $tablet-width)
    &__content, &__cta
      font-size: calcRem(14px)
      --util-font-size: calcRem(14px)

    &__cta.c-outline-button
      padding: calcRem(12px) calcRem(12px)