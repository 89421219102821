.enquiry-message
  display: flex
  justify-content: space-between
  @media (max-width: $mobile-width)
    flex-direction: column
  h2
    margin-top: 0 0 10px
    color: $petrescue-green
    @media (max-width: $mobile-width)
      margin: 0
      font-size: 18px
  .column_left
    width: 30%
    display: inline-block
    @media (max-width: $mobile-width)
      width: 100%
    .from_detail
      margin-bottom: 15px
    p
      margin: 0
      @media (max-width: $mobile-width)
        font-size: 15px
  .column_right
    width: 65%
    display: inline-block
    padding-bottom: 50px
    @media (max-width: $mobile-width)
      width: 100%
      padding: 0
      h2
        margin-top: 20px
      p
        font-size: 15px

    .float-left
      float: left
  form
    margin: 0
    position: absolute
    width: 100%
    input
      display: inline-block
      max-width: 350px
    .button
      width: auto
      margin: 0 0 0 10px
    @media (max-width: $mobile-width)
      position: inherit
      .button
        width: 100%
        margin: 20px 0 0 0
