@import "sass/lib/variables"
@import "sass/lib/utilities"
.c-card-entry
  & > * + * // adds y axis spacing
    margin-top: 15px

  .c-card-entry__row
    display: flex
    & > *
      flex-grow: 1
    & > * + *
      margin-left: 15px
