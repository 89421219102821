@mixin high-contrast-image($image)
  background: $image no-repeat center center
  background-size: cover

@mixin clearfix
  &::after
    display: block
    content: ""
    clear: both

@mixin font-awesome($content, $size)
  &::after
    content: "#{$content}"
    font-size: calcRem($size)
    font-family: FontAwesome
