// PetRescue Brand Colours
$petrescue-green:                     #5FA503
$petrescue-orange:                    #FDA705
$petrescue-red:                       #E7131A
$petrescue-yellow:                    #eac435
$petrescue-blue:                      #005da5
$petrescue-purple:                    #c27bed

$petrescue-green-light:               #63AC44

// PetRescue greys
$pr-grey-dark:                        #616971 // WCAG 2 AA Compliant

$white:                               #FFFFFF
$almost-white:                        #EFEFEF
$very-light-grey:                     #DEDEDE
$light-grey:                          #CCCCCC
$grey:                                #919191
$dark-grey:                           #888888
$very-dark-grey:                      #333333
$darkest:                             #2C2C2C

// General
$group-member-background-color:       #eaf3fb
$group-member-text-color:             #00223d
$admin-background-color:              #eaf3fb
$admin-border-color:                  #b2d6f3
$admin-text-color:                    #00223d
$section-background-color:            #f2f2f2

// Typography & Links
$text-light-grey:                     #959595
$text-grey:                           #767676
$text-dark-grey:                      #515151
$text-black:                          #0B0B0B
$text-white:                          $white
$text-selection-green:                rgba(95, 165, 3, 0.5)
$text-selction-dark:                  #b1b1b1
$text-selction-light:                 #e2e2e2
$text-warning-red:                    #FF7171

// Pagination
$pagination-page-link:                $pr-grey-dark
$pagination-page-link-hover:          darken($pagination-page-link,10%)
$pagination-page-link-focus:          $pagination-page-link-hover
$pagination-page-link-active:         #407ec3

// Containers
$container-border-radius:             4px
$bordered-container-border:           $very-light-grey
$raised-container-shadow:             rgba(198,199,184,0.25) // #c6c7b8 at 25%

// Footer
$footer-text:                         #D8D8D8

// Nav bar
$nav-text-color:                      #494949

// Sidebar Navigation
$sidebar-width:                       340px
$sidebar-header-color:                #2C2C2C
$sidebar-header-nav-border-color:     #D3D3D3

// Tables
$table-border:                        $very-light-grey
$table-heading:                       $pr-grey-dark

// Forms
$form-input-border-color:             #000000
$form-input-radius:                   3px
$form-input-padding:                  10px
$form-input-height:                   44px
$error-red:                           $petrescue-red
$border-grey:                         $light-grey
$focus-blue:                          #76B4FA

//adoption poster
$brochuretext:                        $text-black

// MEDIA QUERIES
$mobile-small-width:                  350px
$mobile-width:                        484px
$tablet-small-width:                  600px
$tablet-width:                        800px
$tablet-large-width:                  900px
$laptop-width:                        1015px
$laptop-large-width:                  1200px

// BASE COLOURS
$bright-blue:                         #45B9E7
$vivid-blue:                          #0076A8

// SHADES
$facebook-blue:                       #3579EA
$twitter-blue:                        #66B8E3
$google-red:                          #C24637
$linkedin-blue:                       #0073b1

$share-email-grey:                    $light-grey
$box-shaodw:                          0px 7px 15px -6px rgba(0, 0, 0, 0.32)

// HEADER COLOURS
$nav-donate-bg:                       $petrescue-green
$nav-signup-bg:                       $pr-grey-dark
$nav-hover-bg:                        $almost-white

// SEARCH
$searchbar-grey:                      $almost-white
$filter-border:                       $light-grey
$filter-active-bg:                    $very-light-grey
$reset-blue:                          $vivid-blue
$listing-box-grey:                    $very-light-grey

// LAYOUT
$body-background:                     #fcfcfc
$footer-background:                   #2C2C2C
$box-shadow-grey:                     $light-grey

// FONTS
$font-sans:                           'Inter', sans-serif
$font-serif:                          'Source Serif Pro', serif
$font-serif-light:                    'SourceSerifPro-Light', serif

// IMAGE LINKS
$homepage-banner-1:                   url('~images/homepage/homepage-banners/banner-1.jpg')
$homepage-banner-2:                   url('~images/homepage/homepage-banners/banner-2.jpg')
$homepage-banner-3:                   url('~images/homepage/homepage-banners/banner-3.jpg')
$homepage-banner-4:                   url('~images/homepage/homepage-banners/banner-4.jpg')
$homepage-banner-5:                   url('~images/homepage/homepage-banners/banner-5.jpg')
$homepage-banner-6:                   url('~images/homepage/homepage-banners/banner-6.jpg')
$homepage-banner-7:                   url('~images/homepage/homepage-banners/banner-7.jpg')
$homepage-banner-8:                   url('~images/homepage/homepage-banners/banner-8.jpg')
$homepage-banner-9:                   url('~images/homepage/homepage-banners/banner-9.jpg')
$homepage-banner-10:                   url('~images/homepage/homepage-banners/banner-10.jpg')
$homepage-banner-11:                   url('~images/homepage/homepage-banners/banner-11.jpg')
$homepage-banner-12:                   url('~images/homepage/homepage-banners/banner-12.jpg')
$homepage-banner-13:                   url('~images/homepage/homepage-banners/banner-13.jpg')
$homepage-banner-14:                   url('~images/homepage/homepage-banners/banner-14.jpg')
$homepage-banner-15:                   url('~images/homepage/homepage-banners/banner-15.jpg')
$homepage-banner-16:                   url('~images/homepage/homepage-banners/banner-16.jpg')
$blog-banner:                         url('~images/homepage/homepage-banners/blog-banner.jpg')

$pr-logo:                             url('~images/global/logo.svg')

$pet-link-icons:                      url('~images/homepage/pet-link-icons.png')

$linkedin-icon:                       url('~images/global/linkedin-icon.svg')
$linkedin-icon-hover:                 url('~images/global/linkedin-icon-green.svg')
$facebook-icon:                       url('~images/global/facebook-icon.svg')
$facebook-icon-hover:                 url('~images/global/facebook-icon-green.svg')
$instagram-icon:                      url('~images/global/instagram-icon.svg')
$instagram-icon-hover:                url('~images/global/instagram-icon-green.svg')
$twitter-icon:                        url('~images/global/twitter-icon.svg')
$twitter-icon-hover:                  url('~images/global/twitter-icon-green.svg')
$tiktok-icon:                        url('~images/global/tiktok-icon.svg')
$tiktok-icon-hover:                  url('~images/global/tiktok-icon-green.svg')

$plus-icon:                           url('~images/icons/filter-expand.svg')
$minus-icon:                          url('~images/icons/filter-collapse.svg')

// Z-INDEX
$notices-index:                       999999999999
$modal-index:                         99999999999
$footer-pop-index:                    9999999999 // this is put on top for the chrissy appeal
$beacon-index:                        999999999
$fuse-sticky-ad-index:                99999999
$santa-index:                         1000000
$main-nav-dropdown-index:             9999

$skip-main-active-index:              999
$confetti-index:                      101
$loading-overlay-index:               100
$loader-index:                        99
$sidebar-index:                       7
$main-nav-index:                      6
$search-select-index:                 5
$favourite-button-index:              4
$listing-status-banner-index:         4
$member-alert-banner-1:               3
$listing-status-banner-bg-index:      3
$member-alert-banner-2:               2
$blogpost-admin-options-index:        2
$member-alert-banner-3:               1
$skip-main-index:                     -999

// Component selectors
$component-element-selector: '.component, [class^="c-"], [class^="js-c-"]'
$negate-components-selector: ':not(.component):not([class^="c-"]):not([class^="js-c-"])'

// WIP; definining variable names and sizes
// Containers
$container-footer:                      1470px

$container-padding:                     15px

/** Container widths
  *
  * TODO: can adjust this v1.0 sizes and add additionals.
  * This is a starting point.
  **/
$container-sizes: (tiny: 560px, thinner: 700px, thin: 950px, standard: 1080px, wide: 1470px, full: 100% )

/** Type scale
  *
  * TODO: can adjust this v1.0 sizes and add additionals.
  * This is a starting point.
  **/
$type-scale: (xs: 0.79rem, sm: 0.889rem, base: 1rem, lg: 1.125rem, xl: 1.266rem, '2xl': 1.424rem, '3xl': 1.602rem, '4xl': 1.802rem, '5xl': 2.027rem, '6xl': 2.281rem)

// Colours map
$colours: (verylightgrey: $almost-white, lightgrey: $very-light-grey, grey: $grey, darkgrey: $dark-grey, verydarkgrey: $very-dark-grey, darkest: $darkest, black: $text-black, white: $white, pr-green: $petrescue-green, green: $petrescue-green) // Adjust this to include the colours you need.

 
// Grid/Flex Spacing
$gap-sizes: ( sm: 10px, md: 30px, lg: 50px, xl: 100px )
