.block
  display: block

.inline-block
  display: inline-block

.display_none, .hidden
  display: none !important

.invisible-on-load
  visibility: hidden

.js-hidden, .hidden-on-load
  display: none

.overflow-hidden
  overflow: hidden

.object-cover
  object-fit: cover

.object-contain
  object-fit: contain

.show_mobile
  display: none !important
  @media (max-width: $mobile-width)
    display: block !important
.hide_mobile
  @media (max-width: $mobile-width)
    display: none
.show_tablet_small
  display: none
  @media (max-width: $tablet-small-width)
    display: block
.hide_tablet_small
  @media (max-width: $tablet-small-width)
    display: none
.show_tablet
  display: none
  @media (max-width: $tablet-width)
    display: block
.hide_tablet
  @media (max-width: $tablet-width)
    display: none
