@import "sass/lib/variables"
@import "sass/lib/typography"

.c-footer-popup
  display: none
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: $footer-pop-index
  max-height: 100vh
  overflow: auto

  &--bg
    background-color: rgba(54,54,54,0.9)

  &--open
    display: block

  &__close
    background-color: rgba(54,54,54,0.9)
    padding: 5px
    width: 150px
    border-radius: 4px 4px 0 0
    color: #fff
    border: none
    cursor: pointer
    &:before, &:after
      content: none
    &:hover
      background-color: darken($very-dark-grey, 10%)
    @media (max-width: $mobile-width)
      right: 35px

  &__inner
    

  &__content
    position: relative
    padding: 25px 0
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

    @media (min-width: $tablet-width)
      grid-gap: 30px
      flex-direction: row

    .petrescue-logo
      position: absolute
      top: 15px
      left: -15px
      width: 80px
      @media (max-width: $tablet-width)
        position: relative
        left: 0
        top: 0
        margin: 0 auto
        display: block

    .main_text
      display: inline-block
      font-weight: bold
      color: #fff
      vertical-align: middle
      font-size: calcRem(20px)
      @media (max-width: $tablet-width)
        font-size: calcRem(18px)
        width: 100%

      & > span
        font-weight: 400
        font-size: calcRem(16px)
        display: block
        margin-top: 10px

    .actions
      .button, .donate--button
        outline: none
        margin-bottom: 5px
        &.later, &.dismiss
          color: #C7C7C7
          background-color: transparent
          border: none
          width: 100%
          font-size: calcRem(14px)
          font-weight: normal

          &:hover
            text-decoration: underline
      label
        color: #fff

    .email_text
      margin: 0 0 10px 0
      text-align: center
