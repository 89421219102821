@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-attachments-list
  // doing this means we don't have to hide the div if there are no attachments
  & > *:first-child
    margin-top: 20px
  & > * + *
    margin-top: 12px

.c-attachment
  display: flex
  padding-left: 15px // keep in line with the rounded part of the textbox
  word-break: break-word

  &__remove-btn
    border-radius: 100%
    font-size: calcRem(13)
    color: $dark-grey
    padding: 3px 4px
    line-height: 1
    background-color: transparent
    cursor: pointer
    transition: 0.2s ease-out
    border: none
    text-decoration: none
    align-self: center
    border: 1px solid transparent
    &:focus
      border: 1px solid $light-grey

  // Note that this preview element can be both a div with text in it (in the case of an arbitary file)
  // OR an image which will be shown from memory using the browsers file api - hence the inclusion of the
  // "object-" styles to support both cases with the same class
  &__preview
    display: flex
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    background-color: $light-grey
    font-weight: 800
    font-size: calcRem(12)
    border-radius: 5px
    overflow: hidden
    object-fit: cover
    object-position: center
    margin-left: 8px

  &__details
    line-height: 1.3
    margin: 4px 0 0 11px // visually align with attachment preview
