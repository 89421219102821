@import "sass/lib/variables"
@import "sass/lib/typography"

.c-form-legacy-fieldset-block
  margin-bottom: 40px
  padding: 30px
  background-color: #F6F6F6

  legend
    float: left
    width: 100%
    display: block
    font-weight: 700
    margin-bottom: 25px
    font-size: map-get($type-scale, "2xl")

  .c-form-legacy-field-block
    margin-left: -16px
    margin-right: -16px
