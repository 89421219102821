#shout_button
  background-color: $petrescue-purple
  border: 1px solid $petrescue-purple
  &:hover
    background-color: darken($petrescue-purple, 10%)

#shout_button.group_donation
  max-width: 216px
  border-radius: 20px
  text-transform: uppercase
  font-size: calcRem(14px)
  display: block
  margin: 0 auto
.rescue-group__header .group_donation
  position: absolute
  right: 25px
  @media (max-width: $mobile-width)
    position: relative
    right: 0

p.shout_hint
  margin: 0 auto
  text-align: center
  font-style: italic
  font-size: 0.9em
  @media (max-width: $tablet-width)
    margin-bottom: 8px

.listing_donation
  width: 60%
  @media (max-width: $tablet-width)
    width: 100%
  #shout_button.button
    padding: 8px 15px
    width: 100%

