@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-donate-button
  --button-padding: 8px 20px
  --button-color: #{$petrescue-yellow}
  --button-color-accent: #{darken($petrescue-yellow, 15%)}

  padding: var(--button-padding)
  border-radius: 7px
  border: none
  display: inline-block
  font-weight: bold
  font-size: calcRem(16)
  text-transform: uppercase
  text-decoration: none
  text-align: center
  transition: 0.1s ease-out

  background-color: var(--button-color)
  border-color: var(--button-color)
  color: $very-dark-grey
  box-shadow: 0 2px 0 0px var(--button-color-accent)

  &--christmas
    position: relative
    &:before
      content: ''
      background-image: url('~images/global/santa-button-hat.webp')
      background-size: contain
      position: absolute
      left: -12px
      top: -10px
      height: 32px
      width: 32px
      filter: drop-shadow(0 2px 1px rgb(0 0 0 / .25))

  &:hover
    box-shadow: 0 4px 0 0px var(--button-color-accent)
    transform: translateY(-2px)

  &.c-donate-button-inherit-style
    background-color: transparent
    border-color: transparent
    box-shadow: none
    text-transform: initial
    font-weight: inherit
    font-size: inherit
    text-align: inherit
    border-radius: inherit

    &:hover
      transform: inherit
