@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"
  
.c-login-modal
  text-align: center
  &_h2 
    color: $text-black
    @media (min-width: $tablet-width)
      margin-top: 0
  .login-form 
    max-width: 300px
    margin: auto
    .input_action,
    .input_action .button
      width: 100%
  .login-page-break 
    margin: 0
    p
      display: inline-block
  .registration__signup-options
    .button--social
      display: inline-flex
      border-radius: 0
      padding: 10px
      margin: 5px
      .icon
        margin: 0
  .login-signup
    &:before 
      content: none
        
