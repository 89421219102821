@import "sass/lib/variables"

.c-our-work-workmap
  &-image
    width: 100%
    border-radius: 20px
  &-link
    position: relative
    margin: 64px auto 52px
    display: block
    width: fit-content
    @media (max-width: $mobile-width)
      width: 90%
  .button
    position: absolute
    bottom: -18px
    left: 0
    right: 0
    width: fit-content
    margin: 0 auto

