@import "sass/lib/variables"

$size: 1.5em

.c-checkbox
  --checkbox-size: 24px
  --checkbox-radius: 3px
  --checkbox-gap: 0.5em
  
  &--lg
    --checkbox-size: 36px
    --checkbox-radius: 5px
    --checkbox-gap: 1em

  &--sml
    --checkbox-size: 20px
    --checkbox-radius: 3px
    --checkbox-gap: 0.5em
  
  &--no-text
    --checkbox-gap: 0

  display: grid
  grid-template-columns: min-content auto
  font-size: 1em
  line-height: 1.5
  color: $text-black

  &--disabled
    color: $dark-grey

  &--error
    color: $petrescue-red

  &__input
    display: grid
    grid-template-areas: "checkbox"
    margin-right: var(--checkbox-gap)

    &__el, .c-checkbox__control
      grid-area: checkbox
      width: var(--checkbox-size)
      height: var(--checkbox-size)

    // The actual input element
    &__el
      opacity: 0
      &:focus + .c-checkbox__control
        box-shadow: 0 0 0px 3px white, 0 0 0px 4px $petrescue-blue
      &:checked + .c-checkbox__control .c-checkbox__control__check
        opacity: 1
      &:disabled + .c-checkbox__control
        color: $dark-grey



  &__control
    display: inline-grid
    border-radius: var(--checkbox-radius)
    border: 0.16em solid currentColor
    background-color: white
    &__check
      transform: scale(0.7)
      opacity: 0
      &__mark
        stroke-width: 0.26em

  &__label
    display: flex
    align-items: center
