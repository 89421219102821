@import "sass/lib/variables"
@import "sass/lib/typography"

.c-footer--external__section
  .footer--external__links
    display: grid
    grid-template-columns: 1fr
    grid-gap: 20px
    margin-bottom: 30px
    @media (min-width: $tablet-small-width)
      grid-template-columns: repeat(2, 1fr)
    @media (min-width: $tablet-width)
      grid-template-columns: repeat(3, 1fr)
    @media (min-width: $laptop-width)
      grid-template-columns: repeat(5, 1fr)

    a
      text-decoration: none
      display: block
      width: 100%
      padding: 5px 8px
      font-size: 18px

  &.copyright
    @media (min-width: $tablet-width)
      display: flex
      justify-content: space-between
      align-items: flex-end

    .footer--external__copyright
      order: 0
    .footer--external__social-icons
      order: 1
