@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-basic-button
  --button-text-color: white
  --button-primary-color: #{$petrescue-green}
  --button-accent-color: #{darken($petrescue-green, 15%)}

  --button-shadow: 0 2px 0 0px var(--button-accent-color)
  --button-shadow-on-hover: 0 4px 0 0px var(--button-accent-color)

  display: inline-flex
  justify-content: center
  align-items: center

  text-align: center
  padding: calcRem(14) calcRem(30)
  color: var(--button-text-color)
  background-color: var(--button-primary-color)
  font-family: $font-sans
  font-weight: bold
  font-size: calcRem(16)
  cursor: pointer
  border-radius: calcRem(10)
  transition: 0.1s ease-out
  box-shadow: var(--button-shadow)

  // reset styles
  border: none
  text-decoration: none

  // &--green 
  // This is the natural colour so needs no alterations

  &--verylightgrey
    --button-primary-color: #{$almost-white}
    --button-accent-color: #{$dark-grey}
    --button-text-color: #{$text-black}

  &--white
    --button-primary-color: #{$white}
    --button-accent-color: rgba(0,0,0,0.25)
    --button-text-color: #{$very-dark-grey}

  &--grey
    --button-primary-color: #{$very-dark-grey}
    --button-accent-color: #{darken($very-dark-grey, 15%)}

  &--lightgrey
    --button-primary-color: #{$light-grey}
    --button-accent-color: #{darken($light-grey, 10%)}
    --button-text-color: #{$text-black}

  &--yellow
    --button-primary-color: #{$petrescue-yellow}
    --button-accent-color: #{darken($petrescue-yellow, 10%)}
    --button-text-color: #{$text-black}

  &--stripped
    --button-primary-color: transparent
    --button-accent-color: transparent
    --button-text-color: #{$text-black}

  &--red
    --button-primary-color: #{$petrescue-red}
    --button-accent-color: #{darken($petrescue-red, 10%)}
    --button-text-color: #{$text-white}

  &--sm
    padding: calcRem(10) calcRem(26)
    font-size: calcRem(14)

  &--xs
    padding: calcRem(5) calcRem(10)
    font-size: calcRem(12)

  &:hover, &:focus
    box-shadow: var(--button-shadow-on-hover)
    transform: translateY(-2px)
