//Group sign up form
.new-rescue-group
  .wrapper
    max-width: 700px

// Groups#index => /rescue_directory
.rescue-group .wrapper
  @media (max-width: $mobile-width)
    margin: 15px 0 0
  .admin-edit
    .actions
      background-color: #f6f6f6
      padding: 20px
      margin-bottom: 20px
      display: flex
      justify-content: space-evenly
      flex-wrap: wrap
      @media (max-width: $mobile-width)
        flex-direction: column
      a
        width: 125px
        margin: 3px 0
        padding: 8px 0
        @media (max-width: $mobile-width)
          width: 100%
          margin-bottom: 15px

///// HEADER //////

.rescue-group__header
  text-align: center
  max-width: 1470px
  margin: 0 auto
  position: relative

  &__name
    line-height: 1.1
    margin: 0
    color: $petrescue-green

  &__avatar
    height: 170px
    object-fit: contain
    margin-bottom: 15px
    @media (max-width: $mobile-width)
      height: 120px
      margin-top: 10px

  .rescue-group__header__details p
    margin: 5px 0

  &__active-in, &__id, &__status
    margin: 0
    font-size: calcRem(20px)
    font-family: $font-serif
    color: $dark-grey
    font-weight: bold
    span
      color: $very-dark-grey
    @media (max-width: $mobile-width)
      order: 1
      margin: 15px 0

  &__checks
    font-size: calcRem(12px)
    color: $dark-grey
    margin: 15px 0
    font-weight: bold
    &-vet, &-abn
      margin: 0 11px
    icon
      color: $very-dark-grey

.rescue-group__data
  width: 100%
  background-color: #F3F3F3
  margin-bottom: 10px
  @media (max-width: $mobile-width)
    flex-direction: column


.rescue-group__data__listings
  display: flex
  flex-wrap: wrap
  justify-content: center
  max-width: 900px
  width: 100%
  padding: 25px 0
  margin: 0 auto
  position: relative
  &-pets
    position: relative
    width: 166px
    min-height: 164px
    font-size: calcRem(14px)
    background-color: $white
    border-radius: 20px
    box-shadow: 0px 4px 4px 0px #0000001A
    text-decoration: none
    padding: 17px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 7px
    text-align: center
    &:hover
      transform: translateY(-4px)
    @media (max-width: $mobile-width)
      min-height: 124px
      width: 110px
      font-size: calcRem(11px)
      padding: 15px

    &--icon
      width: 70%
      @media (max-width: $mobile-width)
        width: 80%

    .value
      font-weight: bold
      display: block
      margin-top: 8px
    .count
      color: $petrescue-green
      font-size: calcRem(24px)
      font-weight: bold
      display: block
      margin: 17px 0 7px
    .icon
      font-size: calcRem(26px)
  &-pets-inactive
    .value, .rescue-group__data__listings-pets--icon, .count, .icon
      opacity: 0.3
.group_rating_wrapper
  display: flex
  flex-direction: column
.group_rating
  margin: 15px auto 0
  margin-bottom: 15px
  @media (max-width: $mobile-width)
    width: 180px
.rescue-group__header__rating, .group_rating
  color: #FFC107
  font-weight: bold
  font-size: 18px
  i
    color: #FFC107
    font-size: 18px
  h3
    text-align: center
    margin: 0
    font-size: 22px
  .checker
    display: none
  input
    overflow: hidden
    position: absolute !important
    clip: rect(1px 1px 1px 1px)
    clip: rect(1px, 1px, 1px, 1px)
    opacity: 0

  label
    position: relative
    float: right
    color: #C8C8C8
    &:before
      margin: 5px
      content: "\f005"
      font-family: FontAwesome
      font-size: 1.5em

  .checked ~ label:before
    color: #FFC107

  label:hover ~ label:before
    color: #ffdb70

  label:hover:before
    color: #FFC107


///// MAIN CONTENT //////

.rescue-group__content
  display: flex
  justify-content: space-between
  @media (max-width: 650px)
    display: block
  .column
    width: 48%
    position: relative
    @media (max-width: 650px)
      width: 100%
    .rescue-group__about__section
      overflow: hidden
      position: relative
      max-height: 660px
      padding-bottom: 22px
      margin-bottom: 20px
      &.open
        max-height: max-content

    .group-address
      margin-bottom: 10px
    .group-address-map
        width: 100%

  .h2h-link
    position: relative
    width: max-content
    margin: 0 auto

    .h2h-link-logo
      margin-bottom: 65px
      display: block

    .h2h-link-poweredBy
      position: absolute
      bottom: 90px
      right: 0px

    .h2h-icon
      width: 30px
      height: 30px

.rescue-group__section-title
  color: $petrescue-green
  font-size: 1.6em
  line-height: 30px

.rescue-group__social-link
  a
    overflow: hidden
    display: inline-block
    width: calc(100% - 52px)
    text-overflow: ellipsis

.rescue-group__contact-card
  background: $white
  border-radius: 10px
  box-shadow: 0px 4px 4px 0px #0000001A
  padding: 20px

.rescue-group__contact-details, .rescue-group__referees-table
  font-size: calcRem(12px)
  word-wrap: break-word
  width: 100%
  margin-top: 10px
  border-collapse: collapse
  td
    padding: 7px
  .label
    text-transform: uppercase

.rescue-group__contact-details
  td:first-child
    width: 115px
    padding-left: 42px
  td:last-child
    word-break: break-word

.rescue-group__referees
  width: 100%
  margin: 10px 0
  .rescue-group__section-title
    margin: 0

.rescue-group__social-link
  margin: 10px 0
  a
    color: $petrescue-green
    vertical-align: middle
  i
    font-size: 23px
    margin-right: 8px
    width: 31px
    vertical-align: middle
    padding: 4px 6px
    color: $white
    border-radius: 3px

  .fa-facebook
    background-color: $facebook-blue
  .fa-twitter
    background-color: $twitter-blue
  .fa-globe
    background-color: $pr-grey-dark
  .fa-instagram
    background-image: linear-gradient(-135deg,#1400c8,#b900b4,#f50000)
  .fa-phone, .fa-user
    color: #0B0B0B

.rescue-group__adoption_process
  background-color: #f2f2f2
  padding: 25px

  .rescue-group__section-title
    color: $text-black

  .wrapper
    position: relative
    max-height: 395px
    overflow: hidden
    margin: 0 auto
    padding-bottom: 15px
    &.open
        max-height: max-content

  .c-expand-text
    background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(242, 242, 242,0.9206057422969187) 61%, #f2f2f2 85%, #f2f2f2 100%)

.rescue-group__pets-preview
  .wrapper
    margin: 0 auto
  &__header
    text-align: center
    padding-top: 30px
    margin: 0 20px
  .no-records-found
    h3
      text-align: center
      font-weight: 500
      font-size: 14px
      padding: 15px
      border: 1px solid #ddd
      margin: 0
      display: block

.rescue-group__pets-preview__pagination
  background-color: transparent
  margin: 25px 0
  form
    display: flex
    align-items: flex-end
    justify-content: center
    @media (max-width: $mobile-width)
      flex-direction: column
    .submit
      width: auto
      margin: 5px 5px
      @media (max-width: $mobile-width)
        width: 100%
  .pagination
    justify-content: space-between
    .info
      display: inline-block
    @media (max-width: 650px)
      display: block
      .info
        margin-bottom: 15px
