.adopted_slideshow, .slides_container
  display: flex
  justify-content: center
  flex-direction: column

  .slides
    position: absolute
    img
      width: 100%
      height: 100%
    &-details
      position: absolute
      top: 10px
      .name, .group, .adopted_at
        background-color: $petrescue-green
        padding: 10px 22px
        margin: 10px 0
        color: #fff
        width: fit-content
      .name
        font-size: calcRem(50px)
      .group
        font-size: calcRem(20px)
        max-width: 300px
        line-height: inherit
      .adopted_at
        font-size: calcRem(20px)
        line-height: inherit

.pet_loader
  text-align: center
  .image
    width: 100px
    height: 98px
    margin: 0 auto
    background-image: url('~images/icons/pet_loader_sprite.png')
    background-size: 950px
    animation: play 9s steps(8) infinite

@keyframes play
  from
    background-position: left
  to
    background-position: right
