@import "sass/lib/variables"
@import "sass/lib/typography"

.c-text-detail-block

  &--admin
    --text-detail-block-title-colour: #{$admin-text-color}
    --text-detail-block-detail-colour: #{$admin-text-color}

  &__title
    color: var(--text-detail-block-title-colour, $petrescue-green)
    text-transform: uppercase
    font-weight: 700
    font-size: calcRem(12)
    margin: 0

  &__detail
    color: var(--text-detail-block-detail-colour, $text-grey)
    font-size: calcRem(18)
    overflow-wrap: break-word
    margin: 0

    // TODO: this is a bit of a hack overriding global styling, would be better for this
    // to be on a link component
    & > a
      color: var(--text-detail-block-detail-colour, $text-grey)
