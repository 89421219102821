.blog-posts-header
  height: 280px
  background: #ccc url('~images/homepage/homepage-banners/blog-banner-2.jpg') 50% 50%
  background-size: cover
  background-repeat: no-repeat
  margin-bottom: 20px
  @media (max-width: $mobile-width)
    height: 240px
  h1
    margin: 0
    padding-top: 130px
    color: #fff
    @media (max-width: $mobile-width)
      font-size: 24px
      padding-top: 100px

.blog_post_collection
  .search__filters
    background-color: $body-background

    .c-search__term__select
      display: none
      @media (max-width: $mobile-width)
        display: block

  &__results-container
    width: 75%
    @media (max-width: $mobile-width)
      width: 100%

    .search-results
      display: flex

  .blog_post
    display: flex
    width: 100%
    box-shadow: 0 1px 0 0px rgba(0,0,0,0.1)
    margin-bottom: 15px
    border-radius: 10px
    background-color: #fff
    overflow: hidden
    @media (max-width: 715px)
      flex-direction: column
      -webkit-flex-direction: column
      -moz-flex-direction: column
      -ms-flex-direction: column
      height: auto
    .blog_post_image
      @media (max-width: $tablet-width)
        height: auto
      img
        width: 210px
        object-fit: cover
        height: 100%
        @media (max-width: 715px)
          width: 100%
          height: auto
    .blog_post_details
      padding: 20px
      display: flex
      flex-direction: column
      width: 80%
      h3
        margin: 0 0 5px 0 !important
        max-width: 560px
        width: 100%
        overflow: hidden
        text-overflow: ellipsis
        font-size: 28px
        line-height: 30px
        @media (max-width: $mobile-width)
          white-space: pre-wrap
        a
          color: $petrescue-green
          text-decoration: none
          &:hover
            text-decoration: underline
            color: $text-black
      p.excerpt
        overflow: hidden
        margin: 0
        max-height: 55px
      
      .article--button
        width: 170px
        margin-top: auto

      @media (max-width: 715px)
        width: 100%
        height: auto
        padding: 20px
        .excerpt,
        a.article--button
          position: inherit
          width: 100%
          margin: 20px 0 0 0

//impact library
.impact-page
  .impact_blogs_container
    display: flex
    justify-content: space-around
    flex-wrap: wrap
    .blog_post.impact
      width: 31%
      flex-direction: column
      height: auto
      @media (max-width: 760px)
        width: 49%
      @media (max-width: $mobile-width)
        width: 100%
      .blog_post_image
        img
          height: 210px
          width: 100%
      .blog_post_details
        width: 100%
        @media (max-width: $mobile-width)
          height: auto
        p.excerpt
          max-height: none
          min-height: 200px
          @media (max-width: $mobile-width)
            min-height: none
        .button
          width: 88%
