.rescue-directory

  overflow: hidden
  background: $section-background-color

  .search-results
    display: flex

  &--map_container
    position: relative
    display: flex
    padding: 20px 30px
    @media (max-width: $tablet-width)
      display: block

  .pr-map-container
    flex-grow: 1
    @media (max-width: $tablet-width)
      margin-top: 30px

  #map
    height: 800px
    max-height: 95vh
    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.3)
    border-radius: 6px
    background-image: url('~images/rescue-directory/map-default.png')
    background-color: transparent
    background-position: center center
    background-size: cover
    background-repeat: no-repeat
    position: relative

  #map.uninitialized
    cursor: pointer

  #map.uninitialized::after
    content: "Click to see rescue directory map"
    display: block
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-weight: bold
    font-size: calcRem(26)
    color: white
    text-align: center
    text-shadow: 0px 0px 2px rgba(0,0,0,0.3)
    padding: 20

  #map.uninitialized::before
    content: ""
    display: block
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.4)
    border-radius: 6px

  .map-filters
    min-width: 250px
    margin-right: 30px

    @media (max-width: $mobile-width)
      position: initial
      width: inherit

    .filter-box
      .sub-list
        padding: 10px 0px 10px 23px
        background: $plus-icon no-repeat 95% 50%
        background-size: 12px
        &.expanded
          background: $minus-icon no-repeat 95% 13px
          background-size: 12px
        &:hover
          background-color: transparent !important

        ul
          margin-left: 10px
          margin-top: 16px

      form
        margin: 0 10px
        .search_location
          min-width: 100%

  #map-loader-background
    background-color: rgba(0,0,0,0.5)
    top: 0
    right: 0
    bottom: 0
    left: 0
    position: fixed
    color: #fff
    z-index: $loader-index
    @media (max-width: $mobile-width)
      position: absolute
    #map-loader
      position: absolute
      color: #fff
      margin: auto
      left: 0
      right: 0
      top: 0
      bottom: 0
      width: 30px
      height: 30px
    .fa-spinner
      margin-left: 13px
      font-size: 30px
    p
      margin-top: 0

  &__map-link
    color: $petrescue-green
    display: block
    text-align: center
    margin-bottom: 20px
    font-weight: bold

  .search__filters
    @media (max-width: 775px)
      background-color: #fcfcfc
    form
      .button
        @media (max-width: $mobile-width)
          margin-bottom: 10px

    &__button-options
      label
        font-weight: bold
        @media (max-width: $mobile-width)
          margin-right: 10px
      .button--white
        font-size: 14px
        padding: 5px 8px
        width: 54px
        margin-bottom: 0
        border-radius: 0 !important
        @media (max-width: $mobile-width)
          width: 80px !important
          margin-bottom: 10px

      .active
        background-color: #333
        border-color: #333
        color: #fff

  .pagination
    @media (max-width: 650px)
      display: block
      margin-top: 5px
      .info
        display: block
        margin-bottom: 15px
    @media (max-width: $mobile-width)
      background-color: #F1F2F2
      padding: 5px

.rescue-directory__groups-list
  .no-results
    text-align: center
    padding: 20px
    width: 100%

.rescue-directory__group
  display: flex
  -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.2)
  -moz-box-shadow: 0 0 8px rgba(0,0,0,0.2)
  box-shadow: 0 0 8px rgba(0,0,0,0.2)
  margin-bottom: 20px
  min-height: 120px
  width: 100%
  @media (max-width: $tablet-width)
    flex-direction: column
    height: auto

  &__image
    width: 120px
    @media (max-width: $tablet-width)
      width: 100%
    img
      width: 120px
      height: 120px
      object-fit: contain
      @media (max-width: $tablet-width)
        width: 100%

  &__info
    padding: 20px
    display: flex
    justify-content: space-between
    -webkit-justify-content: space-between
    -moz-justify-content: space-between
    -ms-justify-content: space-between
    align-items: center
    width: 100%
    @media (max-width: $mobile-width)
      flex-direction: column

  &__section
    display: flex
    align-items: center
    @media (max-width: $mobile-width)
      flex-direction: column

    &.left
      flex-direction: column
      align-items: baseline
      width: 50%
      @media (max-width: $tablet-width)
        width: 40%
      @media (max-width: $mobile-width)
        width: 100%
    &.right
      justify-content: flex-end
      -webkit-justify-content: flex-end
      -moz-justify-content: flex-end
      -ms-justify-content: flex-end
      width: 50%
      @media (max-width: $tablet-width)
        width: 60%
      @media (max-width: $mobile-width)
        width: 100%

  &__name
    margin: 0

  &__states
    margin-top: 3px
    span
      font-weight: bold

  &__listings
    width: calc(100% - 111px)
    max-width: 350px
    display: flex
    -webkit-justify-content: space-around
    -moz-justify-content: space-around
    -ms-justify-content: space-around
    flex-wrap: wrap
    @media (max-width: $mobile-width)
      margin: 20px 0
      width: 100%

    .listings_count
      span
        padding: 10px
        font-size: 14px
        border: 1px dashed $border-grey
        float: left
        border-radius: 4px 0 0 4px
        &.value
          border-left: none
          padding: 10px
          font-weight: bold
          border-radius: 0 4px 4px 0
        &.type
          border-radius: 4px 0 0 4px

  .button
    margin: 0
    padding: 5px 8px
