.admin-tools-donations
  .container
    position: relative
    +clearfix

  &--statistics
    margin-left: 5%
    width: 50%
    float: left

    table
      width: 100%
      background-color: $white

      th
        &.table-heading
          background-color: $petrescue-green
          color: $white
          text-align: center

        &.blank
          background-color: $very-light-grey

      th, td
        padding-left: 8px
        text-align: left
        border: 1px solid $very-light-grey

  &--filters-container
    margin-right: 5%
    width: 30%
    float: right

  &--chart
    position: relative

    #donation_chart
      margin: 0 auto
      width: 90%
      min-height: 400px

    .prev-link, .next-link
      top: 37%
      font-size: 2em
      text-decoration: none
      text-wrap: nowrap
      overflow: hidden
      position: absolute

      &.disabled
        opacity: 0.5

    .prev-link
      left: 15px
    .next-link
      right: 15px
