@import "sass/lib/variables"

$size: 1.5em

.c-radio
  display: grid
  grid-template-columns: min-content auto
  grid-gap: 0.5em
  font-size: 1em
  color: black

  &--disabled
    color: $dark-grey

  &--error
    color: $petrescue-red

  &__input
    display: grid
    grid-template-areas: "radio"

    &__el, .c-radio__control
      grid-area: radio

    // The actual input element
    &__el
      cursor: pointer
      opacity: 0
      width: $size
      height: $size
      &:focus + .c-radio__control
        box-shadow: 0 0 0px 3px white, 0 0 0px 4px $petrescue-blue
      &:checked + .c-radio__control .c-radio__control__check
        opacity: 1
      &:disabled + .c-radio__control
        color: $dark-grey

      @media (hover: hover) and (pointer: fine)
        &:hover + .c-radio__control .c-radio__control__check
          opacity: 1
          background-color: $light-grey
        &:hover:checked + .c-radio__control .c-radio__control__check
          opacity: 1
          background-color: currentColor // to override the background colour

  &__control
    cursor: pointer
    display: inline-grid
    align-items: center
    justify-items: center
    width: $size
    height: $size
    border-radius: 50%
    border: 0.16em solid currentColor
    background-color: white

    &__check
      display: inline-block
      // calc is required becuase we don't use dart sass and node-sass doesn't support css min/max fns
      // When we transition to dart sass - the calc can be removed
      width: calc(max(12px, #{$size - 0.8}))
      height: calc(max(12px, #{$size - 0.8}))
      background-color: currentColor
      border-radius: 50%
      opacity: 0

  &__label
    display: flex
    align-items: center
    padding-top: 1px // for vertical text alignment
