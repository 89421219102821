.conversations--index
  .white-container
    @media (max-width: $mobile-width)
      padding: 0 !important
  .filters-sidebar
    @media (max-width: $mobile-width)
      display: block
  .conversation-wrapper
    width: 100%
    margin-bottom: 15px
    .no-conversations
      text-align: center
      font-size: 2em
    .conversation--content
      border: 1px solid $almost-white
      &.listings-enquiry
        &.unread
          background-color: #e6f0d8