@import "sass/lib/variables"
@import "sass/lib/typography"

.footer--external__social-icons
  margin: 1.3rem 0
  display: flex
  align-items: center
  $size: 20px
  @media (min-width: $tablet-width)
    $size: 30px
  a
    text-indent: -9999px
    width: $size
    height: $size
    margin-right: 10px
    background: $very-light-grey
    background-size: $size $size
    transition: background 400ms ease-in-out
    &:last-child
      margin-right: 0
    &:hover,
    &:focus
      background: $petrescue-green

  $icons: "linkedin" $linkedin-icon, "facebook" $facebook-icon, "instagram" $instagram-icon, "twitter" $twitter-icon, "tiktok" $tiktok-icon

  @each $social, $icon in $icons
    a.c-#{$social}
      mask-image: $icon
