.c-radio-box
  position: relative
  &:has(input:checked)::after
    display: flex
    content: "✔︎"
    justify-content: center
    align-items: center
    width: 20px
    height: 20px
    border-radius: 9999999px
    position: absolute
    top: -10px
    right: 10px
    color: white
    background-color: #262626
    font-size: 10px
