@import "sass/lib/typography"

.c-listing-breadcrumb
  font-size: calcRem(13px)
  max-width: 1470px
  padding: 0 50px
  margin: 30px auto

  &__crumb
    text-decoration: none
    font-style: italic
    &:hover
      text-decoration: underline
