// See footer component for additonal styles.

.footer--external
  background-color: $footer-background
  padding-top: 50px

  p
    color: $footer-text
  a
    color: $footer-text
    &:hover
      color: $footer-background
      background-color: $petrescue-green
      text-decoration: none
  > .wrapper
    margin: 0 auto
    // TODO: address pages overriding wrapper width, so we can remove !important
    max-width: $container-footer !important
