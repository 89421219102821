.c-collapsible-text-block
  &--with-max-height
    &__content
      position: relative
      max-height: var(--text-content-height, auto)

      &:has(+ label input[name="read_more"]:checked)
        --text-content-height: auto
        padding-bottom: 20px

      &:before
        content: ""
        position: absolute
        bottom: 0
        left: 0
        right: 0
        height: 20px
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))

    label
      span:before
        content: "Read More"
      &:has(input[name="read_more"]:checked) span:before
        content: "Read Less"
