.unslider
  overflow: auto
  margin: 0
  padding: 0
  position: relative

.unslider-wrap
  position: relative

  &.unslider-carousel > li
    float: left

.unslider-vertical
  > ul
    height: 100%

  li
    float: none
    width: 100%

.unslider-fade
  position: relative

  .unslider-wrap li
    position: absolute
    left: 0
    top: 0
    right: 0
    z-index: 8

    &.unslider-active
      z-index: 10

.unslider
  li, ol, ul
    margin: 0

.unslider-arrow
  position: absolute
  left: 20px
  top: 50%
  z-index: 9
  cursor: pointer
  overflow: hidden
  background: no-repeat 50% 50%
  background-image: url("~images/icons/slider-arrow.png")
  text-indent: -999em
  transition: opacity .2s
  width: 30px
  height: 30px
  background-color: #000
  border-radius: 50%
  opacity: 0.8
  @media (max-width: $mobile-width)
    display: none

  &.next
    left: auto
    right: 20px
  &.prev
    transform: rotate(-180deg)

.listing-slider
  height: 100%
  visibility: hidden
  touch-action: pan-y pinch-zoom !important
  video
    width: 100%
    @media (max-width: $mobile-width)
      height: 320px
.unslider-nav
  display: none
