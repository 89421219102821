@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-modal
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0,0,0,.75)
  z-index: $modal-index
  display: none

  &.js-c-modal-transitioning, &.js-c-modal-visible
    display: block
    opacity: 0

  &__inner
    padding: 50px 0
    width: 100%
    height: 100%
    position: absolute
    display: flex
    margin: auto
    overflow-y: auto

  &__content
    position: relative
    padding: clamp(15px, 5vw, 50px)
    width: 100%
    background-color: white
    height: fit-content
    margin: 0 auto
    // TODO: ?? Border radius vairable: $border-radius-lg
    border-radius: 20px

    // TODO: remove these styles and use a component
    h2
      color: $text-black
      @media (min-width: $tablet-width)
        margin-top: 0

  &__close
    position: absolute
    right: 20px
    top: 20px
    border: none
    height: 30px
    width: 30px
    color: #fff
    background-color: $very-dark-grey
    transition: all 0.2s ease-in-out
    border-radius: 100%
    cursor: pointer
    &:after, &:before
      display: block
      content: " "
      position: absolute
      top: 5.3px
      left: 13.5px
      width: 3px
      height: 20px
      border-radius: 3px
      background: #fff
      transition: background 0.2s ease-in-out
    &:after
      transform: rotate(45deg)
    &:before
      transform: rotate(-45deg)
    &:hover
      background-color: $dark-grey

  &__scroll
    height: 330px
    overflow: scroll
    a
      color: $petrescue-green !important
    ul
      text-indent: 0
      padding: 0
      font-size: 12px
      border: 1px solid #dadddf
      li
        display: block
        margin: 0
        padding: 8px 10px
        border-top: 1px solid #dadddf
      li:first-child
        border: 0

    ::-webkit-scrollbar
      -webkit-appearance: none
      width: 7px
    ::-webkit-scrollbar-thumb
      border-radius: 4px
      background-color: rgba(0,0,0,.5)
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)
