@import "sass/lib/variables"
@import "sass/lib/typography"

.c-avatar
  overflow: hidden
  border-radius: 100%
  width: var(--avatar-size, 50px)
  height: var(--avatar-size, 50px)
  display: flex
  justify-content: center
  align-items: center
  background-color: $very-light-grey

  &--img
    object-fit: cover

  &--text
    font-weight: 800
    color: black
    font-size: calcRem(16)
