@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-loading-overlay
  align-items: center
  background: $very-dark-grey
  bottom: 0
  display: flex
  flex-direction: column
  justify-content: center
  left: 0
  opacity: 0.75
  position: absolute
  right: 0
  top: 0
  z-index: $loading-overlay-index

  &.blackout
    opacity: 1

  &.rounded
    border-radius: 10px

  p
    color: white
    font-weight: bold

  &--sk_circle
    width: 80px
    height: 80px
    position: relative

    .sk_child
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0

      &:before
        content: ''
        display: block
        margin: 0 auto
        width: 15%
        height: 15%
        background-color: white
        border-radius: 100%
        animation: c-loading-overlay--BounceDelay 1.2s infinite ease-in-out both

      &--2
        transform: rotate(30deg)
        &:before
          animation-delay: -1.1s
      &--3
        transform: rotate(60deg)
        &:before
          animation-delay: -1s
      &--4
        transform: rotate(90deg)
        &:before
          animation-delay: -0.9s
      &--5
        transform: rotate(120deg)
        &:before
          animation-delay: -0.8s
      &--6
        transform: rotate(150deg)
        &:before
          animation-delay: -0.7s
      &--7
        transform: rotate(180deg)
        &:before
          animation-delay: -0.6s
      &--8
        transform: rotate(210deg)
        &:before
          animation-delay: -0.5s
      &--9
        transform: rotate(240deg)
        &:before
          animation-delay: -0.4s
      &--10
        transform: rotate(270deg)
        &:before
          animation-delay: -0.3s
      &--11
        transform: rotate(300deg)
        &:before
          animation-delay: -0.2s
      &--12
        transform: rotate(330deg)
        &:before
          animation-delay: -0.1s

  @keyframes c-loading-overlay--BounceDelay
    0%, 80%, 100%
      transform: scale(0)
    40%
      transform: scale(1)
