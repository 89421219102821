@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"

.c-adoption-poster
  display: block
  text-transform: uppercase
  font-size: calcRem(12px)
  padding: 12px 18px
  font-weight: 600
  margin-bottom: 0
  vertical-align: middle
  margin-bottom: 10px
  background-color: $pr-grey-dark
  border-radius: 8px
  color: white
  text-decoration: none
  margin-left: 8px
  border: 0
  cursor: pointer
  @media (max-width: $tablet-width)
    margin: 0 auto
    display: block
  &:hover
    background-color: darken($pr-grey-dark, 10%)
  .fa-print
    font-size: calcRem(16px)
    margin-right: 4px
    vertical-align: text-bottom
