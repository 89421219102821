@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

.c-christmas-gifting-banner
  $horizontal-spacing: calcRem(26)

  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  padding: 15px 15px
  position: relative
  background-color: $very-dark-grey
  color: white

  @media (min-width: $tablet-small-width)
    padding: 20px 15px

  & &__cta // extra & set to increase specificity
    text-transform: uppercase
    font-size: calcRem(13)
    margin-left: $horizontal-spacing

  &__gift-icon
    display: none
    color: white
    width: 50px
    height: 48px
    transform: translateY(-1px) // fix visual weight
    flex-shrink: 0
    margin-right: $horizontal-spacing

    @media (min-width: $mobile-width)
      display: block

  &__text
    font-family: $font-serif
    vertical-align: middle
    position: relative
    line-height: 1
    font-size: calcRem(20px)
    margin-top: 0
    margin-bottom: 0

    @media (min-width: $tablet-small-width)
      font-size: calcRem(22px)
