@import "sass/lib/variables"

// TODO: replace with $colours map 
$text-colours: (verylightgrey: $almost-white, lightgrey: $very-light-grey, grey: $grey, darkgrey: $dark-grey, verydarkgrey: $very-dark-grey, black: $text-black, white: white,green: $petrescue-green) // Adjust this to include the colours you need.

$textAlignments: ("left", "right", "center", "revert")

$line-heights: (xs: 0.8, sm: 1, md: 1.3, lg: 1.5)


// Note that to avoid specificity issues on some component styles, we also provide a utility css variable
// that assigns the name of the utility so that in components. This way you can set default values in
// components without worrying about specificity issues


@each $name, $colour in $text-colours
  #{'.text-' + $name}
    color: $colour

@each $name, $rem in $type-scale
  .text-#{$name}
    --util-font-size: #{$rem}
    font-size: $rem



@each $textAlign in $textAlignments
  .text-#{$textAlign}
    text-align: #{$textAlign}

.serif
  font-family: $font-serif

@each $name, $line-height in $line-heights
  .leading-#{$name}
    line-height: $line-height

.leading-sml
  --util-line-height: 1.3
  line-height: 1.3

.normal
  font-weight: normal

.semibold
  font-weight: 600
.bold
  font-weight: bold

.italic
  font-style: italic

.semi-bold
  font-weight: 500

.caps
  text-transform: capitalize

.uppercase
  text-transform: uppercase

.underline
  text-decoration: underline

.no-underline
  text-decoration-line: none

.line-through
  text-decoration: line-through

.smaller
  font-size: calcRem(14)

.smallest
  font-size: calcRem(12)

.monospace
  font-family: monospace

.text-center
  text-align: center

.text-shadow-subtle
  text-shadow: 2px 2px 4px rgb(0 0 0 / 20%)

.no-list-styles
  list-style: none
  line-height: inherit
  & > li
    padding: 0
    margin: 0
