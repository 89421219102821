@import "sass/lib/variables"
@import "sass/lib/typography"

.c-img-upload-input__thumbnail
  max-width: 160px
  margin-bottom: 19px
  padding: 5px
  border: 1px solid grey
  border-radius: 4px

  img
    width: 100%
    display: block

