@import "sass/lib/variables"

@keyframes heart-burst
  from
    background-position: left
  to
    background-position: right

.c-favourite-btn
  width: 40px
  height: 40px
  background-image: url('~images/icons/favourite.png')
  background-size: 2900%
  background-position: left
  background-repeat: no-repeat
  background-color: #090909
  outline: none
  border-radius: 100%
  border: none
  cursor: pointer

  &:hover
    transform: scale(1.1)
  &:link, &:visited, &:hover, &:active
    outline: none

  &--active
    background-position: right
  &--is-animating
    animation: heart-burst .8s steps(28) 1
    animation-fill-mode: forwards
