@import "sass/lib/variables"
@import "sass/lib/utilities"
@import "sass/lib/typography"

// Based on https://tailwindcss.com/docs/box-shadow shadow-md
.shadow
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)

.rounded
  border-radius: calcRem(6px)

.rounded-lg
  border-radius: calcRem(10px)

.rounded-xl
  border-radius: calcRem(15px)

// TODO: create loop for all roudned options

.rounded-t-xl
  border-top-left-radius: calcRem(20px)
  border-top-right-radius: calcRem(20px)

.rounded-b-xl
  border-bottom-left-radius: calcRem(20px)
  border-bottom-right-radius: calcRem(20px)

// For image sizing
.img-cover 
  width: 100%
  height: 100%
  object-fit: cover

.blob-1
  background: center / contain no-repeat url('~images/global/blob-1.svg')

.blob-2
  background: center / contain no-repeat url('~images/global/blob-2.svg')

.blob-3
  background: center / contain no-repeat url('~images/global/blob-3.svg')

.blob-4
  background: center / contain no-repeat url('~images/global/blob-4.svg')
