.admin-blog_posts-index
  .search__filter__text
    select, .c-select-search
      width: 200px !important
      @media (max-width: $mobile-width)
        width: 100% !important

.full_width_text
  width: 35em !important
form.blog_post
  .choice, .boolean
    width: 45%
    display: inline-block

  .image, .section
    position: relative
    width: 100%
    margin-bottom: 15px

    & > label
      margin-bottom: 5px
      display: inline-block

    img
      margin: 20px 0
      max-width: 100%
    li
      display: block

  .banner_image
    img
      max-height: 265px
      max-width: 621px
      width: 100%
      object-fit: cover

    .hide-unchecked
      display: none

.admin-blog_posts-show, .blog_posts-show, .pages
  .blog-posts__admin-options__wrapper
    max-width: 1470px
    position: relative

  .blogpost_url
    float: left
    margin: 0
  .blog-posts__admin-options
    max-width: 940px
    margin: 0 auto
    padding: 10px 0
    text-align: right
    display: none
    a
      min-width: 100px
  .blog-posts__admin-options-button
    border-radius: 50%
    padding: 0
    padding: 5px 6px
    font-size: 14px
    position: absolute
    right: 30px
    top: 15px
    z-index: $blogpost-admin-options-index
    width: 27px

.send_member_alert_container
  background-color: $very-light-grey
  padding: 10px
  margin-bottom: 20px
