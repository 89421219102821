.payments__new
  .christmas_2021
    .payments__new--header
      margin-top: 50px
      margin-bottom: 80px
      @media (max-width: $tablet-large-width)
        flex-direction: column
        align-items: center

    .payments__new--header_img
      max-width: 720px
      // This is hacky fix to override the styles in app/webpacker/sass/payments/new.sass
      // All these styles should be re-written into components
      @media (max-width: $tablet-large-width)
        width: 60%
      @media (max-width: $tablet-width)
        width: 100%
      &_credit
        margin-right: 7%
        text-align: right

    .payments__new--header_text
      // This is hacky fix to override the styles in app/webpacker/sass/payments/new.sass
      // All these styles should be re-written into components
      @media (max-width: $tablet-large-width)
        width: 88%
      @media (max-width: $tablet-width)
        width: 100%

  .christmas_2021--gifting
    .payments__new--header_img
      @media (max-width: $tablet-small-width)
        width: 75%


.payments__thank_you
  .christmas_2021
    h1, p
      max-width: 710px
      margin-left: auto
      margin-right: auto
    .large_image
      border-radius: 0
      @media(max-width: $mobile-width)
        width: 75%
    .img_credit
      font-size: calcRem(12px)
      font-style: italic
