@import "sass/lib/typography"
@import "sass/lib/variables"

.c-bonded-pet-banner-promo
  $intermediary-width: 1120px
  $mobile-width: 820px

  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  background-color: #62AC44
  margin-bottom: 30px
  color: white
  padding: 5px 20px
  border-radius: 12px
  position: relative
  overflow: hidden

  // background swirl
  &::before
    content: ""
    position: absolute
    left: 0
    bottom: 0
    right: 0
    height: 23px
    background-image: url("data:image/svg+xml,%3Csvg width='1056' height='23' viewBox='0 0 1056 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.5 0.509644L25.9958 2.20165C50.6125 3.89365 100.725 7.27765 150.837 7.70065C201.829 8.12365 251.942 5.58565 302.054 3.89365C352.167 2.20165 402.279 1.35564 452.392 3.89365C502.504 6.43165 553.496 12.3537 603.608 14.8917C653.721 17.4297 703.833 16.5837 753.946 13.6227C804.058 10.6617 854.171 5.58565 905.162 4.31665C955.275 3.04765 1005.39 5.58565 1030 6.85465L1055.5 8.12365V23.3517H1030C1005.39 23.3517 955.275 23.3517 905.162 23.3517C854.171 23.3517 804.058 23.3517 753.946 23.3517C703.833 23.3517 653.721 23.3517 603.608 23.3517C553.496 23.3517 502.504 23.3517 452.392 23.3517C402.279 23.3517 352.167 23.3517 302.054 23.3517C251.942 23.3517 201.829 23.3517 150.837 23.3517C100.725 23.3517 50.6125 23.3517 25.9958 23.3517H0.5V0.509644Z' fill='%23439323'/%3E%3C/svg%3E%0A")
    background-position: bottom left
    background-size: cover
    background-repeat: no-repeat

  @media (max-width: $intermediary-width)
    flex-direction: column

  @media (max-width: $mobile-width)
    flex-direction: row
    padding: 22px 20px 22px 20px

  .c-bonded-pet-banner-promo__outer_block
    display: flex
    align-items: center
    position: relative
    @media (max-width: $intermediary-width) and (min-width: $mobile-width)
      flex-direction: column
      & > * + *
        margin-top: 8px
    @media (max-width: $mobile-width)
      flex-direction: column

  .c-bonded-pet-banner-promo__inner_block
    display: flex
    align-items: center
    flex-shrink: 0
    @media (max-width: $mobile-width)
      flex-direction: row-reverse

  &__title
    font-weight: bold
    font-size: calcRem(24px)
    font-family: $font-serif
    text-shadow: 1px 1px rgba(black, 0.09)
    margin: 0

  &__squiggle
    margin-left: 19px
    max-width: 89px
    @media (max-width: $mobile-width)
      margin-right: 10px
      margin-left: 0px
      max-width: 70px


  &__tag
    font-weight: bold
    font-size: calcRem(16px)
    line-height: 1.2
    margin-left: 15px
    flex-grow: 1
    text-shadow: 1px 1px rgba(black, 0.17)
    @media (max-width: $intermediary-width)
      margin-left: 0
    @media (max-width: $mobile-width)
      margin-top: 10px
      text-align: center

  &__btn
    // TODO: get colour from variables
    padding: 12px 23px
    background-color: white
    color: $petrescue-green
    font-weight: bold
    font-size: calcRem(16px)
    line-height: 1
    text-align: center
    border-radius: 10px
    margin: 10px 0 10px 32px
    text-decoration: none
    flex-shrink: 0
    display: inline-block
    text-transform: uppercase
    position: relative

    @media (max-width: $intermediary-width)
      margin-left: 0
