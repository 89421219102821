@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/mixins"

.c-listing-card
  // TODO - max-width probably needs adjusting
  // maybe a global variable for all cards
  max-width: 300px
  .c-blank-card
    transition: 0.2s ease-in-out
    will-change: transform, box-shadow
    display: flex
    flex-direction: column
    padding: 0
  &:hover .c-blank-card
    transform: translateY(-4px)
    box-shadow: 0 0 8px rgba(0,0,0,0.15)
  &:active .c-blank-card
    transform: translateY(0px)
  .c-favourite-btn
    top: 10px
    right: 10px
    position: absolute

  &__img-wrapper
    width: 100%
    position: relative
    img
      width: 100%
      border-radius: 10px 10px 0 0

    .c-listing-card__img
      width: 100%
      height: 100%
  .c-listing-card__img__bonded-besties
    position: absolute
    bottom: -30px
    right: 0
    width: 100px

  &__content
    text-align: left
    display: block
    text-decoration: none

    &__header
      h3
        margin: 0
        +truncate
        font-size: 30px
        text-decoration: none
        color: $petrescue-green
        font-family: $font-serif
        line-height: 1.5
        &:hover
          color: $petrescue-green

    &__section
      &__species
        font-weight: bold
        text-transform: capitalize
        font-size: 14px
        line-height: 1.5
        display: block
        margin: 5px 0
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        .fa-heart
          color: $petrescue-green

      &__location,
      &__interstate,
      &__adopted-at,
      &__public_created_at,
      &__bumped_at
        font-size: 14px
        font-weight: normal
        line-height: 27px
        display: block
        +truncate
        i
          width: 15px

      &__best-feature
        margin: 0 0 15px 0
        font-size: 17px
        line-height: 27px
        display: block
        color: $text-light-grey
        +truncate

      &__description
        margin: 1em 0
        height: 140px
        line-height: 20px
        text-align: left
        font-size: 14px
        overflow: hidden

        @media (max-width: $tablet-width)
          height: 110px
          line-height: 18px

      &__adopted
        font-size: 16px
        font-weight: bold
        text-align: center
        border-radius: 6px
        padding: 5px 10px
        background-color: #FBFBFB
        .fa-heart
          color: $petrescue-green

      &__adopted-at
        text-align: center
        opacity: 0.5

      &__footer
        .button
          width: 100%
          display: block

.c-listing-card--compactable 
  max-width: 100%
  @media (min-width: $tablet-small-width)
    max-width: 300px
  .c-blank-card
    flex-direction: row
    @media (min-width: $tablet-small-width)
      flex-direction: column

  .c-listing-card__img-wrapper
    max-width: 80px
    @media (min-width: $mobile-small-width)
      max-width: 100px
    @media (min-width: $tablet-small-width)
      max-width: 100%
    .cards-listings-preview__img   
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: 10px 0 0 10px
      @media (min-width: $tablet-small-width)
        border-radius: 10px 10px 0 0
  .c-favourite-btn
    left: 10px
    @media (min-width: $tablet-small-width)
      right: 10px
  .c-listing-card__content
    &__header
      > h3
        font-size: 22px
        @media (min-width: $tablet-small-width)
          font-size: 30px
    &__section
      &__best-feature
        display: none
        @media (min-width: $tablet-small-width)
          display: block
      &--details
        display: flex
        flex-wrap: wrap
        gap: 5px
