@import "sass/lib/variables"
@import "sass/lib/typography"
@import "sass/lib/utilities"
@import "sass/lib/mixins"

.c-mega-nav__dropdown
  background-color: $white
  padding: 40px 0 30px 0
  position: absolute
  width: 100%
  justify-content: center
  z-index: $main-nav-dropdown-index
  left: 0
  top: 40px
  text-transform: initial
  font-weight: 500
  display: flex
  cursor: initial
  box-shadow: 0px 5px 17px -11px rgba(0,0,0,0.6)

.subnav-wrap
  @media (min-width: $tablet-width)
    display: inline-block

.header-subnav__link
  @include font-awesome("\f107", inherit)
  &:after 
    transition: transform linear 200ms
    margin-left: 2px
    display: inline-block
  &.js-menu-open
    background-color: $white
    border-color: $white
    &:after
      transform: rotate(-180deg)
.show_tablet,
.show_tablet .header-subnav__link
  &:after 
    display: none
    