@import "~glightbox/dist/css/glightbox"

#glightbox-slider
  align-items: start

  .gslide
     max-height: 100%
     height: 100%

.glightboxButton
  margin-top: 30px
  border-radius: 10px
  font-size: calcRem(15px)
  background-color: $almost-white
  color: $text-dark-grey
  border-color: $almost-white
  border-bottom: 3px solid $petrescue-green
  &:hover
    background-color: darken($almost-white, 10%)
  .fa-video-camera
    font-size: calcRem(19px)
    margin-right: 10px

.glightbox-clean .gnext, .glightbox-clean .gprev
  @media (max-width: $mobile-width)
    top: 75%

.glightbox-clean .gindex
  display: none
  @media (max-width: $mobile-width)
    display: block
    position: absolute
    top: 77%
    right: 45%
    color: white
