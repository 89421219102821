@import "sass/lib/variables"

.c-search-modal
  &__label
    margin-bottom: 10px
    display: block

  .c-search-modal-wrapper
    width: 100%

    .c-search-modal-form
      display: flex
      @media (max-width: $mobile-width)
        flex-direction: column

      .c-search-modal-form__fields
        display: flex
        position: relative
        flex-grow: 2
        width: 100%
        max-width: 350px
        @media (max-width: $mobile-width)
          flex-direction: column

        select.c-search-modal__select
          border: solid 2px $form-input-border-color
          width: fit-content
          border-radius: $form-input-radius
          padding: 10px 32px 10px 5px
          width: 100%
          font-size: 17px
